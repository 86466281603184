import { AuthUser } from "../../../modules/auth/types/auth-user.type"
import { NukMenuSection } from '../../config/menus/nuk-menu-section.interface';
import { userHasRole } from '../../../modules/auth/utils/auth-user-role-checker';

/**
 * Given a menu configuration and a user
 * this function filters the section and items and removes the ones which are not matching the user roles.
 * @param user 
 * @param sections 
 * @returns 
 */
export const getAuthFilteredStructure = (user: AuthUser, sections: NukMenuSection[]): NukMenuSection[] => {
    const finalSections: NukMenuSection[] = []
    for (const section of sections) {
        let filteredSection = null
        if (
            section.roles === undefined || 
            section.roles === null || 
            section.roles.length === 0 || 
            userHasRole(user, section.roles)
            ) {
            //Section is enabled
            filteredSection = Object.create(section)
            //Removing current items
            filteredSection.items = []
            if (section.items) {
                for (const item of section.items) {
                    if(item.resource){
                        if( item.resource.roles === undefined || 
                            item.resource.roles === null || 
                            item.resource.roles.length === 0 || 
                            userHasRole(user, item.resource.roles)
                            ){
                                //Resource item is enabled
                                filteredSection.items.push(item)
                            }

                    } else{
                        if(
                            item.roles === undefined || 
                            item.roles === null || 
                            item.roles.length === 0 || 
                            userHasRole(user, item.roles)
                        ){
                            //Normal item is enabled
                            filteredSection.items.push(item)
                        }

                    }

                }
            }

            if(filteredSection!==null && filteredSection.items.length>0){
                finalSections.push(filteredSection)
            }

        }
    }

    return finalSections
}