import { Cancel, Delete } from "@mui/icons-material";
import { Alert, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from 'react-i18next';
import { MuiColorsEnum } from "../../mui/nuk-mui-default-colors.type";
import { NukButton } from "../buttons/nuk-button";
import { NukIconTitle } from "../titles/nuk-icon-title";
import { NukTransitionSlideUp } from '../transitions/nuk-transition-slide-up';

type Props = {
    loading: boolean,
    confirmLoading: boolean,
    open?: boolean,
    label: string
    error: any
    setOpen: Dispatch<SetStateAction<boolean>>,
    handleConfirm: Function

}

/**
 * A Remove dialog that warns the user and ask confirmations before proceeding
 * @param  
 * @returns 
 */
export const NukResourceRemoveDialog: FC<Props> = (
    {
        loading=false,
        confirmLoading=false,
        error,
        open=false,
        label="",
        setOpen,
        handleConfirm,
        children,
    }) => {
    const theme = useTheme();
    const smBreakPoint = useMediaQuery(theme.breakpoints.down('md'))

    const {t} = useTranslation()
    return (
            <Dialog
            open={open}
            onClose={() => {setOpen(false)}}
            TransitionComponent={NukTransitionSlideUp}
            sx={{zIndex:9999}}
            >
            <DialogTitle>
                    <NukIconTitle 
                        icon={<Delete></Delete>} 
                        color={MuiColorsEnum.error} 
                        label={`${t('general:removing')} ${label}`} 
                        skeleton={loading}
                        />
            </DialogTitle>
            <DialogContent>
            {!loading ?
                !confirmLoading ?
                    !children ?
                    <DialogContentText>
                        {t('resources:removeWarning')}
                    </DialogContentText>
                    : 
                    <DialogContent> 
                        {children} 
                    </DialogContent> 
                  :
                  <Box>
                  <LinearProgress color="error"></LinearProgress>
                  </Box>
                :
                <DialogContent>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                </DialogContent>
              
            }
            </DialogContent>
            {error &&
                      <Alert severity="error">
                        {t('operationFailed',{ns:'general'})}
                      </Alert>
            }
            <DialogActions>
            <Grid container rowSpacing={2} sx={{p:1}}>
                <Grid item xs={12} sm={12} md={6}>
                    <NukButton 
                    icon={<Cancel/>} 
                    label={t('general:cancel')} 
                    onClick={() => {setOpen(false)}} 
                    skeleton={loading} 
                    disabled={confirmLoading}
                    sx={{width:(smBreakPoint) ? '100%' : null}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{display:'flex',justifyContent:'flex-end'}}>
                    <NukButton 
                    icon={<Delete/>} 
                    label={t('general:confirm')} 
                    sx={{width:(smBreakPoint) ? '100%' : null}} 
                    onClick={() => {handleConfirm()}} 
                    skeleton={loading} 
                    disabled={confirmLoading}
                    color="error"/>
                </Grid>

            </Grid>
            
       
            </DialogActions>
            </Dialog>
    )
}

