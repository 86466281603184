import { Box, capitalize, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from 'react-i18next';
import { CremationStatus } from "../../constants/cremation-status.enum";
import { CremationStatusStepLabel } from './cremation-status.step-label';

type CremationStatusStepperProps = {
    status: CremationStatus
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({

    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.success.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#4caf50',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      marginTop:5,
      borderRadius: 1,
    },
  }));

export const CremationStatusStepper: FC<CremationStatusStepperProps> = ({ status }) => {

    return (<Stepper alternativeLabel orientation="horizontal" sx={{ flex: 1 }} connector={<QontoConnector/>}>
        <Step completed={isCompleted(status, CremationStatus.REGISTERED)}>
            <CremationStatusStepLabel status={CremationStatus.REGISTERED} />
        </Step>
        <Step completed={isCompleted(status, CremationStatus.DELIVERED)}>
            <CremationStatusStepLabel status={CremationStatus.DELIVERED} />
        </Step>
        <Step completed={isCompleted(status, CremationStatus.STARTED)}>
            <CremationStatusStepLabel status={CremationStatus.STARTED} />
        </Step>
        <Step completed={isCompleted(status, CremationStatus.COMPLETED)}>
            <CremationStatusStepLabel status={CremationStatus.COMPLETED} />
        </Step>
        <Step completed={isCompleted(status, CremationStatus.COLLECTED)}>
            <CremationStatusStepLabel status={CremationStatus.COLLECTED} />
        </Step>
    </Stepper>)
}


const isCompleted = (currentStatus: CremationStatus, compareStatus: CremationStatus) => {

    switch (compareStatus) {
        case CremationStatus.REGISTERED:
            return true
        case CremationStatus.DELIVERED:
            return (
                [
                    CremationStatus.DELIVERED,
                    CremationStatus.STARTED,
                    CremationStatus.COMPLETED,
                    CremationStatus.COLLECTED
                ].includes(currentStatus)
            ) ? true : false
        case CremationStatus.STARTED:
            return (
                [
                    CremationStatus.STARTED,
                    CremationStatus.COMPLETED,
                    CremationStatus.COLLECTED
                ].includes(currentStatus)
            ) ? true : false
        case CremationStatus.COMPLETED:
            return (
                [
                    CremationStatus.COMPLETED,
                    CremationStatus.COLLECTED
                ].includes(currentStatus)
            ) ? true : false
        case CremationStatus.COLLECTED:
            return (
                [
                    CremationStatus.COLLECTED
                ].includes(currentStatus)
            ) ? true : false
        default:
            return false
    }


} 
