
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { FC } from 'react';
import { capitalize, Chip } from '@mui/material';
import { CremationStatus, CremationStatusColor } from '../../constants/cremation-status.enum';
import { MuiColorsEnum } from '../../../../core/mui/nuk-mui-default-colors.type';
import { useTranslation } from 'react-i18next';
import { CremationStatusIcon } from '../icons/cremation-status.icon';
import { keyframes } from '@mui/system';

type Props = {
    value: CremationStatus 
    animated?: boolean 
    detail?: string
}


const pulsateStarted = keyframes`
0% {
    background-color: #d80000;
  }
  50% {
    background-color: #be0000;
  }
  100 {
    background-color: #a50000;
  }
`;


 export const CremationStatusChip : FC<Props> = ({
     value,
     animated = false,
     detail = null,
    }) =>{
    const { t } = useTranslation()
    return <Chip 
                icon={<CremationStatusIcon status={value}/>} 
                label={
                  `${capitalize(t(`cremationStatus${value}`,{ns:'resourceCremation'}))}
                  ${(detail) ? '('+detail+')' : ''}
                  `
                  
                } 
                sx={{
                    pl:0.5,
                    backgroundColor: (value) ? CremationStatusColor[value] : "primary.main",
                    color: '#FFF',
                    animation: (animated && value===CremationStatus.STARTED) ? `${pulsateStarted} 1s infinite ease` : undefined 

                }}/>
}