import { Autocomplete, Box, capitalize, Skeleton, TextField } from "@mui/material";
import { FC, Fragment, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { GraphqlFormError, hasGraphqlErrorSlim } from "../graphl-form-error";
import { NukFormContext } from "../nuk-form-context";
import { NukFormError } from "../nuk-form-error";
import { countries } from "./nuk-form-country-select.countries"
import { useTranslation } from 'react-i18next';

type Props = {
    name: string
    id?: string
    label: string
    rules?: any
    multiline?: boolean
    minRows?: number,
    maxRows?: number,
}

/**
 * Mui Text Field Component wrapped in a React Hook Form Controller for maximum compatibility.
 * id: if not specified, is the same as name
 * @param props 
 * @returns 
 */
export const NukFormCountrySelect : FC<Props> = (
  { name,
    id,
    label,
    rules,
  }) => {
    if(id==null) id = name
    const { control,formState: { errors }} = useFormContext()
    const { loading,gqlErrors } = useContext(NukFormContext)
    const { t } = useTranslation()
    const component =         
    <Fragment> 
      <Controller
              name={name}
              control={control}
              rules={rules}
              render={({ field: { onChange,value } }) => (
        
                <Autocomplete
                options={countries}
                value={(value) ? countries[countries.map(country => country.code).indexOf(value)] : null}
                onChange={(event,data) => {
                    return onChange((data) && data.code);
                }}   
                sx={{mt:2}}
                autoHighlight
                getOptionLabel={(option) => `${t(option.label,{ns:'countries'})} (${option.code})`}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {t(option.label,{ns:'countries'})} ({option.code}) 
                  </Box>
                )}
                renderInput={(params) => (
                  
                  <TextField
                     error={(errors.hasOwnProperty(name) || hasGraphqlErrorSlim(gqlErrors,[name])) ? true : false}

                    {...params}
                    label={capitalize(label)}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
          
              )}
      />
      <NukFormError field={name} errors={errors} />
      <GraphqlFormError errors={gqlErrors} filters={[name]}/>
    </Fragment>

    const skeleton = <Skeleton animation="wave" height={100}/>

    
    return ((!loading) ? component : skeleton)
}