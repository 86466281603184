import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { DataGridActionsBuilder } from "../../../../../core/nuk-utils";
import { usersResource as resourceConfig } from "../../../users.resource";
import { companiesColumn } from "./columns/users-list-companies.column";
import { userListDetailColumn } from './columns/users-list-detail.column';
import { roleColumn } from "./columns/users-list-roles.column";


/**
 * Users list default columns (smBreakPoint and up)
 * @param t 
 * @returns 
 */
export const getColumns = (t: Function) => {
  const ns = {ns: resourceConfig.translationNamespace}
  return [
    { field: 'id', headerName: 'ID', width: 70 },
    { 
      field: 'firstName', 
      headerName: t('firstName',ns), 
      width: 130,
      renderCell : (params: GridRenderCellParams<string>) => (<strong>{params.value}</strong>)
    },
    { 
      field: 
      'lastName', 
      headerName: t('lastName',ns), 
      width: 130,
      renderCell : (params: GridRenderCellParams<string>) => (<strong>{params.value}</strong>)
    },
    { 
      field: 'username', 
      headerName: 'Username', 
      width: 130 
    },
    { 
      field: 'email', 
      headerName: 'Email', 
      width: 400 
    },
    //Custom Role Column
    roleColumn(t),
    companiesColumn(t),
    { 
      field: 'enabled', 
      headerName: t('enabled',{ns:'general'}), 
      type: 'boolean', 
      width: 100 
    },
    //Actions
    DataGridActionsBuilder.buildDefaultResourceActions(resourceConfig.slug)
  ];

}


/**
 * Users list slim columns (xsBreakPoint down)
 * @param t 
 * @returns 
 */
export const getSlimColumns =(t: Function) => {
  return [userListDetailColumn(t)]
}

