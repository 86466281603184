
import {setMinutes,setHours,setSeconds} from 'date-fns'
import { split } from 'lodash'




export class NukTimeUtils{
    
    static getTimeValuesForSelect(params : getTimeValuesForSelectParams){
        const items = []
            items.push({
                value:'',
                label:'',
                default:true,
            })
        for(let h=params.startHour;h<=params.endHour;h++){
            for(let m=0;m<60;m+=params.minuteBreak){
                items.push({
                    value: `${h.toString().padStart(2,'0')}:${m.toString().padStart(2, '0') }`,
                    label: `${h.toString().padStart(2,'0')}:${m.toString().padStart(2, '0')}`,
                })
            }

        }
        return items
    }

    static updateDateWithTimeValuesFromSelect(params: updateDateWithTimeValuesFromSelect){
        if(params.date!=null && params.time.length>0){
        params.date = new Date(params.date)
        const splittedTime = params.time.split(":") 
        params.date = setMinutes(params.date,Number(splittedTime[1]))
        params.date = setHours(params.date,Number(splittedTime[0]))
        params.date = setSeconds(params.date,0)
        return params.date
        } 
        return null
    }

    static extractTimeValuesForSelect(dateStr: string){
        if(dateStr && dateStr.length>0){
        let date = new Date(dateStr)
        return `${date.getHours().toString().padStart(2,'0')}:${date.getMinutes().toString().padStart(2,'0')}`
        } 

        return ''
    }
}

//Types
type getTimeValuesForSelectParams = {
    startHour: number,
    endHour: number,
    minuteBreak: number,
}

type updateDateWithTimeValuesFromSelect = {
    date: Date,
    time: string
}


