import { nukYupI18nextLocale } from '../../../../../../../core/nuk-core'
import * as Yup from 'yup'

//Enabling localization
Yup.setLocale(nukYupI18nextLocale)

/**
 * Auth Profile password change form validation schema
 */
export const authProfileUpdatePasswordFormSchema = Yup.object().shape({
    oldPassword: Yup.string().required(),
    newPassword: Yup.string().required(),
    rePassword: Yup.string()
        .required()
        .oneOf([Yup.ref('newPassword')], "custom.repeatPassword"),
})
export type authProfileUpdatePasswordFormSchemaType = Yup.InferType<typeof authProfileUpdatePasswordFormSchema>
