
import moment from "moment";
import { AuthService } from "../../../modules/auth/services/auth.service";
import { store } from "../../../store";
import { logout, refreshTokens } from "../../../modules/auth/redux/auth-user.slice";
import { setContext } from "@apollo/client/link/context";
//@ts-ignore
const delay = (ms: any) => new Promise(res => setTimeout(res, ms));

export const authLink = setContext(
    request =>
      new Promise(async (success, fail) => {
        const activeStore = store.getState()
  
        if(
            activeStore.user && 
            activeStore.user.value && 
            activeStore.user.value.tokensExpirationDates &&
            activeStore.user.value.tokensExpirationDates.accessTokenExpirationDate &&
            activeStore.user.value.tokensExpirationDates.refreshTokenExpirationDate){
                //Transforming expiration dates in moment
                const accessTokenExpirationDate = moment(activeStore.user.value.tokensExpirationDates.accessTokenExpirationDate)
                const refreshTokenExpirationDate = moment(activeStore.user.value.tokensExpirationDates.refreshTokenExpirationDate)
    
                //Calculating seconds left in access token
                const secondsLeftInAccessToken = accessTokenExpirationDate.diff(moment(),'seconds')
                //Calculatin minutes left in refresh token
                const minutesLeftInRefreshToken = refreshTokenExpirationDate.diff(moment(),'minutes')
                //Checking if the refresh token threeshold (30 seconds) is reached
                if(secondsLeftInAccessToken < 30){
                    //Checking if the refreshToken (1 minute margin) is not expired
                    if(minutesLeftInRefreshToken > 1){
                        await AuthService.refreshToken({
                            data: {},
                            onSuccess: async (data : any) => {
                                //Token refreshed successfully, dispatching token update
                                store.dispatch(refreshTokens(data.tokensExpirationDates))
                            },
                            onError: () => {
                                //Refresh operation failed - this may be due to a disabled refresh token, or disabled user
                                store.dispatch(logout())
                                setTimeout(() => success({refresh:true}),1000)
                            }
                        })
                        await delay(2500)
                        success({refresh:true})
                    } else{
                        store.dispatch(logout())
                        fail({refresh:false})
                    }
                }
    
        } 
        success({clear:true})
        
    
      })
  );

