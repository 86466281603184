import { capitalize, FormControlLabel, Skeleton, Switch } from "@mui/material";
import { FC, Fragment, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { NukFormContext } from './nuk-form-context';
import { NukFormError } from "./nuk-form-error";

type Props = {
    name: string
    id?: string
    label: string
    rules?: any
}

/**
 * Mui Text Field Component wrapped in a React Hook Form Controller for maximum compatibility.
 * id: if not specified, is the same as name
 * @param props 
 * @returns 
 */
export const NukFormSwitchField : FC<Props> = (
    {
        name,
        id,
        label,
        rules
    }) => {
    const { control,formState: { errors }} = useFormContext()
    const { loading } = useContext(NukFormContext)
    if(id==null) id = name

    const component = 
    <Fragment>
            <Controller
                    name={name}
                    control={control}
                    rules={rules}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                    <FormControlLabel control={<Switch onChange={(e) => onChange(e.target.checked)} checked={value} />} label={capitalize(label)} />
                    )}
            />
            { errors &&
                <NukFormError field={name} errors={errors} />
            }
    </Fragment>

    const skeleton = <Skeleton animation="wave" height={100}/>

    return ((!loading) ? component : skeleton)
}

