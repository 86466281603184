import { Box, capitalize, Chip, FormControl, InputLabel, MenuItem, Select, Skeleton, TextField } from "@mui/material";
import { FC, Fragment, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { isTemplateSpan } from "typescript";
import { GraphqlFormError, hasGraphqlErrorSlim } from './graphl-form-error';
import { NukFormContext } from './nuk-form-context';
import { NukFormError } from "./nuk-form-error";
import nextId from 'react-id-generator';
import { GetAppRounded } from "@mui/icons-material";
type Props = {
    name: string
    id?: string
    items: NukFormSelectFieldItem[]
    label: string
    rules?: any
    defaultValue?: string
    customRender?: boolean
}

export type NukFormSelectFieldItem = {
  label?: string,
  value: string | number,
  renderValue?: JSX.Element
}

/**
 * Mui Text Field Component wrapped in a React Hook Form Controller for maximum compatibility.
 * id: if not specified, is the same as name
 * @param props 
 * @returns 
 */
export const NukFormSelectField : FC<Props> = (
  { name,
    id,
    label,
    rules,
    items = [],
    defaultValue = "",
    customRender = false,
  }) => {
    if(id==null) id = name
    const { control,formState: { errors }} = useFormContext()
    const { loading,gqlErrors } = useContext(NukFormContext)
    const component =         
    <Fragment> 
      <Controller
              name={name}
              control={control}
              defaultValue={defaultValue}
              rules={rules}
              render={({ field: { onChange, value } }) => {
                const selectedItem = items.find( ({ value : itemVal  }) => itemVal === value )
                return(
                <FormControl fullWidth>
                <InputLabel>{label}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={value}
                  label={label}
                  error={(errors.hasOwnProperty(name) || hasGraphqlErrorSlim(gqlErrors,[name])) ? true : false}
                  onChange={(e) => onChange(e)}
                  renderValue={(customRender) ? (value) => 
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {selectedItem?.renderValue}
                  </Box> : undefined}
                  fullWidth
                >
                  {items.map((item) => <MenuItem key={nextId()} value={item.value}>
                    {item.renderValue ?
                      item.renderValue : item.label 
                    
                    }
                  </MenuItem>)}
     
                </Select>
                </FormControl>
              )}}
      />
      <NukFormError field={name} errors={errors} />
      <GraphqlFormError errors={gqlErrors} filters={[name]}/>
    </Fragment>

    const skeleton = <Skeleton animation="wave" height={100}/>

    
    return ((!loading) ? component : skeleton)
}