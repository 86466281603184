import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { FC, Fragment, useEffect, useState } from "react";
import {
  NukIconTitle,
  NukTextViewLabel,
} from "../../../../../../core/nuk-components";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { NukLabel } from "../../../../../../core/components/labels/nuk-label";

export interface Props {
  open: boolean;
  resourceId: number | null | undefined;
  setOpen: Function;
}

export const BillingModal: FC<Props> = ({ open, setOpen, resourceId }) => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const getData = () => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/cremations/${resourceId}/billing`,
      {
        credentials: "include",
      }
    )
      .then((r) => r.json())
      .then((d) => {
        setData(d);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open && resourceId) {
      getData();
    }
  }, [resourceId, open]);

  return (
    <Dialog
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle id="customized-dialog-title">
        <Grid container>
          <Grid item sm={9} md={9}>
            <NukIconTitle
              icon={<ReceiptIcon />}
              label={"Dettagli fatturazione"}
            />
          </Grid>
          <Grid item sm={2} md={2} sx={{ textAlign: "right" }}>
            <CloseIcon
              sx={{
                position: "absolute",
                right: 8,
                top: 18,
                fontSize: 36,
                cursor: "pointer",
                color: (theme) => theme.palette.grey[500],
              }}
            
            onClick={() => setOpen(false)}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <LinearProgress />
        ) : (
          <Fragment>
            {data && (
              <div>
                {data.billingData.bellinzonaAssignment ? (
                  <Grid container>
                    <Grid item md={12}>
                      <NukLabel label="INTESTATARIO" />
                      <Typography component={"div"} fontWeight="bold">
                        Comune di Bellinzona
                      </Typography>
                      <Typography component={"div"}>
                        Palazzo Civico - Piazza Nosetto 5
                      </Typography>
                      <Typography component={"div"}>
                        6500 Bellinzona (CH)
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item md={12}>
                      <NukLabel label="INTESTATARIO" />

                      <Typography component={"div"} fontWeight="bold">
                        {data.billingData.company.name}
                      </Typography>
                      <Typography component={"div"}>
                        {data.billingData.company.address}
                      </Typography>
                      <Typography component={"div"}>
                        {data.billingData.company.zipCode}{" "}
                        {data.billingData.company.city}
                        {data.billingData.company.countryCode &&
                          ` (${data.billingData.company.countryCode.toUpperCase()})`}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {/* Specifying company if billed to BZONE*/}
                {data.billingData.bellinzonaAssignment && (
                  <Grid container sx={{ mt: 3 }}>
                    <Grid item md={12}>
                      Questa prestazione è stata eseguita sotto incarico del{" "}
                      <b>Comune di Bellinzona</b> in servizio dell'azienda{" "}
                      <b>{data.billingData.company.name}.</b>
                    </Grid>
                  </Grid>
                )}

                <Grid container sx={{ mt: 3 }}>
                  <Grid item md={6}>
                    {data.billingData.mainType!=='MEDICAL_REMAINS' ? 
                    <NukTextViewLabel
                      label="Defunto"
                      fontSize={32}
                      fontWeight="bold"
                      textTransform="uppercase"
                      value={`${data.billingData.deceased.lastName} ${data.billingData.deceased.firstName}`}
                    />
                    :        <NukTextViewLabel
                    label="OPERAZIONE"
                    fontSize={32}
                    fontWeight="bold"
                    textTransform="uppercase"
                    value={`Cremazione residui medici`}
                  />}

                    
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 3 }} rowSpacing={2}>
                  <Grid item md={12}>
                    <NukLabel label="DETTAGLIO"></NukLabel>
                  </Grid>
                  <Grid item md={12}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Prestazione</TableCell>
                            <TableCell>Quantità</TableCell>
                            <TableCell>Unità/CHF</TableCell>
                            <TableCell align="right">Totale CHF</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.pricing.items.map((i : any,index: number) => 
                          <TableRow key={`table-pricing-item-${index}`}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left">
                              {i.label}
                            </TableCell>
                            <TableCell align="left">
                              {i.qty}
                            </TableCell>
                            <TableCell align="left">
                              {i.price}.-
                            </TableCell>
                            <TableCell align="right" sx={{color:(i.price==="Gratuita") ? 'green' : 'black'}}>
                              {i.total}.-
                            </TableCell>
                          </TableRow>
                          )}
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left" sx={{ fontWeight: "bold" }}>
                              Totale
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell
                              align="right"
                              sx={{ fontWeight: "bold" ,color:(data.pricing.price==="Gratuita") ? 'green' : 'black'}}
                            >
                              {data.pricing.total}{(data.pricing.total==='Gratuita') ? `` : `.-`}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </div>
            )}
          </Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
};
