import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NukListResourceAppBar, NukListResourceDataGrid, NukResourceListContext } from '../../../../../core/nuk-components';
import { ListRolesQuery, useListRolesQuery } from '../../../../../graphql/nuk-graphql-main';
import { rolesResource as resourceConfig } from '../../../roles.resource';
import { roleListDetailColumnHeight } from './columns/roles-list-detail.column';
import { getColumns, getSlimColumns } from './roles-list.columns';
import { RoleListDetailDialog } from './roles-list.detail-dialog';
import { RolesListFilters } from './roles-list.filters';


/**
 * Roles List Component
 * @param props 
 * @returns 
 */
export const RolesList: FC = () => {
  const [rowCount, setRowCount] = useState<number>(0);
  const [filtersLoading, setFiltersLoading] = useState<boolean>(false)
  const [filtersCount, setFiltersCount] = useState<number>(0)
  const [clearFilters, setClearFilters] = useState<boolean>(false)
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false)
  const [detailId, setDetailId] = useState<number | null>(null)
  const { translationNamespace: trns } = resourceConfig
  const { t } = useTranslation()
  const columns = getColumns(t)
  const slimColumns = getSlimColumns(t)

  //Main Gql Query
  const { loading, data, refetch } = useListRolesQuery({
    variables: {
      input: {
        pagination: {
          page: page,
          limit: pageSize
        }
      }
    }, fetchPolicy: 'cache-and-network',
  });

  //This functions gets the user items directly - used by the data grid to display data.
  const getItems = (data: ListRolesQuery) => { return data.roles.items }

  return (
    <NukResourceListContext.Provider value={{
      data: data,
      meta: data?.roles.meta,
      columns: columns,
      slimColumns: slimColumns,
      slimRowHeight: roleListDetailColumnHeight,
      loading: loading,
      trns: trns,
      resourceConfig: resourceConfig,
      page: page,
      setPage: setPage,
      pageSize: pageSize,
      setPageSize: setPageSize,
      rowCount: rowCount,
      setRowCount: setRowCount,
      filtersLoading: filtersLoading,
      setFiltersLoading: setFiltersLoading,
      clearFilters: clearFilters,
      setClearFilters: setClearFilters,
      filtersCount: filtersCount,
      setFiltersCount: setFiltersCount,
      detailDialogOpen: detailDialogOpen,
      setDetailDialogOpen: setDetailDialogOpen,
      detailId: detailId,
      setDetailId: setDetailId,
      refetch: refetch,
      getItems: getItems
    }}>
      <NukListResourceAppBar loading={loading}>
        <RolesListFilters />
      </NukListResourceAppBar>
      <NukListResourceDataGrid detailDialog={<RoleListDetailDialog />} />
    </NukResourceListContext.Provider>
  );
}