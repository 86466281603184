import { Delete, Settings } from '@mui/icons-material';
import { capitalize } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { NukFormAppBar, NukFormContext } from "../../../../../core/nuk-components";
import { GetCremationQuery } from '../../../../../graphql/nuk-graphql-main';
import { cremationsResource as resourceConfig } from '../../../cremations.resource';

interface Props {
    setDeleteDialogOpen: Function
    resourceData: GetCremationQuery | null | undefined
    resourceLabel: string
}
/**
 * Cremation editor form AppBar
 * All the form's executable operations are inside this component.
 * @param param0 
 * @returns 
 */
export const CremationEditorFormAppBar: FC<Props> = ({
    setDeleteDialogOpen,
    resourceData
}) => {
    const { trns } = useContext(NukFormContext)
    const { t } = useTranslation();

    //Actions
    const actions = [
        {
            label: t('general:settings'),
            icon: <Settings />,
            items: [
                {
                    label: `${t('general:delete')}`,
                    icon: <Delete />,
                    onClick: () => {
                        setDeleteDialogOpen(true)
                    }
                },
            ]
        },
    ]

    //Resource Loading (Edit mode)
    const breadcrumbs = [
        {
            label: capitalize(t(resourceConfig.namePlural,{ns: trns})),
            path: `/${resourceConfig.slug}`,
        },
        {
            label: 'Editor',
            path: '#'
        }
    ]

    return <NukFormAppBar 
                actions={actions} 
                breadcrumbs={breadcrumbs} 
                resourceLabel={(resourceData) ? 
                    (resourceData.cremation.type==='MEDICAL_REMAINS') ?
                    `Cremazione residui medici - ${resourceData.cremation.alfId}`
                    :
                    `${resourceData.cremation.firstName} ${resourceData.cremation.lastName}` 
                    : null}
        />
}