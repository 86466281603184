import { ApolloClient, InMemoryCache } from "@apollo/client";
import { additiveLink } from "./apollo-additive.link";

const cache = new InMemoryCache({
  typePolicies: {
    User: {
      fields: {
        //Custom merge for roles - when updating, the array sent could be null to remove all roles, this removes the warning.

        roles: {
          merge(existing, incoming, { mergeObjects }) {
            return incoming;
          },
        },
      },
    },
  },
});

export const client = new ApolloClient({
    cache: cache,
    link: additiveLink,
});