import { AuthUser } from "../types/auth-user.type";

/**
 * Verifies if the user has at least one of the given roles
 * @param user 
 * @param roles 
 */
export const userHasRole = (user: AuthUser,roleValues:string[]) : boolean => {
    if(user && user.roles && user.roles.length>0){
        if (user.roles.some(role => roleValues.includes(role.value))) {
            return true
        }
    }
    return false
}

