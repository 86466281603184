import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { DataGridActionsBuilder } from "../../../../../core/nuk-utils";
import { applicationKeysResource as resourceConfig } from '../../../application-keys.resource';
import { applicationKeyListDetailColumn } from './columns/application-keys-list-detail.column';

/**
 * ApplicationKeys list default columns (smBreakPoint and up)
 * @param t 
 * @returns 
 */
export const getColumns = (t: Function) => {
  const ns = {ns: resourceConfig.translationNamespace}
  return [
    { field: 'id', headerName: 'ID', width: 70 },
    { 
      field: 'name', 
      headerName: t('name',{ns: 'general'}), 
      flex: 1,
      renderCell : (params: GridRenderCellParams<string>) => (<strong>{params.value}</strong>)
    },
    {
      field: 'description',
      headerName: t('description',{ns: 'general'}),
      flex:1,
    },
    {
      field: 'apiKeyPrefix',
      headerName: 'TBA',
      flex:1,
    },
    //Actions
    DataGridActionsBuilder.buildDefaultResourceActions(resourceConfig.slug)
  ];

}


/**
 * ApplicationKeys list slim columns (xsBreakPoint down)
 * @param t 
 * @returns 
 */
export const getSlimColumns =(t: Function) => {
  return [applicationKeyListDetailColumn(t)]
}

