import { Box, capitalize, Card, CardContent, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from 'react-i18next';
import Moment from "react-moment";
import { NukUserApplicationAvatarColored, NukUserAvatarColored } from "../nuk-components";

type Props = {
    firstName?: string,
    lastName?: string,
    name?: string,
    label?: string,
    date: string,
}

export const NukHistoryRecapCard: FC<Props> = ({
    firstName,
    lastName,
    label="",
    name,
    date
}) => {

    const {t,i18n} = useTranslation()
    return (
        <Card sx={{width:'100%'}}>
            <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
    
                <Box sx={{ display: 'flex', flexDirection: 'column',flex:1}}>
                        {capitalize(label)}:
                    <Typography fontWeight="bold" sx={{display:'flex',flexDirection:'row'}}>
                    <Moment date={date} fromNow ago locale={i18n.language}/> 
                        &nbsp;{t('ago',{ns:'general'})}
                    </Typography>
                </Box>
                {(firstName && lastName) ?
                    <NukUserAvatarColored
                        firstName={firstName}
                        lastName={lastName}
                    />
                    : (name) ?
                        <NukUserApplicationAvatarColored
                            name={name}
                        />
                        : null
                }
            </CardContent>
        </Card>
    )
}

