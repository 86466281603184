import { useEffect } from 'react';
import { toast } from "react-toastify";
import { INukFormHookEffectParams } from "../../../../../../core/nuk-core";
import { CreateUserMutation, useCreateUserMutation } from '../../../../../../graphql/nuk-graphql-main';
import { usersResource as resourceConfig } from "../../../../users.resource";
import { userEditorFormCreateSchemaType } from "../user-editor.form.schema";

/**
 * User Create - Hook Wrapper
 * @param params 
 * @returns 
 */
export  const useUserFormEditorCreate = () =>{
    return useCreateUserMutation();
}

/**
 * User Create - Executes the create function
 * @param service 
 * @param values 
 */
export const executeUserFormEditorCreate = (service: Function,values: userEditorFormCreateSchemaType) => {
    //Additional validation can be done here.
    //Launching service
    service({
        variables: {
            input: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                username: values.username,
                enabled: values.enabled,
                password:values.password,
                repeatPassword:values.rePassword,
                roles: values.roles.map((roleId : string) => ({id:Number(roleId)})),
                companies: values.companies.map((companyId) => Number(companyId))
            }
        }
    })
}

/**
 * User Create - Post Effect
 * @param params 
 */
export  const useUserFormEditorCreateEffect = (params: EffectParams) => {
    const { data,error,setGqlError,navigate } = params
    useEffect(() => {
        if(data){
            //Toasting success
            toast.success(`${resourceConfig.nameSingular} ${data.createUser.firstName} ${data.createUser.lastName} Created!`)
            //Navigating back to list
            navigate(resourceConfig.indexPath)
        } else if(error){
            //Setting Gql Errors
            setGqlError(error);
        }
    },[data,error])
}

//User Create - Interfaces
interface EffectParams extends INukFormHookEffectParams {data: CreateUserMutation | null | undefined}
