export class RouteGenerator {
    public static LIST(slug: String){

    } 

    public static CREATE(slug: String){
        return `/${slug}/create`
    }

    public static EDIT(slug: String,id: String | number){
        return `/${slug}/${id}/edit`
    }
}