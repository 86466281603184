import { FC } from 'react';
import { NukStructure } from "../../../core/nuk-components";
import { RoleEditorForm } from '../components/forms/role-editor/role-editor-form';
/**
 * User Editor Page
 * @param props 
 * @returns 
 */
export const RolesEditorPage: FC = (props) => {
  return (
    <NukStructure>
        <RoleEditorForm/>
    </NukStructure>
  )
}