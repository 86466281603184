import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { INukFormHookEffectParams } from '../../../../../../core/hooks/nuk-form-hooks.interfaces';
import { UpdateRoleMutation, useUpdateRoleMutation } from '../../../../../../graphql/nuk-graphql-main';
import { rolesResource as resourceConfig } from '../../../../roles.resource';
import { roleEditorFormUpdateSchemaType } from "../role-editor-form.schema";
/**
 * Role Update - Hook Wrapper
 * @param params 
 * @returns 
 */
export  const useRoleFormEditorUpdate = () =>{
    return useUpdateRoleMutation();
}

/**
 * Role Update - Executes the update function
 * @param service 
 * @param values 
 */
export const executeRoleFormEditorUpdate = (service: Function,id: number,values: roleEditorFormUpdateSchemaType) => {
    //Additional validation can be done here.
    //Launching service
    service({
        variables: {
            id: id,
            input: {
                title: values.title,
                value: values.value,
                description: values.description,
            }
        }
    })
}


/**
 * Role Update - Post Effect
 * @param params 
 */
export  const useRoleFormEditorUpdateEffect = (params: EffectParams) => {
    const { data,error,setGqlError,setResourceLabel } = params
    useEffect(() => {
        if(data){
            //Clearing previous gql errors
            setGqlError(null)
            //Setting resource label
            setResourceLabel(`${data.updateRole.title}`)
            //Toasting success
            toast.success(`${resourceConfig.nameSingular} ${data.updateRole.title} updated.`)
        } else if(error){
            //Setting Gql Errors
            setGqlError(error);
        }
    },[data,error])
}

//User update - Interfaces
interface EffectParams extends INukFormHookEffectParams {data: UpdateRoleMutation | null | undefined}