import { Box, capitalize, StepLabel, StepLabelProps, Typography, useMediaQuery, useTheme } from "@mui/material"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { CremationStatus } from "../../constants/cremation-status.enum"
import { CremationStatusStep } from "./cremation-status.step"

interface Props extends StepLabelProps{
    status: CremationStatus,
    detail?: string
}

export const CremationStatusStepLabel : FC<Props> = ({status,detail}) =>{
    const { t } = useTranslation()
    const trns = { ns: 'resourceCremation' }
    const theme = useTheme()
    const xsBreakPoint = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <StepLabel
        StepIconComponent={(props) => <CremationStatusStep {...props} status={status} />}
    >
        {!xsBreakPoint &&
        <Box>
        {capitalize(t(`cremationStatus${status}`, trns))}
        </Box>
        }
        {detail && 
        <Box>
            <Typography variant="caption">
            {detail}
            </Typography>
        </Box>
        }
    </StepLabel>
    )
}