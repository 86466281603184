import { DatePicker, LocalizationProvider } from "@mui/lab";
import { DatePickerView } from "@mui/lab/DatePicker/shared";
import { capitalize, Skeleton, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FC, Fragment, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { GraphqlFormError, hasGraphqlErrorSlim } from './graphl-form-error';
import { NukFormContext } from './nuk-form-context';
import { NukFormError } from "./nuk-form-error";
import itLocale from 'date-fns/locale/it';

type Props = {
    name: string
    id?: string
    views?: DatePickerView[]
    label: string
    rules?: any
    inputFormat?: string
    mask?: string
}

/**
 * Mui Text Field Component wrapped in a React Hook Form Controller for maximum compatibility.
 * id: if not specified, is the same as name
 * @param props 
 * @returns 
 */
export const NukFormDateField : FC<Props> = (
  { name,
    id,
    label,
    rules,
    views = ['year','month','day'],
    inputFormat= "dd.MM.yyyy",
    mask = ""
  }) => {
    if(id==null) id = name
    const { control,formState: { errors }} = useFormContext()
    const { loading,gqlErrors } = useContext(NukFormContext)
    const component =         
    <Fragment> 
      <Controller
              name={name}
              control={control}
              defaultValue={null}
              rules={rules}
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
                <DatePicker
                  views={views}
                  label={label}
                  value={value}
                  onChange={(e) => onChange(e)}
                  inputFormat={inputFormat}
                  mask={mask}
                  renderInput={(params) => <TextField 
                      {...params} 
                      sx={{width:'100%'}}                   
                      error={(errors.hasOwnProperty(name) || hasGraphqlErrorSlim(gqlErrors,[name])) ? true : false}
                  />}
                />
              </LocalizationProvider>
              )}
      />
      <NukFormError field={name} errors={errors} />
      <GraphqlFormError errors={gqlErrors} filters={[name]}/>
    </Fragment>

    const skeleton = <Skeleton animation="wave" height={100}/>

    
    return ((!loading) ? component : skeleton)
}