import { Card, CardActionArea, Grid, Icon, Typography } from "@mui/material"
import { FC, useState } from "react"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

type Props = {
  label: string,
  description?: string,
  value: string | number,
  isCheckedDefault: boolean,
  checkedIcon?: JSX.Element,
  uncheckedIcon?: JSX.Element,
  color?: string,
  changeHandler: Function
}

/**
 * Card/Checkbox component which enables the user to check/uncheck a value on click
 */
export const NukFormCheckboxCard: FC<Props> = (
  {
    label,
    description,
    value,
    isCheckedDefault = false,
    checkedIcon = <CheckCircleOutlineIcon/>,
    uncheckedIcon = <RadioButtonUncheckedIcon />,
    color = 'primary',
    changeHandler
  }) => {
  const [checked, setChecked] = useState(isCheckedDefault)
    
  return (
    <Card sx={{
      cursor: 'pointer',
      backgroundColor: (checked) ? `${color}.main` : `${color}.paper`,
      color: (checked) ? `${color}.contrastText` : 'text.primary',
    }} onClick={() => {
      changeHandler(value, !checked)
      setChecked(!checked)
    }
    }>
      <CardActionArea>
        <Grid container>
          <Grid item md={3} sm={4} xs={4} sx={{ backgroundColor: 'rgba(0,0,0,0.1)' }} display="flex" alignItems="center" justifyContent="center">
            {checked ? checkedIcon  : uncheckedIcon}
          </Grid>
          <Grid item md={9} sm={8} xs={8} sx={{ p: 2 }}>
            <Typography fontWeight="bold">{label}</Typography>
            {description &&
              <Typography fontStyle="italic" variant="caption">{description}</Typography>
            }
          </Grid>
        </Grid>
        <input type="checkbox" value="" checked={checked} onChange={() => { }} style={{ display: 'none' }}></input>
      </CardActionArea>
    </Card>
  )
}