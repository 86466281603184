import { CircularProgress, Fab, FabProps, Skeleton } from "@mui/material";
import { FC } from "react";


interface Props extends FabProps {
    icon: JSX.Element,
    loading: boolean,
    skeleton?: boolean,    
    onClick?: any
}


export const NukFloatLoadingButton : FC<Props> = ({
    icon,
    loading = false,
    skeleton = false,
    onClick,
    size = "medium",
    color = "primary",
    disabled = false,
    type,
    sx
}) =>{
    return(
        !skeleton ?
                !loading ? 
                <Fab size={size} color={color} onClick={onClick} disabled={disabled} type={type} sx={sx}>
                    {icon}
                </Fab>
                :
                <Fab size={size} color={color} onClick={onClick} disabled={true} sx={sx}>
                    <CircularProgress color="inherit"/>
                </Fab>
        :
            <Skeleton>
                <Fab size={size}></Fab>
            </Skeleton>
    
    )
}