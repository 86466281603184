import { FC } from "react";
import Chart from 'react-apexcharts'


export const ExampleWeeklyRequestsChart : FC = () =>{
    return(
      <Chart options={
        {
          chart: {
            height:10,
            type: 'bar',
            toolbar:{
              show:false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['Lu', 'Ma', 'Me', 'Gio', 'Ve', 'Sa', 'Do'],
          },
          yaxis: {
            show:false,
            title: {
              text: '$ (thousands)'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "$ " + val + " thousands"
              }
            }
          }
        }
      } 
      
      series={[{
        name: 'Nuove richieste',
        data: [2, 0, 4, 3, 2, 1, 3]
      }]} type="bar" height={200} />
    )
}