

import { Box, Card, CardContent, Collapse, Grid, Typography } from "@mui/material"
import { FC, Fragment, useContext, useEffect } from "react"
import { NukFormDialog } from "../../../../../core/components/dialogs/nuk-form.dialog"
import { NukFormCheckboxField, NukFormDateField, NukFormDateTimeField, NukFormRadioGroupField, NukFormTextField, NukIconTitle } from "../../../../../core/nuk-components"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from "react-i18next";
import { NukFormDialogContext } from '../../../../../core/components/dialogs/nuk-form.dialog.context';
import { NukFormCountrySelect } from "../../../../../core/components/forms/nuk-form-country-select/nuk-form-country-select";
import FireplaceIcon from '@mui/icons-material/Fireplace';
import { NukFormSelectField } from "../../../../../core/components/forms/nuk-form-select-field";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { NukLabel } from "../../../../../core/components/labels/nuk-label";
import EventIcon from '@mui/icons-material/Event';
import { NukFormAutoCompleteTextField } from '../../../../../core/components/forms/nuk-form-autocomplete-text-field';
import nextId from "react-id-generator";
import { CremationZipCodeField } from "../../fields/cremation-zipcode-field";
import { CremationCityField } from "../../fields/cremation-city-field";
import { NukTimeUtils } from '../../../../../core/utils/nuk-time-utils';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { CompaniesSelectField } from "../../../../../modules/companies/components/companies-select";
import { useAppSelector } from "../../../../../features/hooks";
import { userHasRole } from "../../../../../modules/auth/utils/auth-user-role-checker";
type Props = {}

export const CremationRequestFormContent: FC<Props> = ({ }) => {
  const { formMethods } = useContext(NukFormDialogContext)
  const { t } = useTranslation()
  const trns = 'resourceCremation'
  const watchBirthYearOnly = formMethods?.watch("birthYearOnly", false)
  const watchDeathYearOnly = formMethods?.watch("deathYearOnly", false)
  const watchType = formMethods?.watch("type", 'NEW');
  const watchBellinzonaAssignment = formMethods?.watch("bellinzonaAssignment",false);
  const user = useAppSelector((state) => state.user.value)

  useEffect(() => {
    formMethods?.setValue('countryCode','CH')
    formMethods?.setValue('death',new Date())
    formMethods?.setValue('deliveryEmployee',`${user.firstName} ${user.lastName}`)
  })
  /**
   * Updates city based on selected zipCode
   */

  return (

    <Box>
      {userHasRole(user,['MASTER','MODERATOR']) &&
      <Card sx={{mb:1,background:(watchBellinzonaAssignment) ? '#db1f16': 'white'}}>
      <Grid item xs={12} sx={{display:'flex',padding:2,alignItems:'center'}}>

                <div style={{width:'75%',color:(watchBellinzonaAssignment) ? 'white': 'black'}}>
                  <NukFormCheckboxField label="Incarico da parte della città di Bellinzona" name="bellinzonaAssignment" />
                </div>
                <div style={{width:'25%'}}>
                  <img src={`/static/images/bellinzona-crest-${(watchBellinzonaAssignment) ? `enabled` : `disabled` }.svg`} style={{width:65,height:65,float:'right'}}/>
                </div>
            </Grid>
      </Card>
      }
            <Card sx={{mb:1}}>
        <CardContent>
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={6} >
              <NukIconTitle label="Onoranza" icon={<CorporateFareIcon />} textTransform="uppercase" />
            </Grid>
      
            <Grid item xs={12} sm={12} md={6} >
              <CompaniesSelectField/>
            </Grid>
          </Grid>
          </CardContent>
        </Card>
      <Card>
        <CardContent>
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12} >
              <NukIconTitle label="Dati defunto" icon={<AccountCircleIcon />} textTransform="uppercase" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <NukFormTextField
                name="lastName"
                label={t('lastName', { ns: 'general' })}
                sx={{
                  '& input': {
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: 24,
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <NukFormTextField
                name="firstName"
                label={t('firstName', { ns: 'general' })}
                sx={{
                  '& input': {
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: 24,
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>



              <NukFormDateField
                name="birth"
                label={
                  (watchBirthYearOnly === 'true') ? t('birthYear', { ns: trns }) : t('birthDate', { ns: trns })
                }
                views={
                  (watchBirthYearOnly === 'true') ? ['year'] : ['year', 'month', 'day']
                }
                inputFormat={
                  (watchBirthYearOnly === 'true') ? 'yyyy' : 'dd.MM.yyyy'
                }
              />


              <NukFormRadioGroupField
                size="small"
                name="birthYearOnly"
                items={
                  [
                    {
                      label: t('fullDate', { ns: trns }),
                      value: 'false',
                      default: true
                    },
                    {
                      label: t('yearOnly', { ns: trns }),
                      value: 'true'
                    }
                  ]
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>

              <NukFormDateField
                name="death"
                label={
                  (watchDeathYearOnly === 'true') ? t('deathYear', { ns: trns }) : t('deathDate', { ns: trns })
                }
                views={
                  (watchDeathYearOnly === 'true') ? ['year'] : ['year', 'month', 'day']
                }
                inputFormat={
                  (watchDeathYearOnly === 'true') ? 'yyyy' : 'dd.MM.yyyy'
                }
              />

              <NukFormRadioGroupField
                name="deathYearOnly"
                size="small"
                items={
                  [
                    {
                      label: t('fullDate', { ns: trns }),
                      value: 'false',
                      default: true
                    },
                    {
                      label: t('yearOnly', { ns: trns }),
                      value: 'true'
                    }
                  ]
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <NukFormCountrySelect name="countryCode" label="Nazionalità" />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <CremationZipCodeField />

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <CremationCityField />


            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 1 }}>
        <CardContent>
          <Grid container columnSpacing={2} rowSpacing={2}>

            <Grid item xs={12} sm={12} md={12}>
              <NukIconTitle label="Dettagli Cremazione" icon={<FireplaceIcon />} textTransform="uppercase" />
            </Grid>
            <Grid item sm={12} md={12}>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item sm={12} md={12}>


                  <Grid container rowSpacing={2} columnSpacing={2}>

                    <Grid item xs={12}>
                      <NukFormRadioGroupField
                        name="type"
                        label="Tipologia"
                        defaultValue="NEW"
                        items={
                          [
                            {
                              label: t('cremationTypeNEW', { ns: trns }),
                              value: "NEW",
                              default: true
                            },
                            {
                              label: t('cremationTypeEXHUMED', { ns: trns }),
                              value: "EXHUMED"
                            },
                            /** 
                            {
                              label: 'Cremazione di una salma con cofano di zinco',
                              value: "ZINC"
                            }
                            **/
                          ]
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>

                      <Collapse in={(watchType === 'EXHUMED')} >

                        <NukFormRadioGroupField
                          name="exhumationContainer"
                          label="Contenitore dei resti esumati"
                          defaultValue="LARGE"
                          items={
                            [
                              {
                                label: t('exhumationContainerLARGE', { ns: trns }),
                                value: "LARGE",
                                default: true
                              },
                              {
                                label: t('exhumationContainerSMALL', { ns: trns }),
                                value: "SMALL"
                              }
                            ]
                          }
                        />
                      </Collapse>
                    </Grid>

                    <Grid item xs={12}>
                    <NukFormRadioGroupField
                          name="medicalCertificateDelivery"
                          label="Consegna del certificato medico"
                          defaultValue="EMAIL"
                          items={
                            [
                              {
                                label: t('medicalCertificateDeliveryEMAIL', { ns: trns }),
                                value: "EMAIL",
                                default: true
                              },
                              {
                                label: t('medicalCertificateDeliveryHAND', { ns: trns }),
                                value: "HAND"
                              }
                            ]
                          }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>

                      <NukFormTextField 
                        name="deliveryEmployee" 
                        label="Nominativo del vostro incaricato che inoltra la richiesta" 
                      />

                    </Grid>

                  </Grid>


                </Grid>




              </Grid>
            </Grid>
          </Grid>
        </CardContent>


      </Card>
      <Card sx={{ mt: 1 }}>
        <CardContent>
          <Grid container columnSpacing={2} rowSpacing={2}>


            <Grid item xs={12} sm={12} md={12}>
              <NukIconTitle label="Consegna prevista della salma" icon={<LocalShippingIcon />} textTransform="uppercase" />
            </Grid>


            <Grid item xs={12} sm={12} md={6} lg={6}>


              <NukFormDateField name="estimatedDeliveryAtDate" label="Data" />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <NukFormSelectField
                name={"estimatedDeliveryAtTime"}
                items={
                  NukTimeUtils.getTimeValuesForSelect({
                    startHour: 0,
                    endHour: 23,
                    minuteBreak: 30
                  })}
                label={"Ora"} />
            </Grid>


          </Grid>
        </CardContent>
      </Card>

    </Box>
  )
}