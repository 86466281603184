import { WatchQueryFetchPolicy } from "@apollo/client";
import { ConstructionOutlined } from "@mui/icons-material";
import { Box, capitalize, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, SelectProps, Skeleton, Typography } from "@mui/material"
import { FC, Fragment, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
import { useListRolesQuery } from "../../../../graphql/nuk-graphql-main";
import { CremationStatus } from "../../constants/cremation-status.enum";
import { CremationStatusChip } from '../chips/cremation-status.chip';


interface Props extends SelectProps {
    statuses: string[],
    setStatuses: React.Dispatch<React.SetStateAction<string[]>>
    fetchPolicy?: WatchQueryFetchPolicy,
}

/**
 * A multi select with chips label for the Roles Resource
 * @param param
 * @returns 
 */
export const CremationStatusesMultiSelectChip: FC<Props> = ({
    statuses, 
    setStatuses,
    size = "medium",
    fetchPolicy = "cache-first"
}) => {

    const {t} = useTranslation()

    const data = [
        CremationStatus.REGISTERED,
        CremationStatus.DELIVERED,
        CremationStatus.STARTED,
        CremationStatus.COMPLETED,
        CremationStatus.COLLECTED,
    ]

    const onFilterStatusesChange = (event: SelectChangeEvent<any>) => {
        const {target: { value }} = event;
        setStatuses(value);
    };


   
    return (
            <FormControl sx={{ width: '100%'}} >
                <InputLabel sx={{marginTop:'-7px'}}>{capitalize(t('status',{ns:'general'}))}</InputLabel>
                <Select
                    size={size}
                    multiple
                    value={statuses}
                    onChange={onFilterStatusesChange}
                    input={<OutlinedInput label={capitalize(t('status',{ns:'general'}))} />}
                    renderValue={(selected) => 
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, margin:0}}>
                        {selected.map((status) =>  <div key={`status-selected-${status}`}><CremationStatusChip value={status as CremationStatus} /></div>)}
                        </Box>
                        }
                    >
                    {data.map((status) => (
                        <MenuItem key={`status-checkbox-${status}`} value={status}>
                             <Checkbox checked={statuses.includes(status)} /> 
                            <CremationStatusChip value={status as CremationStatus} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>              
    )
} 