import { AuthUser } from "../../../modules/auth/types/auth-user.type";
import { userHasRole } from '../../../modules/auth/utils/auth-user-role-checker';
import { INukResource } from "../../resources/nuk-resource.interface";

/**
 * Given a list of resources and an user
 * this function filters the resources removes the ones which are not matching the user roles.
 * @param user 
 * @param sections 
 * @returns 
 */
export const getAuthFilteredResources = (user: AuthUser, resources: INukResource[]): INukResource[] => {
    const finalResources : INukResource[] = []

    for(const resource of resources){
        if(
            resource.roles === undefined || 
            resource.roles === null || 
            resource.roles.length === 0 || 
            userHasRole(user, resource.roles)
        ) finalResources.push(resource)
    }
    return finalResources
}