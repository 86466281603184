import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  capitalize,
  Card,
  CardContent,
  CardMedia,
  createTheme,
  CssBaseline,
  Grid,
  Link,
  Modal,
  Paper,
  styled,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { Theme } from "../../../themes/nukleus/Theme";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FC, Fragment, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../features/hooks";
import { login } from "../redux/auth-user.slice";
import { AuthService, AuthServiceError } from "../services/auth.service";
import { AuthUser } from "../types/auth-user.type";
import { deepmerge } from "@mui/utils";
import BusinessIcon from "@mui/icons-material/Business";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { NukLabel } from "../../../core/components/labels/nuk-label";
import { NukIconTitle } from "../../../core/components/titles/nuk-icon-title";
import LoginIcon from "@mui/icons-material/Login";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
type FormValues = {
  email: string;
  password: string;
};

const pricing = [
  {
    label: "Cremazione salma DOMICILIATI (Comune di Bellinzona)",
    //price: "660.-",
    //price: "600.-",
    //price: "750.-",
    price: "800.-",
  },
  {
    label: "Cremazione salma NON domiciliati",
    //price: "800.-",
    // price: "600.-",
    //price: "750.-",
    price: "800.-",
  },
  /** 
  {
    label: "Cremazione salma con cofano di zinco",
    price: "1200.-",
  },
  **/
  {
    label: "Cremazione salma minori di 14 anni",
    price: "Gratuita",
  },
  {
    label: "Cremazione esumazioni (cofano in legno standard)",
    // price: "600.-",
    //price: "750.-",
    price: "800.-",
    //price: "900.-",
  },
  {
    label: "Cremazione resti (cofano in legno piccolo)",
    price: "350.-",
  },
];

export const IntroPage: FC = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const theme = createTheme(Theme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            padding: 0,
            height: "100%",
            backgroundColor: "green",
          },
        },
      },
    },
  });
  const smBreakPoint = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div
        style={{
          display: "flex",
          alignItems:'center',
          justifyContent:'center',
          flexDirection: smBreakPoint ? "column" : "row",
          padding:10,
          paddingTop:40
        }}
      >
        <div style={{ flex: 0.5, color: "#000", padding: 30,background:'#FFF',borderRadius:5 }}>
          <div style={{ fontWeight: "bold" }}>
            <div style={{display:'flex',justifyContent:'center'}}>
            <img
              style={{ width: "50%"}}
              src="/static/images/brand/brand-color.svg"
            />
            </div>

            <p style={{marginTop:50}}>
              La crematorio Ticino Sagl nasce dall’esigenza di far fronte al
              costante incremento del numero delle cremazioni sul territorio
              ticinese.
            </p>

            <p>
            L’impianto di cremazione è a disposizione delle imprese funebri Ticinesi riconosciute dal Dipartimento Cantonale della Sanità, nonché delle imprese funebri con sede nella Regione Lombardia.
            </p>

            <p>
              L’impianto di cremazione installato è un forno modello “Zeus 1000 /
              1200” prodotto e allestito dalle Officine Meccaniche Ciroldi di
              Ganaceto ( Modena ).
            </p>

            <p>
              Frutto di un’ esperienza aziendale di 50 anni, questo forno
              presenta una gestione del processo di cremazione di ultima
              generazione e che soddisfa in pieno le disposizioni vigenti in
              materia di norme sull’inquinamento e di combustione.
            </p>

            <p>
              Camera di postcombustione, mattoni refrattari, inserti in acciaio
              inox, sonde termiche, termocoppie, lamiere in carbonio, ecc., sono
              solo alcuni esempi di materiali utilizzati per la realizzazione di
              questo impianto moderno e performante.
            </p>
          </div>
          <div style={{marginTop:40}}>
            <Grid container style={{ fontSize: 16 }}>
              <Grid item md={4}>
                <div>
                  <b>Sede:</b>
                </div>
                <div>Via Riale Righetti 26</div>
                <div>CH-6503 Bellinzona-Carasso</div>
              </Grid>

              <Grid item md={4}>
           
              </Grid>
              <Grid item md={4}>
                <div>
                  <b>Contatti:</b>
                </div>
                <div><a href="mailto:info@crematorioticino.ch" style={{textDecoration:'none',color:'#52ad36',fontWeight:'bold'}}>info@crematorioticino.ch</a></div>
                <div><a href="tel:+41 79 608 30 47" style={{textDecoration:'none',color:'#52ad36',fontWeight:'bold'}}>+41 79 608 30 47</a></div>
              </Grid>
            </Grid>
          </div>
          <div style={{marginTop:40}}>
            <Button
              onClick={handleOpen}
              startIcon={<LocalOfferIcon />}
              style={{
                background: "#52ad36",
                color: "#FFF",
        
                width:'100%'
              }}
            >
              Tariffe
            </Button>
          </div>
          <div style={{ marginTop: 10 }}>
            <Button
              onClick={() => {
                navigate("/login");
              }}
              startIcon={<LoginIcon />}
              style={{
                background: "#52ad36",
                color: "#FFF",
                width:"100%"
              }}
            >
              Accesso Operatori
            </Button>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: smBreakPoint ? 500 : 1000,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ marginBottom: "10px" }}
              >
                Tariffe Crematorio Ticino
              </Typography>
              <table
                style={{
                  width: "100%",
                  fontSize: smBreakPoint ? 14 : undefined,
                }}
              >
                <thead></thead>
                <tbody>
                  {pricing.map((value, index) => (
                    <tr key={`pricing-tb-${index}`}>
                      <td style={{ paddingBottom: "10px" }}>{value.label}</td>
                      <td>{value.price}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Typography style={{ marginTop: "10px", fontSize: 16 }}>
                Le tariffe indicate sono escluse di IVA 8,1 %
              </Typography>
            </Box>
          </Modal>
        </div>
        {/*
        <div
          style={{
            flex: 6,
            minHeight: "100vh    ",
            backgroundImage: `url(https://ik.imagekit.io/l5fbcq4fm1l/TecnoCim/Crematorio/WP_20150731_10_22_52_Pro-scaled_nMu6R8n45.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1665469534966)`,
          }}
        ></div>
        */}
      </div>
    </ThemeProvider>
  );
};
