import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { LoadingButton } from '@mui/lab';
import { Alert, capitalize, Card, CardContent, CardMedia, createTheme, CssBaseline, Grid, Link, Paper, styled, ThemeProvider, useMediaQuery } from '@mui/material';
import { Theme } from '../../../themes/nukleus/Theme';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../features/hooks';
import { login } from '../redux/auth-user.slice';
import { AuthService, AuthServiceError } from '../services/auth.service';
import { AuthUser } from '../types/auth-user.type';
import { deepmerge } from '@mui/utils';
import BusinessIcon from '@mui/icons-material/Business';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { NukLabel } from '../../../core/components/labels/nuk-label';
import { NukIconTitle } from '../../../core/components/titles/nuk-icon-title';
import LoginIcon from '@mui/icons-material/Login';
type FormValues = {
  email: string;
  password: string;
};

export const LoginPage: FC = () => {
  //Usage
  const { register, handleSubmit } = useForm<FormValues>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  //Hooks
  const [loginFailedMessage, setLoginFailedMessage] = useState<string | null>(null);
  const [loginOngoing, setLoginOngoing] = useState(false);

  //On form submit
  const onSubmit: SubmitHandler<FormValues> = formData => {
    AuthService.login(
      {
        data: { ...formData },
        before: () => {
          setLoginOngoing(true)
        },
        onSuccess: (user: AuthUser) => {
          //Dispatching the user
          dispatch(login(user))
          //Navigating to profile
          navigate('/')
        },
        onError: (error: AuthServiceError) => {
          setLoginFailedMessage(error)
          setLoginOngoing(false)
        },

      }
    )

  };




  const theme = createTheme(Theme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: "#43a047",
          }

        }
      }
    },

  });
  const smBreakPoint = useMediaQuery(theme.breakpoints.down('md'));
  const xsBreakPoint = useMediaQuery(theme.breakpoints.down('sm'))

  const fieldSx =
  {
    '& .MuiInputLabel-root': {
      color: Theme.palette.primary.contrastText
    },
    '& .MuiFilledInput-root,.MuiInputLabel-filled,.MuiFormLabel-filled': {
      color: Theme.palette.primary.contrastText
    },
    '& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
      color: Theme.palette.primary.contrastText
    }

  }


  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />




      <Container component="main" sx={{ width: (smBreakPoint) ? '100%' : '40%' }}>
        <Card sx={{
          borderRadius: '0 0 3px 3px'
        }}>
          <CardContent>
            <Box sx={{
              display: 'flex',
              flex: 0.5,
              flexDirection: 'column',
              alignItems: 'center',
              mt: 12
            }}>

              <img style={{ width: '75%' }} src="/static/images/brand/brand-color.svg" />
            </Box>

            <Card sx={{mt:3}}>
              <CardContent>
              <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
              <NukIconTitle label="Autenticazione onoranze e operatori" icon={<LoginIcon/>}/>
              
              <TextField
                {...register('email', { required: true })}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                variant="filled"
              />


              <TextField
                {...register('password', { required: true })}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                variant="filled"
              />


              {loginFailedMessage &&
                <Alert severity="error">
                  {t(loginFailedMessage)}
                </Alert>
              }


              <LoadingButton
                loading={loginOngoing}
                loadingPosition="start"
                startIcon={<LoginIcon />}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loginOngoing
                  ? capitalize(t("signInOngoing", { ns: 'general' }))
                  : capitalize(t("signIn", { ns: 'general' }))
                }

              </LoadingButton>

            </Box>
              </CardContent>
            </Card>
           
          </CardContent>
        </Card>

        <Card sx={{mt:2}}>
          <CardContent>
            <Grid container>
              <Grid item md={6} >
                <NukLabel label="Sede" icon={<BusinessIcon/>}></NukLabel>
          
                <Typography variant="h6">
                Via Riale Righetti 26
                </Typography>
                <Typography variant="h6" sx={{mb:2}}>
                CH-6503 Bellinzona-Carasso
                </Typography>
          
    
              </Grid>
              <Grid item md={6}>
              <NukLabel label="Contatti" icon={<AlternateEmailIcon/>}></NukLabel>

                <Typography variant="h6">
                <Link href="mailto:info@crematorioticino.ch" sx={{textDecoration:'none'}}>

                  info@crematorioticino.ch
                  </Link>
                </Typography>
                <Typography variant="h6">
                <Link href="tel:+41796083047" sx={{textDecoration:'none'}} >

                +41 79 608 30 47
</Link>
                
                </Typography>
              </Grid>
            </Grid>
          </CardContent>

        </Card>





      </Container>


    </ThemeProvider>



  );


}