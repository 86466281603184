import { Chip } from "@mui/material"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { Role } from "../../../../../../graphql/nuk-graphql-main"

/**
 * User Roles custom column
 * @param t 
 * @returns 
 */
export const roleColumn =(t: Function) => {
  return{
    field: 'roles',
    headerName: t('roles',{ns:'general'}),
    width: 500,
    sortable: false,
    renderCell: (params: GridRenderCellParams<Role[]>) => (
      //@ts-ignore
      params.value.map((role: Role) => {
        return (
            <Chip key={role.id} label={role.title} color="primary" sx={{mr:0.5}} />
        )
      })
    )
  }}
    
