import { FC } from "react";
import { NukStructure } from "../../../core/nuk-components";
import { CremationsList } from "../components/lists/cremations-list/cremations-list";
/**
 * Users List Page
 * @returns FC
 */
export const CremationsListPage: FC = () => {
  return (
      <NukStructure>
            <CremationsList/>
      </NukStructure>
  )
}