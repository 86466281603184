import { FC } from 'react';
import { NukStructure } from "../../../core/nuk-components";
import { CremationEditorForm } from '../components/forms/cremation-editor/cremation-editor-form';
/**
 * User Editor Page
 * @param props 
 * @returns 
 */
export const CremationsEditorPage: FC = (props) => {
  return (
    <NukStructure>
        <CremationEditorForm/>
    </NukStructure>
  )
}