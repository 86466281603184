import axios, { AxiosResponse } from "axios";
import { request } from "https";

const endpoint = process.env.REACT_APP_API_ENDPOINT

export class Service{
    static post(requestPath: string,params: any,callBack: Function){
        //Calling before method (if exists)
        if(params.before) params.before()

        //Preparing call
        const path = this.createFullRequestPath(requestPath)
        axios.post(path, {
            ...params.data
          },{withCredentials:true})
          .then(function (response) {
            //Success 
            callBack(Service.prepareResponse(response))
            //Calling after method (if exists)
            if(params.after) params.after()
            })
          .catch(function (error) {
            //Error
            callBack(Service.prepareResponse(error.response,error))
            //Calling after method (if exists)
            if(params.after) params.after()
        });

    }


    private static createFullRequestPath(requestPath: string){
        //Removing trailing "/" if found
        if(requestPath.charAt(0)==="/")
            requestPath = requestPath.substring(1)

        return `${endpoint}/${requestPath}`
            
    }

    private static prepareResponse(response: AxiosResponse<any,any>, error?: any) : ServiceResponse{
    
        return {
            success:(error) ? false : true,
            //Response status or forced 503 (Server Unavailable) if not response received
            status: (response) ? response.status : 503,
            data: (response && response.data) ? response.data : null,
            message: (response && response.data.message) ? response.data.message : null
        }
    }
}

export interface ServiceParams {
    data: any,
    onSuccess: Function,
    onError: Function,
    before?: Function,
    after?: Function,
}

export type ServiceResponse = {
    success: boolean,
    status: number,
    data: any,
    message: string,
}