

import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab"
import { FC, Fragment, useContext } from "react"
import FastfoodIcon from '@mui/icons-material/Fastfood';
import { Box, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CremationStatus, CremationStatusColor } from "../../constants/cremation-status.enum";
import { CremationStatusIcon } from '../icons/cremation-status.icon';
import { CremationEventsTimelineItem } from "./cremation-events.timeline-item";
import { Cremation, GetCremationQuery } from "../../../../graphql/nuk-graphql-main";
import PersonIcon from '@mui/icons-material/Person';
import AcUnitIcon  from '@mui/icons-material/AcUnit';
import { CremationEventsTimelineItemSlim } from "./cremation-events.timeline-item-slim";
type Props = {
    data: GetCremationQuery
}

const oppositeSx = {
    py: 2
}
const contentSx = {
    py: 2.2
}

export const CremationEventsTimeline: FC<Props> = ({ data }) => {
    const theme = useTheme()
    const smBreakPoint = useMediaQuery(theme.breakpoints.down('md'))
    return (
        !smBreakPoint ? 
        <Timeline sx={{ '& .MuiTypography-root': { fontSize: 28, mt: -1 } }}>
            <CremationEventsTimelineItem
                status={CremationStatus.REGISTERED}
                time={data.cremation.createdAt}
            />
     

            {(data.cremation.estimatedDeliveryAt && data.cremation.status === CremationStatus.REGISTERED) &&     
                <CremationEventsTimelineItem
                            status={CremationStatus.DELIVERED}
                            time={data.cremation.estimatedDeliveryAt}
                            //forceOneHourLess
                            title="Consegna in cella prevista"
                        />
            }

            {data.cremation.status !==CremationStatus.REGISTERED &&
            <CremationEventsTimelineItem
                status={CremationStatus.DELIVERED}
                time={data.cremation.enteredCellAt}
                detailItems={data.cremation.enteredCell ? [
                    <Fragment>
                                           <AcUnitIcon/>

                        Cella {data.cremation.enteredCell}
                    </Fragment>
                ] : undefined}
            />
            }
            <CremationEventsTimelineItem
                status={CremationStatus.STARTED}
                time={data.cremation.startedAt}
            />
            <CremationEventsTimelineItem
                status={CremationStatus.COMPLETED}
                time={data.cremation.completedAt}
            />
            <CremationEventsTimelineItem
                status={CremationStatus.COLLECTED}
                time={data.cremation.collectedAt}
                detailItems={data.cremation.collectedBy ? [
                    <Fragment>
                        <PersonIcon/>
                        {data.cremation.collectedBy}
                    </Fragment>
                ] : undefined}
                last
            />

        </Timeline>
        : <Box>
            <CremationEventsTimelineItemSlim
                status={CremationStatus.REGISTERED}
                time={data.cremation.createdAt}
            />

            {(data.cremation.estimatedDeliveryAt && !data.cremation.enteredCell) &&     
                <CremationEventsTimelineItemSlim
                            status={CremationStatus.DELIVERED}
                            time={data.cremation.estimatedDeliveryAt}
                            //forceOneHourLess
                            title="Consegna in cella prevista"
                        />
            }

            <CremationEventsTimelineItemSlim
                status={CremationStatus.DELIVERED}
                time={data.cremation.enteredCellAt}
                detailItems={data.cremation.enteredCell ? [
                    <Fragment>
                                           <AcUnitIcon/>

                        Cella {data.cremation.enteredCell}
                    </Fragment>
                ] : undefined}
            />
            <CremationEventsTimelineItemSlim
                status={CremationStatus.STARTED}
                time={data.cremation.startedAt}
            />
            <CremationEventsTimelineItemSlim
                status={CremationStatus.COMPLETED}
                time={data.cremation.completedAt}
            />
            <CremationEventsTimelineItemSlim
                status={CremationStatus.COLLECTED}
                time={data.cremation.collectedAt}
                detailItems={data.cremation.collectedBy ? [
                    <Fragment>
                        <PersonIcon/>
                        {data.cremation.collectedBy}
                    </Fragment>
                ] : undefined}
                last
            />
        </Box>

    )
}