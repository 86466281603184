import { WatchQueryFetchPolicy } from "@apollo/client";
import { Box, capitalize, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, SelectProps, Skeleton, Typography } from "@mui/material"
import { FC, Fragment, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
import { useListRolesQuery } from "../../../../graphql/nuk-graphql-main";



interface Props extends SelectProps {
    roles: number[],
    setRoles: React.Dispatch<React.SetStateAction<number[]>>
    fetchPolicy?: WatchQueryFetchPolicy,
}

/**
 * A multi select with chips label for the Roles Resource
 * @param param
 * @returns 
 */
export const RolesMultiSelectChips: FC<Props> = ({
    roles, 
    setRoles,
    size = "medium",
    fetchPolicy = "cache-first"
}) => {
    const { loading, data } = useListRolesQuery({
        variables: {
            input: {
                pagination: {
                    page: 1,
                    limit: 100
                }
            }
        }, fetchPolicy: fetchPolicy,
    });
    const {t} = useTranslation()

    const onFilterRolesChange = (event: SelectChangeEvent<typeof roles>) => {
        const {target: { value }} = event;
        setRoles(
            typeof value === 'string' ? value.split(',').map((v) => Number(v)) : value,
        );
    };
   
    return (
            !loading ?
            <FormControl sx={{ width: '100%'}} >
                <InputLabel sx={{marginTop:'-7px'}}>{capitalize(t('role',{ns:'general'}))}</InputLabel>
                <Select
                    size={size}
                    multiple
                    value={roles}
                    onChange={onFilterRolesChange}
                    input={<OutlinedInput label={capitalize(t('role',{ns:'general'}))} />}
                    renderValue={(selected) => ((data) ?
                        (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, margin:0}}>
                                {data.roles.items.map((role) => {
                                    if (selected.includes(role.id))
                                        return <Chip key={role.id} label={role.title} color="primary" sx={{mt:0,mb:0}} />
                                })}
                            </Box>
                        )
                        : "")}
                    >
                    {data?.roles.items.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                            <Checkbox checked={roles.includes(role.id)} />
                            <ListItemText primary={role.title} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            : <Skeleton />
              
    )
} 