import { capitalize, Typography } from '@mui/material';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { translateYupError } from '../../yup/nuk-yup-i18next-locale';
import { NukFormContext } from './nuk-form-context';
type Props = {
  field: string,
  errors: any,
}

export const NukFormError: FC<Props> = (props) => {
  const { field, errors } = props
  const { trns } = useContext(NukFormContext)
  const { t } = useTranslation()
  if (errors.hasOwnProperty(field)) {
    return (
      <Typography color="error">{
        capitalize(
          translateYupError(t,trns,field,errors[field].message)
          )}</Typography>
    )
  }
  else
    return (<div></div>)
}

/**
 * Shorthand functions that verifies if the given field holds an error
 * @param error 
 * @returns 
 */
export const hasError = (error: any): boolean => {
  return (error !== undefined)

};