import * as Yup from 'yup'
import { nukYupI18nextLocale } from '../../../../../core/nuk-core'

//Enabling localization
Yup.setLocale(nukYupI18nextLocale)

/**
 * Cremation editor form create validation schema
 */
export const cremationEditorFormCreateSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  type: Yup.string().required(),
  exhumationContainer: Yup.string().when('type', {
    is: 'EXHUMED',
    then: Yup.string()
      .required()            
  })
})

/**
 * Cremation editor form update validation schema
 */
export const cremationEditorFormUpdateSchema = Yup.object().shape({

})


export type cremationEditorFormCreateSchemaType = Yup.InferType<typeof cremationEditorFormCreateSchema>
export type cremationEditorFormUpdateSchemaType = Yup.InferType<typeof cremationEditorFormUpdateSchema>