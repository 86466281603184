import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
import { NukFormContainer, NukFormContext } from '../../../../../core/nuk-components';
import { rolesResource as resourceConfig } from '../../../roles.resource';
import { RoleRemoveDialog } from '../../dialogs/role-remove.dialog';
import { executeRoleFormEditorCreate, useRoleFormEditorCreate, useRoleFormEditorCreateEffect } from './hooks/role-editor-form-create.hook';
import { useRoleFormEditorGet, useRoleFormEditorGetEffect } from './hooks/role-editor-form-get.hook';
import { executeRoleFormEditorUpdate, useRoleFormEditorUpdate, useRoleFormEditorUpdateEffect } from './hooks/role-editor-form-update.hook';
import { RoleEditorFormAppBar } from './role-editor-form.app-bar';
import { RoleEditorFormContent } from './role-editor-form.content';
import { roleEditorFormCreateSchema, roleEditorFormCreateSchemaType, roleEditorFormUpdateSchema, roleEditorFormUpdateSchemaType } from './role-editor-form.schema';

/**
 * Role Editor Form
 * All the hooks related to CRUD operations are inside this component.
 * @returns 
 */
export const RoleEditorForm:FC = () => {
  
    const { id } = useParams();
    const mode = (id) ? 'update' : 'create'
    const methods = useForm({ mode: 'onSubmit' ,reValidateMode:'onSubmit',resolver: 
        yupResolver((mode==="create") ? roleEditorFormCreateSchema : roleEditorFormUpdateSchema)});
    const { setValue, handleSubmit,formState:{isSubmitting}} = methods
    const navigate = useNavigate()
    const [resourceLabel, setResourceLabel] = useState("")
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [gqlErrors, setGqlErrors] = useState<ApolloError | null | undefined>(null);
    const trns = 'resourceUser'

  
    const hookDefaults = {
      setGqlError: setGqlErrors,
      setResourceLabel: setResourceLabel,
      setValue: setValue,
      navigate: navigate,
      mode: mode,
    }

    useEffect(() =>{
      setGqlErrors(null)
    },[isSubmitting])

 
    //Form Hooks
    const { loading: resourceLoading, data: resourceData, error: resourceError } = useRoleFormEditorGet({ id: Number(id) })
    const [createResource, { data: createdResourceData, loading: createResourceLoading, error: createResourceError }] = useRoleFormEditorCreate();
    const [updateResource, { data: updatedResourceData, loading: updateResourceLoading, error: updateResourceError }] = useRoleFormEditorUpdate()
  
    //Form Effect Hooks
    useRoleFormEditorGetEffect({data: resourceData,loading: resourceLoading,error: resourceError,...hookDefaults})
    useRoleFormEditorCreateEffect({ data: createdResourceData, loading: createResourceLoading, error: createResourceError, ...hookDefaults })
    useRoleFormEditorUpdateEffect({ data: updatedResourceData, loading: updateResourceLoading, error: updateResourceError, ...hookDefaults })

  
    //Form submission
    const onSubmit = (data : roleEditorFormCreateSchemaType | roleEditorFormUpdateSchemaType | any) => {
      
      if (mode === 'create') {
        executeRoleFormEditorCreate(createResource,data)
      }
      else if (mode === 'update') {
        executeRoleFormEditorUpdate(updateResource, Number(id), data)
      }
    };


  
    return(
        <NukFormContext.Provider value={
            {
              loading: resourceLoading,
              submitted: (createResourceLoading || updateResourceLoading),
              deleteDialogOpen: deleteDialogOpen,
              resourceConfig: resourceConfig,
              gqlErrors: gqlErrors,
              mode: (id) ? 'update' : 'create',
              trns: trns,
            }
          }>
            <FormProvider {...methods}  >
              <Box component="form" onSubmit={handleSubmit(data => onSubmit(data))}>
                <NukFormContainer>
                <RoleEditorFormAppBar setDeleteDialogOpen={setDeleteDialogOpen} resourceData={resourceData} resourceLabel={resourceLabel}/>
                  <RoleEditorFormContent resourceData={resourceData}/>
                  { (mode==='update' && resourceData) &&
                    //Remove Dialog
                    <RoleRemoveDialog 
                    open={deleteDialogOpen} 
                    setOpen={setDeleteDialogOpen} 
                    label={`${resourceData.role.title}`} 
                    mode={'static'} 
                    resourceId={resourceData.role.id} 
                    onCompleted={() => {
                      navigate(resourceConfig.indexPath)
                    }}
                />
                  }
                 </NukFormContainer>
              </Box>
              
        
            </FormProvider>
          </NukFormContext.Provider>
    )
}