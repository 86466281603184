import { createSlice } from '@reduxjs/toolkit'
import { AuthUser } from '../types/auth-user.type';
const initialState : AuthUser ={
    id: null,
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    roles: [],
    companies: [],
    tokensExpirationDates:{
        accessTokenExpirationDate: null,
        refreshTokenExpirationDate: null,
    }
   
}

export const userSlice = createSlice({
    name: 'user',
    initialState: {value: initialState },
    reducers: {
        login: (state,action) => {
            state.value = action.payload
        },
        logout: (state) =>{
            state.value = initialState
        },
        refreshTokens: (state,action) =>{
            state.value.tokensExpirationDates = action.payload
            
        }
    }
})

export const {login,logout,refreshTokens} = userSlice.actions 
export default userSlice.reducer