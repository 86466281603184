import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from "@mui/material";
import { FC, Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NukResourceListContext } from '../../../../../core/components/lists/nuk-list-context';
import { NukResourceListDetailModal, NukTextViewLabel } from "../../../../../core/nuk-components";
import { useGetRoleLazyQuery } from "../../../../../graphql/nuk-graphql-main";
import { RoleRemoveDialog } from "../../dialogs/role-remove.dialog";
type Props = {}

/**
 * Users List Detail Dialog  
 * @param param0 
 * @returns 
 */
export const RoleListDetailDialog: FC<Props> = ({ }) => {
    const { t } = useTranslation()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
    const { setDetailDialogOpen, refetch } = useContext(NukResourceListContext)
    //Fetch (Get) Detail query
    const [fetch, { data }] = useGetRoleLazyQuery()

    return (
        <NukResourceListDetailModal
            resource={data?.role}
            fetch={fetch}
            label={(data) ? `${data.role.title}`: ""}
            loading={false}
            actions={[
                {
                    label: t('remove', { ns: 'general' }),
                    icon: <DeleteIcon />,
                    onClick: () => setDeleteDialogOpen(true)

                },
            ]}
        >
            {data ?
                <Grid container rowGap={2}>
                    <Grid item xs={12} md={4}>
                        <NukTextViewLabel label="Id" value={data.role.id.toString()} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NukTextViewLabel label={t('name', { ns: 'general' })} value={data.role.title} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NukTextViewLabel label={t('value', { ns: 'general' })} value={data.role.value} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <NukTextViewLabel label={t('description', { ns: 'general' })} value={data.role.description} />
                    </Grid>
                </Grid>


                : <Fragment></Fragment>}

            {data &&
                <RoleRemoveDialog
                    open={deleteDialogOpen}
                    setOpen={setDeleteDialogOpen}
                    label={`${data.role.title}`}
                    mode={'static'}
                    resourceId={data.role.id}
                    onCompleted={() => {
                        if (setDetailDialogOpen) {
                            setDetailDialogOpen(false)
                            refetch()
                        }
                    }}
                />
            }
        </NukResourceListDetailModal>

    )
}