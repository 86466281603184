import { Box, Typography } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-pro";

/**
 * Role list detail column
 * (Used for xsBreakPoint to provide a better mobile experience)
 * ? This should become obsolete once MUI implements a default collapse in MuiDataGridProd
 * @param t 
 * @returns 
 */
export const roleListDetailColumn = (t: Function) => {
  return {
    field: 'title',
    headerName: t('details', { ns: 'general' }),
    sortable: false,
    flex:1,
    renderCell: (params: GridRowParams) => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', pl: 1 }}>
          <Typography fontWeight="bold">
            {params.row.title} 
          </Typography>
          <Typography fontStyle="italic">
            {params.row.description}
          </Typography>
        </Box>
      )
    }
  }
}

export const roleListDetailColumnHeight = 80