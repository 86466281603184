import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AlfredCompany = {
  __typename?: 'AlfredCompany';
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type ApplicationKey = {
  __typename?: 'ApplicationKey';
  apiKey?: Maybe<Scalars['String']>;
  apiKeyPrefix?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdByApplication?: Maybe<ApplicationKey>;
  createdByUser?: Maybe<User>;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['Float'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedByApplication?: Maybe<ApplicationKey>;
  updatedByUser?: Maybe<User>;
};

export type ApplicationKeyHistory = {
  __typename?: 'ApplicationKeyHistory';
  entityId: Scalars['Float'];
  executedAt: Scalars['DateTime'];
  executedByApplication?: Maybe<ApplicationKey>;
  executedByUser?: Maybe<User>;
  externalEntityId?: Maybe<Scalars['Float']>;
  externalEntityRelation?: Maybe<Scalars['String']>;
  externalExecutedByApplication?: Maybe<ApplicationKey>;
  externalExecutedByUser?: Maybe<User>;
  id: Scalars['Float'];
  metadataAfter?: Maybe<ApplicationKey>;
  metadataBefore?: Maybe<ApplicationKey>;
  operation: Scalars['String'];
};

export type ApplicationKeyHistoryList = {
  __typename?: 'ApplicationKeyHistoryList';
  items: Array<ApplicationKeyHistory>;
  meta: GqlPaginationMeta;
};

export type ApplicationKeyList = {
  __typename?: 'ApplicationKeyList';
  items: Array<ApplicationKey>;
  meta: GqlPaginationMeta;
};

export type Company = {
  __typename?: 'Company';
  address: Scalars['String'];
  city: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CompanyList = {
  __typename?: 'CompanyList';
  items: Array<Company>;
  meta: GqlPaginationMeta;
};

export type Content = {
  __typename?: 'Content';
  createdAt: Scalars['DateTime'];
  createdByApplication?: Maybe<ApplicationKey>;
  createdByUser?: Maybe<User>;
  id: Scalars['Float'];
  name: Scalars['String'];
  price: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  updatedByApplication?: Maybe<ApplicationKey>;
  updatedByApplicationId: Scalars['Float'];
  updatedByUser?: Maybe<User>;
};

export type ContentHistory = {
  __typename?: 'ContentHistory';
  entityId: Scalars['Float'];
  executedAt: Scalars['DateTime'];
  executedByApplication?: Maybe<ApplicationKey>;
  executedByUser?: Maybe<User>;
  externalEntityId?: Maybe<Scalars['Float']>;
  externalEntityRelation?: Maybe<Scalars['String']>;
  externalExecutedByApplication?: Maybe<ApplicationKey>;
  externalExecutedByUser?: Maybe<User>;
  id: Scalars['Float'];
  metadataAfter?: Maybe<Content>;
  metadataBefore?: Maybe<Content>;
  operation: Scalars['String'];
};

export type ContentHistoryList = {
  __typename?: 'ContentHistoryList';
  items: Array<ContentHistory>;
  meta: GqlPaginationMeta;
};

export type ContentList = {
  __typename?: 'ContentList';
  items: Array<Content>;
  meta: GqlPaginationMeta;
};

export type CreateApplicationKeyInput = {
  description: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type CreateContentInput = {
  name: Scalars['String'];
};

export type CreateCremationInput = {
  alfCompanyId: Scalars['Float'];
  bellinzonaAssignment?: InputMaybe<Scalars['Boolean']>;
  birth: Scalars['DateTime'];
  birthYearOnly?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  death: Scalars['DateTime'];
  deathYearOnly?: InputMaybe<Scalars['Boolean']>;
  deliveryEmployee: Scalars['String'];
  estimatedDeliveryAt: Scalars['DateTime'];
  exhumationContainer?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  medicalCertificateDelivery: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CreateMedicalCremationInput = {
  alfCompanyId: Scalars['Float'];
  estimatedDeliveryAt: Scalars['DateTime'];
  packets: Array<MedicalCremationPacket>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateOriginInput = {
  name: Scalars['String'];
};

export type CreateRoleInput = {
  description: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
};

export type CreateUserInput = {
  companies?: InputMaybe<Array<Scalars['Float']>>;
  email: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  repeatPassword: Scalars['String'];
  roles?: InputMaybe<Array<UserRoleInput>>;
  username: Scalars['String'];
};

export type Cremation = {
  __typename?: 'Cremation';
  alfCompanyId?: Maybe<Scalars['Float']>;
  alfId?: Maybe<Scalars['Float']>;
  bellinzonaAssignment?: Maybe<Scalars['Boolean']>;
  birth?: Maybe<Scalars['DateTime']>;
  birthYearOnly: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  collectedAt?: Maybe<Scalars['DateTime']>;
  collectedBy?: Maybe<Scalars['String']>;
  collectionQrCode?: Maybe<Scalars['String']>;
  collectionQrId?: Maybe<Scalars['Float']>;
  collectionQrScannedAt?: Maybe<Scalars['DateTime']>;
  company?: Maybe<AlfredCompany>;
  completedAt?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdByApplication?: Maybe<ApplicationKey>;
  createdByUser?: Maybe<User>;
  death?: Maybe<Scalars['DateTime']>;
  deathYearOnly: Scalars['Boolean'];
  deliveryEmployee?: Maybe<Scalars['String']>;
  deliveryEmployeeSourceId?: Maybe<Scalars['Float']>;
  deliveryQrCode?: Maybe<Scalars['String']>;
  deliveryQrId?: Maybe<Scalars['Float']>;
  deliveryQrScannedAt?: Maybe<Scalars['DateTime']>;
  enteredCell?: Maybe<Scalars['Float']>;
  enteredCellAt?: Maybe<Scalars['DateTime']>;
  estimatedDeliveryAt?: Maybe<Scalars['DateTime']>;
  exhumationContainer?: Maybe<Scalars['String']>;
  exitedCellAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  importSource?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  medicalCertificateDelivery: Scalars['String'];
  medicalCertificateFile?: Maybe<Scalars['String']>;
  medicalCertificateSigned?: Maybe<Scalars['Boolean']>;
  packets?: Maybe<Array<CremationPacket>>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  type: Scalars['String'];
  unavailableCellAtReservation?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
  updatedByApplication?: Maybe<ApplicationKey>;
  updatedByApplicationId: Scalars['Float'];
  updatedByUser?: Maybe<User>;
  zipCode?: Maybe<Scalars['String']>;
};

export type CremationHistory = {
  __typename?: 'CremationHistory';
  entityId: Scalars['Float'];
  executedAt: Scalars['DateTime'];
  executedByApplication?: Maybe<ApplicationKey>;
  executedByUser?: Maybe<User>;
  externalEntityId?: Maybe<Scalars['Float']>;
  externalEntityRelation?: Maybe<Scalars['String']>;
  externalExecutedByApplication?: Maybe<ApplicationKey>;
  externalExecutedByUser?: Maybe<User>;
  id: Scalars['Float'];
  metadataAfter?: Maybe<Cremation>;
  metadataBefore?: Maybe<Cremation>;
  operation: Scalars['String'];
};

export type CremationHistoryList = {
  __typename?: 'CremationHistoryList';
  items: Array<CremationHistory>;
  meta: GqlPaginationMeta;
};

export type CremationList = {
  __typename?: 'CremationList';
  items: Array<Cremation>;
  meta: GqlPaginationMeta;
};

export type CremationPacket = {
  __typename?: 'CremationPacket';
  content: Content;
  id: Scalars['Float'];
  origin: Origin;
  total: Scalars['Float'];
};

export type DateRangeInput = {
  end: Scalars['String'];
  start: Scalars['String'];
};

export type DefaultListFilters = {
  createdAtRange?: InputMaybe<DateRangeInput>;
  q?: InputMaybe<Scalars['String']>;
  updatedAtRange?: InputMaybe<DateRangeInput>;
};

export type DefaultListInput = {
  filters?: InputMaybe<DefaultListFilters>;
  pagination?: InputMaybe<GqlPaginationInput>;
};

export type FilterCremationsInput = {
  createdAtRange?: InputMaybe<DateRangeInput>;
  q?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<Scalars['String']>>;
  updatedAtRange?: InputMaybe<DateRangeInput>;
  year?: InputMaybe<Scalars['Float']>;
};

export type FilterUsersInput = {
  createdAtRange?: InputMaybe<DateRangeInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  q?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['Float']>>;
  updatedAtRange?: InputMaybe<DateRangeInput>;
};

export type GetCremationsByCellOccupationInput = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type GqlDefaultOperation = {
  __typename?: 'GqlDefaultOperation';
  operation: Scalars['String'];
  success: Scalars['Boolean'];
};

export type GqlPaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type GqlPaginationMeta = {
  __typename?: 'GqlPaginationMeta';
  currentPage: Scalars['Float'];
  itemCount: Scalars['Float'];
  itemsPerPage: Scalars['Float'];
  totalItems: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type ListCremationsInput = {
  filters?: InputMaybe<FilterCremationsInput>;
  pagination?: InputMaybe<GqlPaginationInput>;
};

export type ListHistoryFilters = {
  executedAtRange?: InputMaybe<DateRangeInput>;
  executedByApplication?: InputMaybe<Array<Scalars['Float']>>;
  executedByUser?: InputMaybe<Array<Scalars['Float']>>;
  externalEntityRelations?: InputMaybe<Array<Scalars['String']>>;
  externalExecutedByApplication?: InputMaybe<Array<Scalars['Float']>>;
  externalExecutedByUser?: InputMaybe<Array<Scalars['Float']>>;
  operations?: InputMaybe<Array<Scalars['String']>>;
};

export type ListHistoryInput = {
  filters?: InputMaybe<ListHistoryFilters>;
  pagination?: InputMaybe<GqlPaginationInput>;
};

export type ListRolesInput = {
  filters?: InputMaybe<DefaultListFilters>;
  pagination?: InputMaybe<GqlPaginationInput>;
};

export type ListUsersInput = {
  filters?: InputMaybe<FilterUsersInput>;
  pagination?: InputMaybe<GqlPaginationInput>;
};

export type MedicalCremationPacket = {
  contentId: Scalars['Float'];
  originId: Scalars['Float'];
  total: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createApplicationKey: ApplicationKey;
  createContent: Content;
  createCremation: Cremation;
  createMedicalCremation: Cremation;
  createOrigin: Origin;
  createRole: Role;
  createUser: User;
  refreshApplicationKey: ApplicationKey;
  removeApplicationKey: ApplicationKey;
  removeContent: Content;
  removeCremation: Cremation;
  removeOrigin: Origin;
  removeRefreshToken: RefreshToken;
  removeRole: Role;
  removeUser: User;
  removeUserRefreshTokens: Array<RefreshToken>;
  updateApplicationKey: ApplicationKey;
  updateContent: Content;
  updateCremation: Cremation;
  updateCremationStatus: Cremation;
  updateMedicalCremation: Cremation;
  updateOrigin: Origin;
  updateRole: Role;
  updateUser: User;
  updateUserPassword: User;
  updateUserPersonalPassword: GqlDefaultOperation;
};


export type MutationCreateApplicationKeyArgs = {
  input: CreateApplicationKeyInput;
};


export type MutationCreateContentArgs = {
  input: CreateContentInput;
};


export type MutationCreateCremationArgs = {
  input: CreateCremationInput;
};


export type MutationCreateMedicalCremationArgs = {
  input: CreateMedicalCremationInput;
};


export type MutationCreateOriginArgs = {
  input: CreateOriginInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationRefreshApplicationKeyArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveApplicationKeyArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveContentArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveCremationArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveOriginArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveRefreshTokenArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveRoleArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveUserRefreshTokensArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateApplicationKeyArgs = {
  id: Scalars['Int'];
  input: UpdateApplicationKeyInput;
};


export type MutationUpdateContentArgs = {
  id: Scalars['Int'];
  input: UpdateContentInput;
};


export type MutationUpdateCremationArgs = {
  id: Scalars['Int'];
  input: UpdateCremationInput;
};


export type MutationUpdateCremationStatusArgs = {
  id: Scalars['Int'];
  input: UpdateCremationStatusInput;
};


export type MutationUpdateMedicalCremationArgs = {
  id: Scalars['Int'];
  input: UpdateMedicalCremationInput;
};


export type MutationUpdateOriginArgs = {
  id: Scalars['Int'];
  input: UpdateOriginInput;
};


export type MutationUpdateRoleArgs = {
  id: Scalars['Int'];
  input: UpdateRoleInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['Int'];
  input: UpdateUserInput;
};


export type MutationUpdateUserPasswordArgs = {
  id: Scalars['Int'];
  input: UpdateUserPasswordInput;
};


export type MutationUpdateUserPersonalPasswordArgs = {
  input: UpdateUserPasswordPersonalInput;
};

export type Origin = {
  __typename?: 'Origin';
  createdAt: Scalars['DateTime'];
  createdByApplication?: Maybe<ApplicationKey>;
  createdByUser?: Maybe<User>;
  id: Scalars['Float'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedByApplication?: Maybe<ApplicationKey>;
  updatedByApplicationId: Scalars['Float'];
  updatedByUser?: Maybe<User>;
};

export type OriginHistory = {
  __typename?: 'OriginHistory';
  entityId: Scalars['Float'];
  executedAt: Scalars['DateTime'];
  executedByApplication?: Maybe<ApplicationKey>;
  executedByUser?: Maybe<User>;
  externalEntityId?: Maybe<Scalars['Float']>;
  externalEntityRelation?: Maybe<Scalars['String']>;
  externalExecutedByApplication?: Maybe<ApplicationKey>;
  externalExecutedByUser?: Maybe<User>;
  id: Scalars['Float'];
  metadataAfter?: Maybe<Origin>;
  metadataBefore?: Maybe<Origin>;
  operation: Scalars['String'];
};

export type OriginHistoryList = {
  __typename?: 'OriginHistoryList';
  items: Array<OriginHistory>;
  meta: GqlPaginationMeta;
};

export type OriginList = {
  __typename?: 'OriginList';
  items: Array<Origin>;
  meta: GqlPaginationMeta;
};

export type Query = {
  __typename?: 'Query';
  applicationKey: ApplicationKey;
  applicationKeys: ApplicationKeyList;
  companies: CompanyList;
  company: Company;
  content: Content;
  contents: ContentList;
  cremation: Cremation;
  cremations: CremationList;
  getApplicationKeyHistory: ApplicationKeyHistoryList;
  getContentHistory: ContentHistoryList;
  getCremationHistory: CremationHistoryList;
  getCremationsByCellOccupation: Array<Cremation>;
  getDailyArrivals: Array<Cremation>;
  getOriginHistory: OriginHistoryList;
  getRoleHistory: RoleHistoryList;
  getUserHistory: UserHistoryList;
  manyCompanies: Array<Company>;
  origin: Origin;
  origins: OriginList;
  role: Role;
  roles: RoleList;
  user: User;
  users: UserList;
};


export type QueryApplicationKeyArgs = {
  id: Scalars['Int'];
};


export type QueryApplicationKeysArgs = {
  input: DefaultListInput;
};


export type QueryCompaniesArgs = {
  input: DefaultListInput;
};


export type QueryCompanyArgs = {
  id: Scalars['Int'];
};


export type QueryContentArgs = {
  id: Scalars['Int'];
};


export type QueryContentsArgs = {
  input: DefaultListInput;
};


export type QueryCremationArgs = {
  id: Scalars['Int'];
};


export type QueryCremationsArgs = {
  input: ListCremationsInput;
};


export type QueryGetApplicationKeyHistoryArgs = {
  id: Scalars['Int'];
  input: ListHistoryInput;
};


export type QueryGetContentHistoryArgs = {
  id: Scalars['Int'];
  input: ListHistoryInput;
};


export type QueryGetCremationHistoryArgs = {
  id: Scalars['Int'];
  input: ListHistoryInput;
};


export type QueryGetCremationsByCellOccupationArgs = {
  input: GetCremationsByCellOccupationInput;
};


export type QueryGetOriginHistoryArgs = {
  id: Scalars['Int'];
  input: ListHistoryInput;
};


export type QueryGetRoleHistoryArgs = {
  id: Scalars['Int'];
  input: ListHistoryInput;
};


export type QueryGetUserHistoryArgs = {
  id: Scalars['Int'];
  input: ListHistoryInput;
};


export type QueryManyCompaniesArgs = {
  ids: Array<Scalars['Int']>;
};


export type QueryOriginArgs = {
  id: Scalars['Int'];
};


export type QueryOriginsArgs = {
  input: DefaultListInput;
};


export type QueryRoleArgs = {
  id: Scalars['Int'];
};


export type QueryRolesArgs = {
  input: ListRolesInput;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUsersArgs = {
  input: ListUsersInput;
};

export type RefreshToken = {
  __typename?: 'RefreshToken';
  id: Scalars['Float'];
  key: Scalars['String'];
  refresh_counter: Scalars['Float'];
  userAgent: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  createdAt: Scalars['DateTime'];
  createdByApplication?: Maybe<ApplicationKey>;
  createdByUser?: Maybe<User>;
  description: Scalars['String'];
  id: Scalars['Float'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedByApplication?: Maybe<ApplicationKey>;
  updatedByApplicationId: Scalars['Float'];
  updatedByUser?: Maybe<User>;
  value: Scalars['String'];
};

export type RoleHistory = {
  __typename?: 'RoleHistory';
  entityId: Scalars['Float'];
  executedAt: Scalars['DateTime'];
  executedByApplication?: Maybe<ApplicationKey>;
  executedByUser?: Maybe<User>;
  externalEntityId?: Maybe<Scalars['Float']>;
  externalEntityRelation?: Maybe<Scalars['String']>;
  externalExecutedByApplication?: Maybe<ApplicationKey>;
  externalExecutedByUser?: Maybe<User>;
  id: Scalars['Float'];
  metadataAfter?: Maybe<Role>;
  metadataBefore?: Maybe<Role>;
  operation: Scalars['String'];
};

export type RoleHistoryList = {
  __typename?: 'RoleHistoryList';
  items: Array<RoleHistory>;
  meta: GqlPaginationMeta;
};

export type RoleList = {
  __typename?: 'RoleList';
  items: Array<Role>;
  meta: GqlPaginationMeta;
};

export type UpdateApplicationKeyInput = {
  description?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateContentInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateCremationInput = {
  alfCompanyId: Scalars['Float'];
  bellinzonaAssignment?: InputMaybe<Scalars['Boolean']>;
  birth: Scalars['DateTime'];
  birthYearOnly?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  collectedAt?: InputMaybe<Scalars['DateTime']>;
  collectedBy?: InputMaybe<Scalars['String']>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  countryCode?: InputMaybe<Scalars['String']>;
  death: Scalars['DateTime'];
  deathYearOnly?: InputMaybe<Scalars['Boolean']>;
  deliveryEmployee?: InputMaybe<Scalars['String']>;
  enteredCell?: InputMaybe<Scalars['Float']>;
  enteredCellAt?: InputMaybe<Scalars['DateTime']>;
  estimatedDeliveryAt?: InputMaybe<Scalars['DateTime']>;
  exhumationContainer?: InputMaybe<Scalars['String']>;
  exitedCellAt?: InputMaybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  medicalCertificateDelivery?: InputMaybe<Scalars['String']>;
  medicalCertificateSigned?: InputMaybe<Scalars['Boolean']>;
  startedAt?: InputMaybe<Scalars['DateTime']>;
  status: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateCremationStatusInput = {
  status: Scalars['String'];
};

export type UpdateMedicalCremationInput = {
  alfCompanyId: Scalars['Float'];
  collectedAt?: InputMaybe<Scalars['DateTime']>;
  collectedBy?: InputMaybe<Scalars['String']>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  deliveryEmployee?: InputMaybe<Scalars['String']>;
  enteredCell?: InputMaybe<Scalars['Float']>;
  enteredCellAt?: InputMaybe<Scalars['DateTime']>;
  estimatedDeliveryAt?: InputMaybe<Scalars['DateTime']>;
  exitedCellAt?: InputMaybe<Scalars['DateTime']>;
  packets: Array<MedicalCremationPacket>;
  startedAt?: InputMaybe<Scalars['DateTime']>;
  status: Scalars['String'];
};

export type UpdateOriginInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateRoleInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
  variable?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  companies?: InputMaybe<Array<Scalars['Float']>>;
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<UserRoleInput>>;
  username?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  companies?: Maybe<Array<Scalars['Float']>>;
  companiesParsed?: Maybe<Array<AlfredCompany>>;
  createdAt: Scalars['DateTime'];
  createdByApplication?: Maybe<ApplicationKey>;
  createdByUser?: Maybe<User>;
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  refreshTokens?: Maybe<Array<RefreshToken>>;
  roles?: Maybe<Array<Role>>;
  updatedAt: Scalars['DateTime'];
  updatedByApplication?: Maybe<ApplicationKey>;
  updatedByApplicationId: Scalars['Float'];
  updatedByUser?: Maybe<User>;
  username: Scalars['String'];
};

export type UserHistory = {
  __typename?: 'UserHistory';
  entityId: Scalars['Float'];
  executedAt: Scalars['DateTime'];
  executedByApplication?: Maybe<ApplicationKey>;
  executedByUser?: Maybe<User>;
  externalEntityId?: Maybe<Scalars['Float']>;
  externalEntityRelation?: Maybe<Scalars['String']>;
  externalExecutedByApplication?: Maybe<ApplicationKey>;
  externalExecutedByUser?: Maybe<User>;
  id: Scalars['Float'];
  metadataAfter?: Maybe<User>;
  metadataBefore?: Maybe<User>;
  operation: Scalars['String'];
};

export type UserHistoryList = {
  __typename?: 'UserHistoryList';
  items: Array<UserHistory>;
  meta: GqlPaginationMeta;
};

export type UserList = {
  __typename?: 'UserList';
  items: Array<User>;
  meta: GqlPaginationMeta;
};

export type UserRoleInput = {
  id: Scalars['Int'];
};

export type UpdateUserPasswordInput = {
  password: Scalars['String'];
  repeatPassword: Scalars['String'];
};

export type UpdateUserPasswordPersonalInput = {
  oldPassword: Scalars['String'];
  password: Scalars['String'];
  repeatPassword: Scalars['String'];
};

export type ListCompaniesQueryVariables = Exact<{
  input: DefaultListInput;
}>;


export type ListCompaniesQuery = { __typename?: 'Query', companies: { __typename?: 'CompanyList', meta: { __typename?: 'GqlPaginationMeta', totalItems: number, itemCount: number, itemsPerPage: number, totalPages: number, currentPage: number }, items: Array<{ __typename?: 'Company', id: number, name: string, address: string, city: string, zipCode: string }> } };

export type ListContentsQueryVariables = Exact<{
  input: DefaultListInput;
}>;


export type ListContentsQuery = { __typename?: 'Query', contents: { __typename?: 'ContentList', meta: { __typename?: 'GqlPaginationMeta', totalItems: number, itemCount: number, itemsPerPage: number, totalPages: number, currentPage: number }, items: Array<{ __typename?: 'Content', id: number, name: string }> } };

export type ListOriginsQueryVariables = Exact<{
  input: DefaultListInput;
}>;


export type ListOriginsQuery = { __typename?: 'Query', origins: { __typename?: 'OriginList', meta: { __typename?: 'GqlPaginationMeta', totalItems: number, itemCount: number, itemsPerPage: number, totalPages: number, currentPage: number }, items: Array<{ __typename?: 'Origin', id: number, name: string }> } };

export type CreateApplicationKeyMutationVariables = Exact<{
  input: CreateApplicationKeyInput;
}>;


export type CreateApplicationKeyMutation = { __typename?: 'Mutation', createApplicationKey: { __typename?: 'ApplicationKey', id: number, name: string } };

export type GetApplicationKeyQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetApplicationKeyQuery = { __typename?: 'Query', applicationKey: { __typename?: 'ApplicationKey', id: number, name: string, description: string, apiKeyPrefix?: string | null } };

export type RemoveApplicationKeyMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveApplicationKeyMutation = { __typename?: 'Mutation', removeApplicationKey: { __typename?: 'ApplicationKey', id: number, name: string } };

export type UpdateApplicationKeyMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UpdateApplicationKeyInput;
}>;


export type UpdateApplicationKeyMutation = { __typename?: 'Mutation', updateApplicationKey: { __typename?: 'ApplicationKey', id: number, name: string } };

export type ListApplicationKeysQueryVariables = Exact<{
  input: DefaultListInput;
}>;


export type ListApplicationKeysQuery = { __typename?: 'Query', applicationKeys: { __typename?: 'ApplicationKeyList', meta: { __typename?: 'GqlPaginationMeta', totalItems: number, itemCount: number, itemsPerPage: number, totalPages: number, currentPage: number }, items: Array<{ __typename?: 'ApplicationKey', id: number, name: string, description: string, apiKeyPrefix?: string | null }> } };

export type CreateCremationMutationVariables = Exact<{
  input: CreateCremationInput;
}>;


export type CreateCremationMutation = { __typename?: 'Mutation', createCremation: { __typename?: 'Cremation', firstName?: string | null, lastName?: string | null, type: string, bellinzonaAssignment?: boolean | null, exhumationContainer?: string | null, unavailableCellAtReservation?: boolean | null, birth?: any | null, birthYearOnly: boolean, death?: any | null, deathYearOnly: boolean, city?: string | null, zipCode?: string | null, countryCode?: string | null, estimatedDeliveryAt?: any | null } };

export type GetDailyArrivalsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDailyArrivalsQuery = { __typename?: 'Query', getDailyArrivals: Array<{ __typename?: 'Cremation', id: number, firstName?: string | null, lastName?: string | null, estimatedDeliveryAt?: any | null, company?: { __typename?: 'AlfredCompany', name: string } | null }> };

export type GetCremationsByCellOccupationQueryVariables = Exact<{
  input: GetCremationsByCellOccupationInput;
}>;


export type GetCremationsByCellOccupationQuery = { __typename?: 'Query', getCremationsByCellOccupation: Array<{ __typename?: 'Cremation', firstName?: string | null, lastName?: string | null, enteredCell?: number | null, type: string, estimatedDeliveryAt?: any | null, enteredCellAt?: any | null, exitedCellAt?: any | null, deliveryQrScannedAt?: any | null, unavailableCellAtReservation?: boolean | null }> };

export type GetCremationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCremationQuery = { __typename?: 'Query', cremation: { __typename?: 'Cremation', id: number, alfId?: number | null, bellinzonaAssignment?: boolean | null, status: string, estimatedDeliveryAt?: any | null, enteredCellAt?: any | null, enteredCell?: number | null, deliveryQrId?: number | null, deliveryQrScannedAt?: any | null, exitedCellAt?: any | null, startedAt?: any | null, completedAt?: any | null, collectedAt?: any | null, collectedBy?: string | null, collectionQrId?: number | null, collectionQrScannedAt?: any | null, deliveryEmployee?: string | null, firstName?: string | null, lastName?: string | null, type: string, exhumationContainer?: string | null, birth?: any | null, birthYearOnly: boolean, death?: any | null, deathYearOnly: boolean, city?: string | null, zipCode?: string | null, medicalCertificateSigned?: boolean | null, medicalCertificateFile?: string | null, medicalCertificateDelivery: string, countryCode?: string | null, createdAt: any, updatedAt: any, packets?: Array<{ __typename?: 'CremationPacket', id: number, total: number, origin: { __typename?: 'Origin', id: number, name: string }, content: { __typename?: 'Content', id: number, name: string } }> | null, company?: { __typename?: 'AlfredCompany', id: number, name: string } | null, createdByUser?: { __typename?: 'User', firstName: string, lastName: string } | null, createdByApplication?: { __typename?: 'ApplicationKey', name: string } | null, updatedByUser?: { __typename?: 'User', firstName: string, lastName: string } | null, updatedByApplication?: { __typename?: 'ApplicationKey', name: string } | null } };

export type CreateMedicalCremationMutationVariables = Exact<{
  input: CreateMedicalCremationInput;
}>;


export type CreateMedicalCremationMutation = { __typename?: 'Mutation', createMedicalCremation: { __typename?: 'Cremation', id: number, type: string, estimatedDeliveryAt?: any | null, unavailableCellAtReservation?: boolean | null, alfCompanyId?: number | null, packets?: Array<{ __typename?: 'CremationPacket', id: number, total: number, origin: { __typename?: 'Origin', id: number, name: string }, content: { __typename?: 'Content', id: number, name: string } }> | null } };

export type RemoveCremationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveCremationMutation = { __typename?: 'Mutation', removeCremation: { __typename?: 'Cremation', id: number, firstName?: string | null, lastName?: string | null, type: string, exhumationContainer?: string | null, birth?: any | null, birthYearOnly: boolean, death?: any | null, deathYearOnly: boolean, city?: string | null, zipCode?: string | null, countryCode?: string | null } };

export type UpdateMedicalCremationMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UpdateMedicalCremationInput;
}>;


export type UpdateMedicalCremationMutation = { __typename?: 'Mutation', updateMedicalCremation: { __typename?: 'Cremation', id: number, alfId?: number | null, status: string, estimatedDeliveryAt?: any | null, deliveryQrId?: number | null, deliveryQrScannedAt?: any | null, enteredCellAt?: any | null, enteredCell?: number | null, exitedCellAt?: any | null, startedAt?: any | null, completedAt?: any | null, collectedAt?: any | null, collectedBy?: string | null, collectionQrId?: number | null, collectionQrScannedAt?: any | null, medicalCertificateSigned?: boolean | null, firstName?: string | null, lastName?: string | null, type: string, exhumationContainer?: string | null, birth?: any | null, birthYearOnly: boolean, death?: any | null, deathYearOnly: boolean, city?: string | null, zipCode?: string | null, countryCode?: string | null, packets?: Array<{ __typename?: 'CremationPacket', id: number, origin: { __typename?: 'Origin', id: number, name: string }, content: { __typename?: 'Content', id: number, name: string } }> | null } };

export type UpdateCremationStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UpdateCremationStatusInput;
}>;


export type UpdateCremationStatusMutation = { __typename?: 'Mutation', updateCremationStatus: { __typename?: 'Cremation', id: number, status: string, estimatedDeliveryAt?: any | null, enteredCellAt?: any | null, enteredCell?: number | null, exitedCellAt?: any | null, startedAt?: any | null, completedAt?: any | null, collectedAt?: any | null, collectedBy?: string | null, medicalCertificateSigned?: boolean | null, firstName?: string | null, lastName?: string | null, type: string, exhumationContainer?: string | null, birth?: any | null, birthYearOnly: boolean, death?: any | null, deathYearOnly: boolean, city?: string | null, zipCode?: string | null, countryCode?: string | null, packets?: Array<{ __typename?: 'CremationPacket', id: number, origin: { __typename?: 'Origin', id: number, name: string }, content: { __typename?: 'Content', id: number, name: string } }> | null } };

export type UpdateCremationMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UpdateCremationInput;
}>;


export type UpdateCremationMutation = { __typename?: 'Mutation', updateCremation: { __typename?: 'Cremation', id: number, alfId?: number | null, status: string, estimatedDeliveryAt?: any | null, deliveryQrId?: number | null, deliveryQrScannedAt?: any | null, enteredCellAt?: any | null, enteredCell?: number | null, exitedCellAt?: any | null, startedAt?: any | null, completedAt?: any | null, collectedAt?: any | null, collectedBy?: string | null, collectionQrId?: number | null, collectionQrScannedAt?: any | null, medicalCertificateSigned?: boolean | null, firstName?: string | null, lastName?: string | null, type: string, exhumationContainer?: string | null, birth?: any | null, birthYearOnly: boolean, death?: any | null, deathYearOnly: boolean, city?: string | null, zipCode?: string | null, countryCode?: string | null } };

export type ListCremationsQueryVariables = Exact<{
  input: ListCremationsInput;
}>;


export type ListCremationsQuery = { __typename?: 'Query', cremations: { __typename?: 'CremationList', meta: { __typename?: 'GqlPaginationMeta', totalItems: number, itemCount: number, itemsPerPage: number, totalPages: number, currentPage: number }, items: Array<{ __typename?: 'Cremation', id: number, alfId?: number | null, bellinzonaAssignment?: boolean | null, status: string, createdAt: any, estimatedDeliveryAt?: any | null, deliveryQrId?: number | null, deliveryQrScannedAt?: any | null, enteredCellAt?: any | null, enteredCell?: number | null, unavailableCellAtReservation?: boolean | null, exitedCellAt?: any | null, startedAt?: any | null, completedAt?: any | null, collectedAt?: any | null, collectionQrId?: number | null, collectionQrScannedAt?: any | null, medicalCertificateSigned?: boolean | null, firstName?: string | null, lastName?: string | null, type: string, exhumationContainer?: string | null, birth?: any | null, birthYearOnly: boolean, death?: any | null, deathYearOnly: boolean, city?: string | null, zipCode?: string | null, countryCode?: string | null, packets?: Array<{ __typename?: 'CremationPacket', id: number, total: number }> | null, company?: { __typename?: 'AlfredCompany', id: number, name: string } | null }> } };

export type UpdateUserPersonalPasswordMutationVariables = Exact<{
  input: UpdateUserPasswordPersonalInput;
}>;


export type UpdateUserPersonalPasswordMutation = { __typename?: 'Mutation', updateUserPersonalPassword: { __typename?: 'GqlDefaultOperation', operation: string, success: boolean } };

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'Role', id: number, title: string, value: string, description: string } };

export type GetRoleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetRoleQuery = { __typename?: 'Query', role: { __typename?: 'Role', id: number, title: string, value: string, description: string, createdAt: any, updatedAt: any, createdByUser?: { __typename?: 'User', firstName: string, lastName: string } | null, createdByApplication?: { __typename?: 'ApplicationKey', name: string } | null, updatedByUser?: { __typename?: 'User', firstName: string, lastName: string } | null, updatedByApplication?: { __typename?: 'ApplicationKey', name: string } | null } };

export type RemoveRoleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveRoleMutation = { __typename?: 'Mutation', removeRole: { __typename?: 'Role', id: number, title: string, value: string, description: string } };

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UpdateRoleInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'Role', id: number, title: string, value: string, description: string } };

export type ListRolesQueryVariables = Exact<{
  input: ListRolesInput;
}>;


export type ListRolesQuery = { __typename?: 'Query', roles: { __typename?: 'RoleList', meta: { __typename?: 'GqlPaginationMeta', totalItems: number, itemCount: number, itemsPerPage: number, totalPages: number, currentPage: number }, items: Array<{ __typename?: 'Role', id: number, title: string, value: string, description: string }> } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: number, firstName: string, lastName: string, email: string, username: string, enabled: boolean, roles?: Array<{ __typename?: 'Role', id: number, title: string, description: string, value: string }> | null } };

export type GetUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: number, firstName: string, lastName: string, email: string, username: string, enabled: boolean, createdAt: any, updatedAt: any, companies?: Array<number> | null, createdByUser?: { __typename?: 'User', firstName: string, lastName: string } | null, createdByApplication?: { __typename?: 'ApplicationKey', name: string } | null, updatedByUser?: { __typename?: 'User', firstName: string, lastName: string } | null, updatedByApplication?: { __typename?: 'ApplicationKey', name: string } | null, roles?: Array<{ __typename?: 'Role', id: number, title: string, description: string, value: string }> | null } };

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveUserMutation = { __typename?: 'Mutation', removeUser: { __typename?: 'User', id: number, username: string, firstName: string, lastName: string, email: string, enabled: boolean, roles?: Array<{ __typename?: 'Role', id: number, title: string, description: string }> | null } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: number, firstName: string, lastName: string, email: string, username: string, enabled: boolean, roles?: Array<{ __typename?: 'Role', id: number, title: string, description: string, value: string }> | null } };

export type ListUsersQueryVariables = Exact<{
  input: ListUsersInput;
}>;


export type ListUsersQuery = { __typename?: 'Query', users: { __typename?: 'UserList', meta: { __typename?: 'GqlPaginationMeta', totalItems: number, itemCount: number, itemsPerPage: number, totalPages: number, currentPage: number }, items: Array<{ __typename?: 'User', id: number, username: string, firstName: string, lastName: string, email: string, enabled: boolean, companiesParsed?: Array<{ __typename?: 'AlfredCompany', id: number, name: string }> | null, roles?: Array<{ __typename?: 'Role', id: number, title: string, description: string }> | null }> } };


export const ListCompaniesDocument = gql`
    query listCompanies($input: DefaultListInput!) {
  companies(input: $input) {
    meta {
      totalItems
      itemCount
      itemsPerPage
      totalPages
      currentPage
    }
    items {
      id
      name
      address
      city
      zipCode
    }
  }
}
    `;

/**
 * __useListCompaniesQuery__
 *
 * To run a query within a React component, call `useListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompaniesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListCompaniesQuery(baseOptions: Apollo.QueryHookOptions<ListCompaniesQuery, ListCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompaniesQuery, ListCompaniesQueryVariables>(ListCompaniesDocument, options);
      }
export function useListCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompaniesQuery, ListCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompaniesQuery, ListCompaniesQueryVariables>(ListCompaniesDocument, options);
        }
export type ListCompaniesQueryHookResult = ReturnType<typeof useListCompaniesQuery>;
export type ListCompaniesLazyQueryHookResult = ReturnType<typeof useListCompaniesLazyQuery>;
export type ListCompaniesQueryResult = Apollo.QueryResult<ListCompaniesQuery, ListCompaniesQueryVariables>;
export const ListContentsDocument = gql`
    query listContents($input: DefaultListInput!) {
  contents(input: $input) {
    meta {
      totalItems
      itemCount
      itemsPerPage
      totalPages
      currentPage
    }
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useListContentsQuery__
 *
 * To run a query within a React component, call `useListContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListContentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListContentsQuery(baseOptions: Apollo.QueryHookOptions<ListContentsQuery, ListContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListContentsQuery, ListContentsQueryVariables>(ListContentsDocument, options);
      }
export function useListContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListContentsQuery, ListContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListContentsQuery, ListContentsQueryVariables>(ListContentsDocument, options);
        }
export type ListContentsQueryHookResult = ReturnType<typeof useListContentsQuery>;
export type ListContentsLazyQueryHookResult = ReturnType<typeof useListContentsLazyQuery>;
export type ListContentsQueryResult = Apollo.QueryResult<ListContentsQuery, ListContentsQueryVariables>;
export const ListOriginsDocument = gql`
    query listOrigins($input: DefaultListInput!) {
  origins(input: $input) {
    meta {
      totalItems
      itemCount
      itemsPerPage
      totalPages
      currentPage
    }
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useListOriginsQuery__
 *
 * To run a query within a React component, call `useListOriginsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOriginsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOriginsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListOriginsQuery(baseOptions: Apollo.QueryHookOptions<ListOriginsQuery, ListOriginsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOriginsQuery, ListOriginsQueryVariables>(ListOriginsDocument, options);
      }
export function useListOriginsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOriginsQuery, ListOriginsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOriginsQuery, ListOriginsQueryVariables>(ListOriginsDocument, options);
        }
export type ListOriginsQueryHookResult = ReturnType<typeof useListOriginsQuery>;
export type ListOriginsLazyQueryHookResult = ReturnType<typeof useListOriginsLazyQuery>;
export type ListOriginsQueryResult = Apollo.QueryResult<ListOriginsQuery, ListOriginsQueryVariables>;
export const CreateApplicationKeyDocument = gql`
    mutation createApplicationKey($input: CreateApplicationKeyInput!) {
  createApplicationKey(input: $input) {
    id
    name
  }
}
    `;
export type CreateApplicationKeyMutationFn = Apollo.MutationFunction<CreateApplicationKeyMutation, CreateApplicationKeyMutationVariables>;

/**
 * __useCreateApplicationKeyMutation__
 *
 * To run a mutation, you first call `useCreateApplicationKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationKeyMutation, { data, loading, error }] = useCreateApplicationKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicationKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateApplicationKeyMutation, CreateApplicationKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApplicationKeyMutation, CreateApplicationKeyMutationVariables>(CreateApplicationKeyDocument, options);
      }
export type CreateApplicationKeyMutationHookResult = ReturnType<typeof useCreateApplicationKeyMutation>;
export type CreateApplicationKeyMutationResult = Apollo.MutationResult<CreateApplicationKeyMutation>;
export type CreateApplicationKeyMutationOptions = Apollo.BaseMutationOptions<CreateApplicationKeyMutation, CreateApplicationKeyMutationVariables>;
export const GetApplicationKeyDocument = gql`
    query getApplicationKey($id: Int!) {
  applicationKey(id: $id) {
    id
    name
    description
    apiKeyPrefix
  }
}
    `;

/**
 * __useGetApplicationKeyQuery__
 *
 * To run a query within a React component, call `useGetApplicationKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationKeyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicationKeyQuery(baseOptions: Apollo.QueryHookOptions<GetApplicationKeyQuery, GetApplicationKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationKeyQuery, GetApplicationKeyQueryVariables>(GetApplicationKeyDocument, options);
      }
export function useGetApplicationKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationKeyQuery, GetApplicationKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationKeyQuery, GetApplicationKeyQueryVariables>(GetApplicationKeyDocument, options);
        }
export type GetApplicationKeyQueryHookResult = ReturnType<typeof useGetApplicationKeyQuery>;
export type GetApplicationKeyLazyQueryHookResult = ReturnType<typeof useGetApplicationKeyLazyQuery>;
export type GetApplicationKeyQueryResult = Apollo.QueryResult<GetApplicationKeyQuery, GetApplicationKeyQueryVariables>;
export const RemoveApplicationKeyDocument = gql`
    mutation removeApplicationKey($id: Int!) {
  removeApplicationKey(id: $id) {
    id
    name
  }
}
    `;
export type RemoveApplicationKeyMutationFn = Apollo.MutationFunction<RemoveApplicationKeyMutation, RemoveApplicationKeyMutationVariables>;

/**
 * __useRemoveApplicationKeyMutation__
 *
 * To run a mutation, you first call `useRemoveApplicationKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApplicationKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApplicationKeyMutation, { data, loading, error }] = useRemoveApplicationKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveApplicationKeyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveApplicationKeyMutation, RemoveApplicationKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveApplicationKeyMutation, RemoveApplicationKeyMutationVariables>(RemoveApplicationKeyDocument, options);
      }
export type RemoveApplicationKeyMutationHookResult = ReturnType<typeof useRemoveApplicationKeyMutation>;
export type RemoveApplicationKeyMutationResult = Apollo.MutationResult<RemoveApplicationKeyMutation>;
export type RemoveApplicationKeyMutationOptions = Apollo.BaseMutationOptions<RemoveApplicationKeyMutation, RemoveApplicationKeyMutationVariables>;
export const UpdateApplicationKeyDocument = gql`
    mutation updateApplicationKey($id: Int!, $input: UpdateApplicationKeyInput!) {
  updateApplicationKey(id: $id, input: $input) {
    id
    name
  }
}
    `;
export type UpdateApplicationKeyMutationFn = Apollo.MutationFunction<UpdateApplicationKeyMutation, UpdateApplicationKeyMutationVariables>;

/**
 * __useUpdateApplicationKeyMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationKeyMutation, { data, loading, error }] = useUpdateApplicationKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationKeyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicationKeyMutation, UpdateApplicationKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicationKeyMutation, UpdateApplicationKeyMutationVariables>(UpdateApplicationKeyDocument, options);
      }
export type UpdateApplicationKeyMutationHookResult = ReturnType<typeof useUpdateApplicationKeyMutation>;
export type UpdateApplicationKeyMutationResult = Apollo.MutationResult<UpdateApplicationKeyMutation>;
export type UpdateApplicationKeyMutationOptions = Apollo.BaseMutationOptions<UpdateApplicationKeyMutation, UpdateApplicationKeyMutationVariables>;
export const ListApplicationKeysDocument = gql`
    query listApplicationKeys($input: DefaultListInput!) {
  applicationKeys(input: $input) {
    meta {
      totalItems
      itemCount
      itemsPerPage
      totalPages
      currentPage
    }
    items {
      id
      name
      description
      apiKeyPrefix
    }
  }
}
    `;

/**
 * __useListApplicationKeysQuery__
 *
 * To run a query within a React component, call `useListApplicationKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useListApplicationKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListApplicationKeysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListApplicationKeysQuery(baseOptions: Apollo.QueryHookOptions<ListApplicationKeysQuery, ListApplicationKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListApplicationKeysQuery, ListApplicationKeysQueryVariables>(ListApplicationKeysDocument, options);
      }
export function useListApplicationKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListApplicationKeysQuery, ListApplicationKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListApplicationKeysQuery, ListApplicationKeysQueryVariables>(ListApplicationKeysDocument, options);
        }
export type ListApplicationKeysQueryHookResult = ReturnType<typeof useListApplicationKeysQuery>;
export type ListApplicationKeysLazyQueryHookResult = ReturnType<typeof useListApplicationKeysLazyQuery>;
export type ListApplicationKeysQueryResult = Apollo.QueryResult<ListApplicationKeysQuery, ListApplicationKeysQueryVariables>;
export const CreateCremationDocument = gql`
    mutation createCremation($input: CreateCremationInput!) {
  createCremation(input: $input) {
    firstName
    lastName
    type
    bellinzonaAssignment
    exhumationContainer
    unavailableCellAtReservation
    birth
    birthYearOnly
    death
    deathYearOnly
    city
    zipCode
    countryCode
    estimatedDeliveryAt
  }
}
    `;
export type CreateCremationMutationFn = Apollo.MutationFunction<CreateCremationMutation, CreateCremationMutationVariables>;

/**
 * __useCreateCremationMutation__
 *
 * To run a mutation, you first call `useCreateCremationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCremationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCremationMutation, { data, loading, error }] = useCreateCremationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCremationMutation(baseOptions?: Apollo.MutationHookOptions<CreateCremationMutation, CreateCremationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCremationMutation, CreateCremationMutationVariables>(CreateCremationDocument, options);
      }
export type CreateCremationMutationHookResult = ReturnType<typeof useCreateCremationMutation>;
export type CreateCremationMutationResult = Apollo.MutationResult<CreateCremationMutation>;
export type CreateCremationMutationOptions = Apollo.BaseMutationOptions<CreateCremationMutation, CreateCremationMutationVariables>;
export const GetDailyArrivalsDocument = gql`
    query getDailyArrivals {
  getDailyArrivals {
    id
    firstName
    lastName
    estimatedDeliveryAt
    company {
      name
    }
  }
}
    `;

/**
 * __useGetDailyArrivalsQuery__
 *
 * To run a query within a React component, call `useGetDailyArrivalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyArrivalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyArrivalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDailyArrivalsQuery(baseOptions?: Apollo.QueryHookOptions<GetDailyArrivalsQuery, GetDailyArrivalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDailyArrivalsQuery, GetDailyArrivalsQueryVariables>(GetDailyArrivalsDocument, options);
      }
export function useGetDailyArrivalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDailyArrivalsQuery, GetDailyArrivalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDailyArrivalsQuery, GetDailyArrivalsQueryVariables>(GetDailyArrivalsDocument, options);
        }
export type GetDailyArrivalsQueryHookResult = ReturnType<typeof useGetDailyArrivalsQuery>;
export type GetDailyArrivalsLazyQueryHookResult = ReturnType<typeof useGetDailyArrivalsLazyQuery>;
export type GetDailyArrivalsQueryResult = Apollo.QueryResult<GetDailyArrivalsQuery, GetDailyArrivalsQueryVariables>;
export const GetCremationsByCellOccupationDocument = gql`
    query getCremationsByCellOccupation($input: GetCremationsByCellOccupationInput!) {
  getCremationsByCellOccupation(input: $input) {
    firstName
    lastName
    enteredCell
    type
    estimatedDeliveryAt
    enteredCellAt
    exitedCellAt
    deliveryQrScannedAt
    unavailableCellAtReservation
  }
}
    `;

/**
 * __useGetCremationsByCellOccupationQuery__
 *
 * To run a query within a React component, call `useGetCremationsByCellOccupationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCremationsByCellOccupationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCremationsByCellOccupationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCremationsByCellOccupationQuery(baseOptions: Apollo.QueryHookOptions<GetCremationsByCellOccupationQuery, GetCremationsByCellOccupationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCremationsByCellOccupationQuery, GetCremationsByCellOccupationQueryVariables>(GetCremationsByCellOccupationDocument, options);
      }
export function useGetCremationsByCellOccupationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCremationsByCellOccupationQuery, GetCremationsByCellOccupationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCremationsByCellOccupationQuery, GetCremationsByCellOccupationQueryVariables>(GetCremationsByCellOccupationDocument, options);
        }
export type GetCremationsByCellOccupationQueryHookResult = ReturnType<typeof useGetCremationsByCellOccupationQuery>;
export type GetCremationsByCellOccupationLazyQueryHookResult = ReturnType<typeof useGetCremationsByCellOccupationLazyQuery>;
export type GetCremationsByCellOccupationQueryResult = Apollo.QueryResult<GetCremationsByCellOccupationQuery, GetCremationsByCellOccupationQueryVariables>;
export const GetCremationDocument = gql`
    query getCremation($id: Int!) {
  cremation(id: $id) {
    id
    alfId
    bellinzonaAssignment
    status
    estimatedDeliveryAt
    enteredCellAt
    enteredCell
    deliveryQrId
    deliveryQrScannedAt
    exitedCellAt
    startedAt
    completedAt
    collectedAt
    collectedBy
    collectionQrId
    collectionQrScannedAt
    deliveryEmployee
    firstName
    lastName
    type
    exhumationContainer
    birth
    birthYearOnly
    death
    deathYearOnly
    city
    zipCode
    medicalCertificateSigned
    medicalCertificateFile
    medicalCertificateDelivery
    countryCode
    createdAt
    packets {
      id
      total
      origin {
        id
        name
      }
      content {
        id
        name
      }
    }
    company {
      id
      name
    }
    createdByUser {
      firstName
      lastName
    }
    createdByApplication {
      name
    }
    updatedAt
    updatedByUser {
      firstName
      lastName
    }
    updatedByApplication {
      name
    }
  }
}
    `;

/**
 * __useGetCremationQuery__
 *
 * To run a query within a React component, call `useGetCremationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCremationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCremationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCremationQuery(baseOptions: Apollo.QueryHookOptions<GetCremationQuery, GetCremationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCremationQuery, GetCremationQueryVariables>(GetCremationDocument, options);
      }
export function useGetCremationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCremationQuery, GetCremationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCremationQuery, GetCremationQueryVariables>(GetCremationDocument, options);
        }
export type GetCremationQueryHookResult = ReturnType<typeof useGetCremationQuery>;
export type GetCremationLazyQueryHookResult = ReturnType<typeof useGetCremationLazyQuery>;
export type GetCremationQueryResult = Apollo.QueryResult<GetCremationQuery, GetCremationQueryVariables>;
export const CreateMedicalCremationDocument = gql`
    mutation createMedicalCremation($input: CreateMedicalCremationInput!) {
  createMedicalCremation(input: $input) {
    id
    type
    estimatedDeliveryAt
    unavailableCellAtReservation
    alfCompanyId
    packets {
      id
      total
      origin {
        id
        name
      }
      content {
        id
        name
      }
    }
  }
}
    `;
export type CreateMedicalCremationMutationFn = Apollo.MutationFunction<CreateMedicalCremationMutation, CreateMedicalCremationMutationVariables>;

/**
 * __useCreateMedicalCremationMutation__
 *
 * To run a mutation, you first call `useCreateMedicalCremationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMedicalCremationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMedicalCremationMutation, { data, loading, error }] = useCreateMedicalCremationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMedicalCremationMutation(baseOptions?: Apollo.MutationHookOptions<CreateMedicalCremationMutation, CreateMedicalCremationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMedicalCremationMutation, CreateMedicalCremationMutationVariables>(CreateMedicalCremationDocument, options);
      }
export type CreateMedicalCremationMutationHookResult = ReturnType<typeof useCreateMedicalCremationMutation>;
export type CreateMedicalCremationMutationResult = Apollo.MutationResult<CreateMedicalCremationMutation>;
export type CreateMedicalCremationMutationOptions = Apollo.BaseMutationOptions<CreateMedicalCremationMutation, CreateMedicalCremationMutationVariables>;
export const RemoveCremationDocument = gql`
    mutation removeCremation($id: Int!) {
  removeCremation(id: $id) {
    id
    firstName
    lastName
    type
    exhumationContainer
    birth
    birthYearOnly
    death
    deathYearOnly
    city
    zipCode
    countryCode
  }
}
    `;
export type RemoveCremationMutationFn = Apollo.MutationFunction<RemoveCremationMutation, RemoveCremationMutationVariables>;

/**
 * __useRemoveCremationMutation__
 *
 * To run a mutation, you first call `useRemoveCremationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCremationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCremationMutation, { data, loading, error }] = useRemoveCremationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCremationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCremationMutation, RemoveCremationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCremationMutation, RemoveCremationMutationVariables>(RemoveCremationDocument, options);
      }
export type RemoveCremationMutationHookResult = ReturnType<typeof useRemoveCremationMutation>;
export type RemoveCremationMutationResult = Apollo.MutationResult<RemoveCremationMutation>;
export type RemoveCremationMutationOptions = Apollo.BaseMutationOptions<RemoveCremationMutation, RemoveCremationMutationVariables>;
export const UpdateMedicalCremationDocument = gql`
    mutation updateMedicalCremation($id: Int!, $input: UpdateMedicalCremationInput!) {
  updateMedicalCremation(id: $id, input: $input) {
    id
    alfId
    status
    estimatedDeliveryAt
    deliveryQrId
    deliveryQrScannedAt
    enteredCellAt
    enteredCell
    exitedCellAt
    startedAt
    completedAt
    collectedAt
    collectedBy
    collectionQrId
    collectionQrScannedAt
    medicalCertificateSigned
    firstName
    lastName
    type
    exhumationContainer
    birth
    birthYearOnly
    death
    deathYearOnly
    city
    zipCode
    countryCode
    packets {
      id
      origin {
        id
        name
      }
      content {
        id
        name
      }
    }
  }
}
    `;
export type UpdateMedicalCremationMutationFn = Apollo.MutationFunction<UpdateMedicalCremationMutation, UpdateMedicalCremationMutationVariables>;

/**
 * __useUpdateMedicalCremationMutation__
 *
 * To run a mutation, you first call `useUpdateMedicalCremationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMedicalCremationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMedicalCremationMutation, { data, loading, error }] = useUpdateMedicalCremationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMedicalCremationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMedicalCremationMutation, UpdateMedicalCremationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMedicalCremationMutation, UpdateMedicalCremationMutationVariables>(UpdateMedicalCremationDocument, options);
      }
export type UpdateMedicalCremationMutationHookResult = ReturnType<typeof useUpdateMedicalCremationMutation>;
export type UpdateMedicalCremationMutationResult = Apollo.MutationResult<UpdateMedicalCremationMutation>;
export type UpdateMedicalCremationMutationOptions = Apollo.BaseMutationOptions<UpdateMedicalCremationMutation, UpdateMedicalCremationMutationVariables>;
export const UpdateCremationStatusDocument = gql`
    mutation updateCremationStatus($id: Int!, $input: UpdateCremationStatusInput!) {
  updateCremationStatus(id: $id, input: $input) {
    id
    status
    estimatedDeliveryAt
    enteredCellAt
    enteredCell
    exitedCellAt
    startedAt
    completedAt
    collectedAt
    collectedBy
    medicalCertificateSigned
    firstName
    lastName
    type
    exhumationContainer
    birth
    birthYearOnly
    death
    deathYearOnly
    city
    zipCode
    countryCode
    packets {
      id
      origin {
        id
        name
      }
      content {
        id
        name
      }
    }
  }
}
    `;
export type UpdateCremationStatusMutationFn = Apollo.MutationFunction<UpdateCremationStatusMutation, UpdateCremationStatusMutationVariables>;

/**
 * __useUpdateCremationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCremationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCremationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCremationStatusMutation, { data, loading, error }] = useUpdateCremationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCremationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCremationStatusMutation, UpdateCremationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCremationStatusMutation, UpdateCremationStatusMutationVariables>(UpdateCremationStatusDocument, options);
      }
export type UpdateCremationStatusMutationHookResult = ReturnType<typeof useUpdateCremationStatusMutation>;
export type UpdateCremationStatusMutationResult = Apollo.MutationResult<UpdateCremationStatusMutation>;
export type UpdateCremationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateCremationStatusMutation, UpdateCremationStatusMutationVariables>;
export const UpdateCremationDocument = gql`
    mutation updateCremation($id: Int!, $input: UpdateCremationInput!) {
  updateCremation(id: $id, input: $input) {
    id
    alfId
    status
    estimatedDeliveryAt
    deliveryQrId
    deliveryQrScannedAt
    enteredCellAt
    enteredCell
    exitedCellAt
    startedAt
    completedAt
    collectedAt
    collectedBy
    collectionQrId
    collectionQrScannedAt
    medicalCertificateSigned
    firstName
    lastName
    type
    exhumationContainer
    birth
    birthYearOnly
    death
    deathYearOnly
    city
    zipCode
    countryCode
  }
}
    `;
export type UpdateCremationMutationFn = Apollo.MutationFunction<UpdateCremationMutation, UpdateCremationMutationVariables>;

/**
 * __useUpdateCremationMutation__
 *
 * To run a mutation, you first call `useUpdateCremationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCremationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCremationMutation, { data, loading, error }] = useUpdateCremationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCremationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCremationMutation, UpdateCremationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCremationMutation, UpdateCremationMutationVariables>(UpdateCremationDocument, options);
      }
export type UpdateCremationMutationHookResult = ReturnType<typeof useUpdateCremationMutation>;
export type UpdateCremationMutationResult = Apollo.MutationResult<UpdateCremationMutation>;
export type UpdateCremationMutationOptions = Apollo.BaseMutationOptions<UpdateCremationMutation, UpdateCremationMutationVariables>;
export const ListCremationsDocument = gql`
    query listCremations($input: ListCremationsInput!) {
  cremations(input: $input) {
    meta {
      totalItems
      itemCount
      itemsPerPage
      totalPages
      currentPage
    }
    items {
      id
      alfId
      bellinzonaAssignment
      status
      createdAt
      estimatedDeliveryAt
      deliveryQrId
      deliveryQrScannedAt
      enteredCellAt
      enteredCell
      unavailableCellAtReservation
      exitedCellAt
      startedAt
      completedAt
      collectedAt
      collectionQrId
      collectionQrScannedAt
      medicalCertificateSigned
      firstName
      lastName
      type
      exhumationContainer
      birth
      birthYearOnly
      death
      deathYearOnly
      city
      zipCode
      countryCode
      packets {
        id
        total
      }
      company {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useListCremationsQuery__
 *
 * To run a query within a React component, call `useListCremationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCremationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCremationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListCremationsQuery(baseOptions: Apollo.QueryHookOptions<ListCremationsQuery, ListCremationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCremationsQuery, ListCremationsQueryVariables>(ListCremationsDocument, options);
      }
export function useListCremationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCremationsQuery, ListCremationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCremationsQuery, ListCremationsQueryVariables>(ListCremationsDocument, options);
        }
export type ListCremationsQueryHookResult = ReturnType<typeof useListCremationsQuery>;
export type ListCremationsLazyQueryHookResult = ReturnType<typeof useListCremationsLazyQuery>;
export type ListCremationsQueryResult = Apollo.QueryResult<ListCremationsQuery, ListCremationsQueryVariables>;
export const UpdateUserPersonalPasswordDocument = gql`
    mutation updateUserPersonalPassword($input: updateUserPasswordPersonalInput!) {
  updateUserPersonalPassword(input: $input) {
    operation
    success
  }
}
    `;
export type UpdateUserPersonalPasswordMutationFn = Apollo.MutationFunction<UpdateUserPersonalPasswordMutation, UpdateUserPersonalPasswordMutationVariables>;

/**
 * __useUpdateUserPersonalPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPersonalPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPersonalPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPersonalPasswordMutation, { data, loading, error }] = useUpdateUserPersonalPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPersonalPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPersonalPasswordMutation, UpdateUserPersonalPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPersonalPasswordMutation, UpdateUserPersonalPasswordMutationVariables>(UpdateUserPersonalPasswordDocument, options);
      }
export type UpdateUserPersonalPasswordMutationHookResult = ReturnType<typeof useUpdateUserPersonalPasswordMutation>;
export type UpdateUserPersonalPasswordMutationResult = Apollo.MutationResult<UpdateUserPersonalPasswordMutation>;
export type UpdateUserPersonalPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateUserPersonalPasswordMutation, UpdateUserPersonalPasswordMutationVariables>;
export const CreateRoleDocument = gql`
    mutation createRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    id
    title
    value
    description
  }
}
    `;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const GetRoleDocument = gql`
    query getRole($id: Int!) {
  role(id: $id) {
    id
    title
    value
    description
    createdAt
    createdByUser {
      firstName
      lastName
    }
    createdByApplication {
      name
    }
    updatedAt
    updatedByUser {
      firstName
      lastName
    }
    updatedByApplication {
      name
    }
  }
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
      }
export function useGetRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const RemoveRoleDocument = gql`
    mutation removeRole($id: Int!) {
  removeRole(id: $id) {
    id
    title
    value
    description
  }
}
    `;
export type RemoveRoleMutationFn = Apollo.MutationFunction<RemoveRoleMutation, RemoveRoleMutationVariables>;

/**
 * __useRemoveRoleMutation__
 *
 * To run a mutation, you first call `useRemoveRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRoleMutation, { data, loading, error }] = useRemoveRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRoleMutation, RemoveRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRoleMutation, RemoveRoleMutationVariables>(RemoveRoleDocument, options);
      }
export type RemoveRoleMutationHookResult = ReturnType<typeof useRemoveRoleMutation>;
export type RemoveRoleMutationResult = Apollo.MutationResult<RemoveRoleMutation>;
export type RemoveRoleMutationOptions = Apollo.BaseMutationOptions<RemoveRoleMutation, RemoveRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation updateRole($id: Int!, $input: UpdateRoleInput!) {
  updateRole(id: $id, input: $input) {
    id
    title
    value
    description
  }
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const ListRolesDocument = gql`
    query listRoles($input: ListRolesInput!) {
  roles(input: $input) {
    meta {
      totalItems
      itemCount
      itemsPerPage
      totalPages
      currentPage
    }
    items {
      id
      title
      value
      description
    }
  }
}
    `;

/**
 * __useListRolesQuery__
 *
 * To run a query within a React component, call `useListRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRolesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListRolesQuery(baseOptions: Apollo.QueryHookOptions<ListRolesQuery, ListRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListRolesQuery, ListRolesQueryVariables>(ListRolesDocument, options);
      }
export function useListRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRolesQuery, ListRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListRolesQuery, ListRolesQueryVariables>(ListRolesDocument, options);
        }
export type ListRolesQueryHookResult = ReturnType<typeof useListRolesQuery>;
export type ListRolesLazyQueryHookResult = ReturnType<typeof useListRolesLazyQuery>;
export type ListRolesQueryResult = Apollo.QueryResult<ListRolesQuery, ListRolesQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    firstName
    lastName
    email
    username
    enabled
    roles {
      id
      title
      description
      value
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const GetUserDocument = gql`
    query getUser($id: Int!) {
  user(id: $id) {
    id
    firstName
    lastName
    email
    username
    enabled
    createdAt
    createdByUser {
      firstName
      lastName
    }
    createdByApplication {
      name
    }
    updatedAt
    updatedByUser {
      firstName
      lastName
    }
    updatedByApplication {
      name
    }
    roles {
      id
      title
      description
      value
    }
    companies
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const RemoveUserDocument = gql`
    mutation removeUser($id: Int!) {
  removeUser(id: $id) {
    id
    username
    firstName
    lastName
    email
    enabled
    roles {
      id
      title
      description
    }
  }
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: Int!, $input: UpdateUserInput!) {
  updateUser(id: $id, input: $input) {
    id
    firstName
    lastName
    email
    username
    enabled
    roles {
      id
      title
      description
      value
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const ListUsersDocument = gql`
    query listUsers($input: ListUsersInput!) {
  users(input: $input) {
    meta {
      totalItems
      itemCount
      itemsPerPage
      totalPages
      currentPage
    }
    items {
      id
      username
      firstName
      lastName
      email
      enabled
      companiesParsed {
        id
        name
      }
      roles {
        id
        title
        description
      }
    }
  }
}
    `;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;