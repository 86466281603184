import { Card, CardContent, Grid } from "@mui/material"
import { FC, Fragment, useContext, useEffect, useRef, useState } from "react"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { NukFloatButton } from '../../buttons/nuk-float-button';
import { NukButton } from "../../buttons/nuk-button";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PageviewIcon from '@mui/icons-material/Pageview';
import { toast } from "react-toastify";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { NukUppyDialog } from "../nuk-uppy-dialog/NukUppyDialog";
import { DashboardModal,useUppy } from '@uppy/react'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { useTranslation } from 'react-i18next';
import nextId  from 'react-id-generator';
 type Props = {
    resourceId: number,
    endpoints: NuKFileManagerEndpointsT,
    files: NukFileManagerFileT[]
    uploadEnabled?: boolean,
    onUploadCompleted?: Function,
    onFileRemoved?: Function
 }

 export type NukFileManagerFileT = {
    label: string,
    name: string,
    onRemove?: Function 
 }

 export type NuKFileManagerEndpointsT ={
    stream: string,
    upload: string,
    remove: string,
 }



 export const NukFileManager : FC<Props> = ({
    resourceId,
    endpoints,
    files,
    uploadEnabled = true,
    onUploadCompleted = () => {},
    onFileRemoved = () => {},
 }) => {


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)


 const streamFile = () => {
    fetch(endpoints.stream.replace(':id',resourceId.toString()),{credentials:'include'})
    .then(response => response.blob())
    .then(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url);
    })
 }

 const removeFile = () => {
    fetch(endpoints.remove.replace(':id',resourceId.toString()),{method:'POST',credentials:'include'})
    .then(response => response.json())
    .then(data => {
        toast.success("File eliminato")
        onFileRemoved()
        setPondFiles([])
    })
 }

 const [uploadModalOpen,setUploadModalOpen] = useState<boolean>(false)

 const [pondFiles,setPondFiles] = useState<any[]>([])

 const {t} = useTranslation()
 return(
    <Fragment>

         <Grid container>
            <Grid item md={12}>
                {uploadEnabled &&
        <FilePond
        files={pondFiles}
        onupdatefiles={setPondFiles}
        allowMultiple={false}
        maxFiles={1}
        instantUpload={true}
        credits={false}
        server={{    
                    process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {

                        const formData = new FormData();
                        formData.append('file', file, file.name);
            
                        const request = new XMLHttpRequest();
                        request.open('POST', endpoints.upload.replace(':id',resourceId.toString()));
                        request.withCredentials = true;

                        // Should call the progress method to update the progress to 100% before calling load
                        // Setting computable to false switches the loading indicator to infinite mode
                        request.upload.onprogress = (e) => {
                            progress(e.lengthComputable, e.loaded, e.total);
                        };
            
                        // Should call the load method when done and pass the returned server file id
                        // this server file id is then used later on when reverting or restoring a file
                        // so your server knows which file to return without exposing that info to the client
                        request.onload = function () {
                            if (request.status >= 200 && request.status < 300) {
                                // the load method accepts either a string (id) or an object
                                load(request.responseText);
                                onUploadCompleted()
                            } else {
                                // Can call the error method if something is wrong, should exit after
                                error('oh no');
                            }
                        };

                        
            
                        request.send(formData);
            
                        // Should expose an abort method so the request can be cancelled
                        return {
                            abort: () => {
                                // This function is entered if the user has tapped the cancel button
                                request.abort();
            
                                // Let FilePond know the request has been cancelled
                                abort();
                            },
                        };

                    
                    
                    },
        }}
        name="files" 
        labelIdle='Trascina qui il file o <span class="filepond--label-action">Clicca per cercare nel tuo computer</span>'
      />
}
            </Grid>

      
         </Grid>
        {files.map((file: NukFileManagerFileT)=><Card>
            <CardContent key={nextId()}>
                <Grid container>
                    <Grid item md={8} sx={{display:'flex',alignItems:'center'}}>
                     <PictureAsPdfIcon sx={{mr:2}}/>
                        {file.label}
              
                    </Grid>
                    <Grid item md={4} sx={{display:'flex',justifyContent:'flex-end'}}>
                        <NukButton label={t('view',{ns:'general'})} icon={<PageviewIcon/>} onClick={() => streamFile()} sx={{mr:2}}/>
                        <NukButton label={t('remove',{ns:'general'})} icon={<DeleteForeverIcon/>} onClick={() => {
                            removeFile()}
                        } color="error"/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>)}
    </Fragment>
 )
}