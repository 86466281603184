
import { Grid, Skeleton } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import * as React from 'react';
import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { GraphqlFormError } from '../graphl-form-error';
import { NukFormContext } from '../nuk-form-context';
import { NukFormError } from '../nuk-form-error';
import { NukFormCheckboxCard } from './nuk-form-checkbox-card';

type Props = {
  name: string,
  rules?: any
  items: Items[]
  checkedIcon?: JSX.Element,
  uncheckedIcon?: JSX.Element,
  color?: string
}
type Items = {
  label: string,
  description?: string,
  value: string,
  checked?: boolean
}
export const NukFormCheckboxCards: FC<Props> = ({ 
  name,
  rules, 
  items, 
  checkedIcon,
  uncheckedIcon,
  color 
}
  
  ) => {
  const { control,formState: { errors }} = useFormContext()
  const { loading,gqlErrors } = React.useContext(NukFormContext)
  //Default state values are set by filtering the items which have a "checked" prop set as true.
  const [values, setValues] = React.useState<string[]>(
    items.filter(
      (item) => (item.checked) ? item.checked : false).map(
      (item) => item.value ));

  /**
   * Value change handler
   * @param value 
   * @param checked 
   * @param callback 
   */
  const handleChange = (value: string | number, checked: boolean, callback: Function) => {
    let updatedValue = values
    if (checked) {
      updatedValue.push(value.toString())
    } else {
      updatedValue.splice(updatedValue.indexOf(value.toString()), 1)
    }
    setValues(updatedValue);
    callback(values)
  };


  const component =     
  <Fragment>
  <Controller
    name={name}
    control={control}
    defaultValue={items.filter((item) => {
      return item.checked
    }).map((item) => {
      if (item.checked) {
        return item.value
      } else  return null
    })}
    rules={rules}
    render={({ field: { onChange, value } }) => (
      <FormControl style={{ width: '100%' }}>
        <Grid container spacing={2}>

          {items.map((item) => (
            <Grid item lg={3} md={6} sm={12} xs={12} key={item.value}>
              <NukFormCheckboxCard
                value={item.value}
                label={item.label}
                description={item.description}
                checkedIcon={checkedIcon}
                uncheckedIcon={uncheckedIcon}
                color={color}
                isCheckedDefault={(item.checked) ? item.checked : false}
                changeHandler={(value: string, checked: boolean) => {
                  handleChange(value, checked, onChange)
                }}
              />
            </Grid>
          ))}
        </Grid>
      </FormControl>
 
    )}
  />
        <NukFormError field={name} errors={errors} />
        <GraphqlFormError errors={gqlErrors} filters={[name]}/>
</Fragment>

const skeleton = <Skeleton animation="wave" height={200}/>


  return ((!loading) ? component : skeleton);
}



