import { Avatar, Tooltip } from "@mui/material";
import React, { FC } from "react";
import { NukStringColorizer } from '../../utils/nuk-string-colorizer.class';

type Props = {
    firstName: string,
    lastName: string,
}

export const NukUserAvatarColored: FC<Props> = ({
    firstName,
    lastName
}) => {
    if(firstName && lastName){
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
   return(
       <Tooltip title={`${firstName} ${lastName}`}>
        <Avatar sx={{ bgcolor: NukStringColorizer.getColor(`${firstName}${lastName}`) }}>
                {initials}
        </Avatar>
       </Tooltip>
   )
    } else return (null)

} 