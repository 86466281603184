import { capitalize, Grid, TextField, useMediaQuery, useTheme } from "@mui/material";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NukResourceListContext } from '../../../../../core/components/lists/nuk-list-context';

/**
 * Roles List Filters
 * @returns 
 */
export const RolesListFilters: FC = () => {
  //Resource List Context variables
  const { loading, page, pageSize, refetch, setFiltersLoading, setFiltersCount, clearFilters, setClearFilters } = useContext(NukResourceListContext)

  //Theme hooks
  const theme = useTheme()
  const xsBreakPoint = useMediaQuery(theme.breakpoints.down('sm'))

  //Filter States
  const [filterQ, setFilterQ] = useState<string>('')

  //Translation
  const { t } = useTranslation()

  /**
   * Users list filter counter function
   * Counts the active filters and displays the number on a badge 
   * (xsBreakPoint only)
   */
  const filterCounter = () => {
    let partialCount = 0
    if (filterQ && filterQ.length) partialCount++
    if (setFiltersCount) setFiltersCount(partialCount)

  }

  /**
   * Clear Filters 
   * Resets the filter to the given default values
   */
  const filterClear = () => {
    setFilterQ('')
  }

  /**
   * User Filter Effect Hook
   * Triggered everytime a filter changes with a 500ms delay to avoid to many requests.
   */
  useEffect(() => {
    filterCounter()
    if (!loading) {
      if (setFiltersLoading) setFiltersLoading(true)
      const timeOutId = setTimeout(() => {
        if (setFiltersLoading) setFiltersLoading(false)
        refetch({
          input: {
            pagination: { page: page, limit: pageSize },
            filters: {
              q: filterQ,
            },
          },
        })

      }, 500);
      return () => clearTimeout(timeOutId);
    }

  },
    //Filters to be watched by the hook
    [filterQ])


  /**
   * Clear Filters Hook
   */
  useEffect(() => {
    if (clearFilters) {
      filterClear()
      if (setClearFilters) setClearFilters(false)
    }
  }, [clearFilters])


  return (
    <Fragment>

      <Grid container spacing={1} rowGap={(xsBreakPoint) ? 2 : 0}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            label={`${capitalize(t('search', { ns: 'general' }))}...`}
            value={filterQ}
            onChange={e => setFilterQ(e.target.value)}
          />

        </Grid>
      </Grid>

    </Fragment>

  )
}