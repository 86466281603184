import { useEffect } from 'react';
import { toast } from "react-toastify";
import { INukFormHookEffectParams } from "../../../../../../core/nuk-core";
import { CreateCremationMutation, useCreateCremationMutation } from '../../../../../../graphql/nuk-graphql-main';
import { cremationsResource as resourceConfig } from '../../../../cremations.resource';
import { cremationEditorFormCreateSchemaType } from "../cremation-editor-form.schema";

/**
 * Cremation Create - Hook Wrapper
 * @param params 
 * @returns 
 */
export  const useCremationFormEditorCreate = () =>{
    return useCreateCremationMutation();
}

/**
 * Cremation Create - Executes the create function
 * @param service 
 * @param values 
 */
export const executeCremationFormEditorCreate = (service: Function,values: cremationEditorFormCreateSchemaType) => {
    //Additional validation can be done here.
    //Launching service

    //TODO: IF year only reset dates to first of february
    
    service({
        variables: {
            input: {
                firstName: values.firstName,
                lastName: values.lastName,
                type: values.type,
                exhumationContainer: values.exhumationContainer,
                birth: values.birth,
                birthYearOnly: (values.birthYearOnly === 'true') ? true : false,
                death: values.death,
                deathYearOnly:  (values.deathYearOnly === 'true') ? true : false,
                countryCode: values.countryCode,
                zipCode: `${values.zipCode}`,
                city: values.city,
                customization: values.customization,
                customizationDetail: values.customizationDetail,
                invoiceType: values.invoiceType,
                 //TODO: Change
                 alfCompanyId: 1
            }
        }
    })
}

/**
 * Cremation Create - Post Effect
 * @param params 
 */
export  const useCremationFormEditorCreateEffect = (params: EffectParams) => {
    const { data,error,setGqlError,navigate } = params
    useEffect(() => {
        if(data){
            //Toasting success
            toast.success(`${resourceConfig.nameSingular} ${data.createCremation.firstName} ${data.createCremation.lastName} Created!`)
            //Navigating back to list
            navigate(resourceConfig.indexPath)
        } else if(error){
            //Setting Gql Errors
            setGqlError(error);
        }
    },[data,error])
}

//User Create - Interfaces
interface EffectParams extends INukFormHookEffectParams {data: CreateCremationMutation | null | undefined}
