import { Fab, FabProps, Skeleton } from "@mui/material";
import { FC } from "react";


interface Props extends FabProps {
    icon: JSX.Element,
    skeleton?: boolean,    
    onClick?: any
}


export const NukFloatButton : FC<Props> = ({
    icon,
    skeleton = false,
    onClick,
    size ="medium",
    color = "primary",
    sx,
    type,
    href,
}) =>{
    return(
        !skeleton ? 
        <Fab size={size} color={color} onClick={onClick} sx={sx} type={type} href={href}>
            {icon}
        </Fab>
        :
        <Skeleton variant="circular" sx={sx}>
            <Fab size={size}></Fab>
        </Skeleton>
    )
}