

import { Box, Button, capitalize, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { FC, Fragment } from 'react';
import { NukStructure } from "../../../core/nuk-components";
  import { useAppSelector } from '../../../features/hooks';
import { useTranslation } from 'react-i18next';
import { DashboardDailyTimeline } from '../timelines/dashboard-daily-events.timeline';

import { DashboardCellOccupation } from '../components/cells/dashboard-cell-occupation';
import { Typography } from '@mui/material';
import { CremationStatusCountCard } from '../../../resources/cremations/components/cards/cremation-status-count.card';
import { CremationStatus } from '../../../resources/cremations/constants/cremation-status.enum';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import { userHasRole } from '../../auth/utils/auth-user-role-checker';
import { DailyArrivals } from '../daily-arrivals';


/**
 * Dashboard Page
 * @param props 
 * @returns 
 */
export const DashboardPage: FC = (props) => {
  const authUser = useAppSelector((state) => state.user.value)
  const { t,i18n } = useTranslation()


  return (
    <NukStructure>
      <Box sx={{ p: 2 }}>
   
      <Grid container columnSpacing={2} rowSpacing={2}>

          <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                  <DashboardDailyTimeline/>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={12}>
            <Card>
              <CardContent sx={{pt:0}}>
                  <DailyArrivals/>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/*
        {userHasRole(authUser,['MASTER','MODERATOR']) &&
        <Grid container sx={{mt:2}} columnSpacing={2}>

          
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Occupazione celle">
              </CardHeader>
              <CardContent>
                <DashboardCellOccupation/>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
          
        }
      */}
      </Box>
    </NukStructure>
  )
}