import { useEffect } from "react";
import { INukFormHookEffectParams, INukFormHookGetParams } from "../../../../../../core/nuk-core";
import { NukTimeUtils } from "../../../../../../core/utils/nuk-time-utils";
import { GetCremationQuery, GetCremationQueryResult, useGetCremationQuery } from "../../../../../../graphql/nuk-graphql-main";

/**
 * Cremation Get - Fetch Hook
 * @param INukFormHookGetParams 
 * @returns GetUserQueryResult
 */
export  const useCremationFormEditorGet = (params: INukFormHookGetParams) : GetCremationQueryResult => {
    const { id } = params
    return useGetCremationQuery({
        variables:{id:id},
        skip:(isNaN(id) || !id)
    });

}

/**
 * Cremation Get - Post Effect
 * @param EffectParams 
 */
export const useCremationFormEditorGetEffect = (params: EffectParams) => {
    const { data,setValue } = params
    useEffect(() => {
        if(data){
        //Compiling form values
        setValue('alfCompanyId',data.cremation.company?.id)
        setValue('status',data.cremation.status)
        //Dates
        setValue('bellinzonaAssignment',data.cremation.bellinzonaAssignment)
        
        setValue('estimatedDeliveryAtDate',data.cremation.estimatedDeliveryAt)
        setValue('estimatedDeliveryAtTime',NukTimeUtils.extractTimeValuesForSelect(data.cremation.estimatedDeliveryAt))

        setValue('enteredCellAtDate',data.cremation.enteredCellAt)
        setValue('enteredCellAtTime',NukTimeUtils.extractTimeValuesForSelect(data.cremation.enteredCellAt))
        setValue('enteredCell',data.cremation.enteredCell)

        setValue('exitedCellAtDate',data.cremation.exitedCellAt)
        setValue('exitedCellAtTime',NukTimeUtils.extractTimeValuesForSelect(data.cremation.exitedCellAt))
        
        
        setValue('startedAtDate',data.cremation.startedAt)
        setValue('startedAtTime',NukTimeUtils.extractTimeValuesForSelect(data.cremation.startedAt))

        setValue('completedAtDate',data.cremation.completedAt)
        setValue('completedAtTime',NukTimeUtils.extractTimeValuesForSelect(data.cremation.completedAt))

        setValue('collectedAtDate',data.cremation.collectedAt)
        setValue('collectedAtTime',NukTimeUtils.extractTimeValuesForSelect(data.cremation.collectedAt))
        setValue('collectedBy',data.cremation.collectedBy)

        setValue('deliveryEmployee',data.cremation.deliveryEmployee)

        setValue('firstName', data.cremation.firstName)
        setValue('lastName', data.cremation.lastName)
        setValue('birth',data.cremation.birth)
        setValue('birthYearOnly',(data.cremation.birthYearOnly) ? 'true' : 'false')
        setValue('death',data.cremation.death)
        setValue('deathYearOnly',(data.cremation.deathYearOnly) ? 'true' : 'false')
        setValue('medicalCertificateSigned',data.cremation.medicalCertificateSigned)
        setValue('medicalCertificateDelivery',data.cremation.medicalCertificateDelivery)
        setValue('type',data.cremation.type)
        setValue('exhumationContainer',data.cremation.exhumationContainer)
        setValue('countryCode',data.cremation.countryCode)
        setValue('zipCode',data.cremation.zipCode)
        setValue('city',data.cremation.city)

        }
    },[data])
}

//User Get - Interfaces
interface EffectParams extends INukFormHookEffectParams {data: GetCremationQuery | null | undefined}
