import { capitalize as capitalizeFunc, Typography } from "@mui/material"
import { FC, Fragment } from "react"
import { NukLabel } from "./nuk-label"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
type Props = {
    label: string,
    value: boolean,
}

export const NukEnabledLabel : FC<Props> = ({
    label,
    value = false,
}) =>{
    return(
        <Fragment>
            <NukLabel label={label}/>
            <Typography variant="h5" >
                {value ? <CheckIcon/> : <CloseIcon />}
            </Typography>
        </Fragment>
    )
}