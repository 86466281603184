import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react'
import { persistor } from './store';
import { store } from './store'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
  HttpLink
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import "@fontsource/inter";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { toast } from 'react-toastify';
import { client as apolloClient} from './core/config/apollo/apollo-client';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import 'moment/locale/it';
//MUI X License Key
LicenseInfo.setLicenseKey(
  '719cd3b06cbf18f63396a7959cf8812eT1JERVI6NDEzNDYsRVhQSVJZPTE2ODA5MzQ3MjAwMDAsS0VZVkVSU0lPTj0x',
);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
