import { capitalize, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { FC } from "react"
import { Link, useLocation } from "react-router-dom"
import { useAppDispatch } from "../../../features/hooks"
import { toggleDrawer } from "../../../features/layout"
import { MuiColors } from "../../mui/nuk-mui-default-colors.type"

type Props = {
    label: string,
    to: string,
    icon: JSX.Element | null,
    color?: string | MuiColors 
    closeDrawerOnClick?: boolean
}

/**
 * Drawer menu item
 * @param param0 
 * @returns 
 */
export const NukDrawerMenuItem: FC<Props> = ({
    label,
    to,
    icon,
    color,
    closeDrawerOnClick = false,
}) => {
    const route = useLocation() 
    const dispatch = useAppDispatch()


    return (
        <ListItemButton onClick={()=>{closeDrawerOnClick && dispatch(toggleDrawer())}} component={Link} to={to} sx={{backgroundColor:(to.includes(route.pathname)) ? 'primary.main' : null}}>
            <ListItemIcon sx={{'& .MuiSvgIcon-root':{color:(color) ? `${color}.main` : 'primary.contrastText'}}}>
                {icon}
            </ListItemIcon>
            <ListItemText primary={capitalize(label)} sx={{'& .MuiTypography-root':{color: (color) ? `${color}.main` : 'primary.contrastText'}}}/>
        </ListItemButton>
    )
}