import { Box, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { NukPrintContainer } from '../../../core/nuk-components';
import { GetCremationQuery } from '../../../graphql/nuk-graphql-main';
import { DefaultPrintHeader } from '../../../modules/prints/components/default-print-header';
import { format } from 'date-fns'

type Props = {
  children?: React.ReactNode,
  data: GetCremationQuery
}

export const CremationCertificatePrint = React.forwardRef<any,Props>((props, ref) => {
  const { cremation } = props.data
  return (
    <Box ref={ref}>
      <NukPrintContainer paper="A4">
      <DefaultPrintHeader/>
      <Box sx={{mt:'100px',display:'flex',flexDirection:'column',alignItems:'center'}}>
      <Typography variant="h6">
          Certificato di cremazione Nr. <span style={{color:'blue'}}>{String(cremation.alfId).padStart(5,'0')}</span>
        </Typography>
      </Box>

      <Box sx={{mt:'20px',display:'flex',flexDirection:'column',alignItems:'center'}}>
      <Typography variant="body1" sx={{textAlign:'center'}}>
            Con la presente si dichiara che in data <b>{format(new Date(cremation.completedAt),'dd.MM.yyyy')}</b> abbiamo provveduto alla cremazione della salma del/della defunta/o: 
        </Typography>

      </Box>

      <Box sx={{mt:'20px',display:'flex',flexDirection:'column',alignItems:'center'}}>
      <Typography variant="h3" sx={{textAlign:'center'}}>
          {cremation.firstName?.toUpperCase()}  {cremation.lastName?.toUpperCase()}
        </Typography>

      </Box>
    

      <Box sx={{display:'flex',width:'540px',margin:'auto',mt:'30px'}}>
          <Box>
          <Typography variant="body1" >
            {cremation.birthYearOnly ? 
            <Fragment>
              Nato/a nel: <b>{format(new Date(cremation.birth),'yyyy')}</b>
            </Fragment> : 
            <Fragment>
              Nato/a il: <b>{format(new Date(cremation.birth),'dd.MM.yyyy')}</b>
            </Fragment>
            }
        </Typography>
          </Box>
          <Box sx={{marginLeft:'auto'}}>
          <Typography variant="body1" >
          {cremation.deathYearOnly ? 
            <Fragment>
              Deceduto/a nel: <b>{format(new Date(cremation.death),'yyyy')} </b>
            </Fragment> : 
            <Fragment>
              Deceduta/a il: <b>{format(new Date(cremation.death),'dd.MM.yyyy')}</b>
            </Fragment>
            }
        </Typography>
          </Box>
      </Box>

      <Box sx={{mt:'40px',display:'flex',flexDirection:'column',alignItems:'center'}}>
          <Typography variant="body1" sx={{textAlign:'center'}}>
          In data <b>{format(new Date(cremation.collectedAt),'dd.MM.yyyy')}</b> le ceneri sono state ritirate dall'impresa di onoranze funebri:
        </Typography>

      </Box>


      <Box sx={{mt:'10px',display:'flex',flexDirection:'column',alignItems:'center'}}>
      <Typography variant="h6" sx={{textAlign:'center'}}>
        {(cremation.company) ? cremation.company.name : ""}
        </Typography>

      </Box>



      <Box sx={{ml:'15px',mt:'220px',display:'flex',flexDirection:'column'}}>
          <Typography variant="body1">
          In fede,
        </Typography>

      </Box>

      <Box sx={{mr:'15px',mt:'10px',display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
          <Typography variant="body1" fontWeight="bold">
          CREMATORIO TICINO SAGL
        </Typography>
        <Typography variant="body1" fontWeight="bold" color="blue">
          <img src='https://api.crematorioticino.ch/cremations/cremation-certificate/signature' style={{width:160,height:'auto'}}/>
        </Typography>
      </Box>
      </NukPrintContainer>
    </Box>
  );
});

