import { Box, capitalize, Checkbox, Chip, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Skeleton, TextField } from "@mui/material";
import { FC, Fragment, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { isTemplateSpan } from "typescript";
import { GraphqlFormError, hasGraphqlErrorSlim } from './graphl-form-error';
import { NukFormContext } from './nuk-form-context';
import { NukFormError } from "./nuk-form-error";
import nextId from 'react-id-generator';
import { GetAppRounded } from "@mui/icons-material";
type Props = {
    name: string
    id?: string
    label: string
    rules?: any
}

type NukFormSelectFieldItem = {
  label?: string,
  value: string,
  renderValue?: JSX.Element
}

/**
 * Mui Text Field Component wrapped in a React Hook Form Controller for maximum compatibility.
 * id: if not specified, is the same as name
 * @param props 
 * @returns 
 */
export const NukFormCheckboxField : FC<Props> = (
  { name,
    id,
    label,
    rules,

  }) => {
    if(id==null) id = name
    const { control,formState: { errors }} = useFormContext()
    const { loading,gqlErrors } = useContext(NukFormContext)
    const component =         
    <Fragment> 
      <Controller
              name={name}
              control={control}
              rules={rules}
              render={({ field: { onChange, value } }) => {
                return(
                  
                <FormControl fullWidth>
                    <FormControlLabel control={<Checkbox checked={!!value}/>} label={label} onChange={(e)=> onChange(e)} value={value} />
                </FormControl>
              )}}
      />
      <NukFormError field={name} errors={errors} />
      <GraphqlFormError errors={gqlErrors} filters={[name]}/>
    </Fragment>

    const skeleton = <Skeleton animation="wave" height={100}/>

    
    return ((!loading) ? component : skeleton)
}