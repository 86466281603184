import { useEffect } from 'react';
import { toast } from "react-toastify";
import { INukDialogFormHookEffectParams } from '../../../../../../core/hooks/nuk-form-hooks.interfaces';
import { INukFormHookEffectParams } from "../../../../../../core/nuk-core";
import { CreateCremationMutation, useCreateCremationMutation } from '../../../../../../graphql/nuk-graphql-main';
import { cremationsResource as resourceConfig } from '../../../../cremations.resource';
import { cremationRequestFormSchemaType } from '../cremation-request-form.schema';
import { NukTimeUtils } from '../../../../../../core/utils/nuk-time-utils';

/**
 * Cremation Create - Hook Wrapper
 * @param params 
 * @returns 
 */
export  const useCremationRequestFormCreate = () =>{
    return useCreateCremationMutation();
}

/**
 * Cremation Create - Executes the create function
 * @param service 
 * @param values 
 */
export const executeCremationRequestFormCreate = (service: Function,values: cremationRequestFormSchemaType) => {
    //Additional validation can be done here.
    //Launching service    
    service({
        variables: {
            input: {
                alfCompanyId: Number(values.alfCompanyId),
                bellinzonaAssignment: values.bellinzonaAssignment,
                firstName: values.firstName,
                lastName: values.lastName,
                birth: values.birth,
                birthYearOnly: (values.birthYearOnly === 'true') ? true : false,
                death: values.death,
                deathYearOnly:  (values.deathYearOnly === 'true') ? true : false,
                countryCode: values.countryCode,
                zipCode: values.zipCode,
                city: values.city,
                type: values.type,
                exhumationContainer: values.exhumationContainer,
                deliveryEmployee: values.deliveryEmployee,
                medicalCertificateDelivery: values.medicalCertificateDelivery,
                estimatedDeliveryAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.estimatedDeliveryAtDate,
                    time: values.estimatedDeliveryAtTime,
                }),
            }
        }
    })
}

/**
 * Cremation Create - Post Effect
 * @param params 
 */
export  const useCremationRequestFormCreateEffect = (params: EffectParams) => {
    const { data,error,setGqlError,refetch } = params
    useEffect(() => {
        if(data){
            //Refreshing List
            refetch()
        } else if(error){
            //Setting Gql Errors
            setGqlError(error);
        }
    },[data,error])
}

//User Create - Interfaces
interface EffectParams extends INukDialogFormHookEffectParams {data: CreateCremationMutation | null | undefined,refetch: Function}
