import { configureStore } from '@reduxjs/toolkit'
import userReducer  from './modules/auth/redux/auth-user.slice'
import layoutReducer from './features/layout'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig ={
    key:'main-root',
    storage,
}



const persistedUserReducer = persistReducer(persistConfig,userReducer)
const persistedLayoutReducer = persistReducer(persistConfig,layoutReducer)

export const store = configureStore({
    reducer: {
      user: persistedUserReducer,
      layout: persistedLayoutReducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  })

export const persistor = persistStore(store)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch