import { capitalize, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  errors?: any,
  filters: String[]
}

export const GraphqlFormError: FC<Props> = (props) => {
  const { errors,filters } = props
  const { t } = useTranslation()

  const message = matchError(errors,filters)
    if(message)
      return (
        <Typography color="error">
          {capitalize(t(message.replace(/ /g,"_").toUpperCase(),{ns:'errors'}))} 
        </Typography>
      )
    
  

  return (null)
}

export const matchError = (errors: any,filters: String[]) => {
  let message = null
  if(typeof errors !=='undefined' && errors!=null && typeof errors.graphQLErrors!=='undefined' && errors.graphQLErrors.length>0){
    //e.message is first source
    //e.extensions.response.message
    for(let e of errors.graphQLErrors){
      //First control on e.message
      for(const f of filters){
        if(e.message===f) message = f
        if(message) break
      }

      if(message==null && typeof e.extensions.response!=='undefined'){
        for(const f of filters){
          for(const msg of e.extensions.response.message){
              if(msg.includes(f)) message = msg
              if(message) break
          }
        }
      }

      if(message==null && typeof e.extensions.fields!=='undefined'){
        for(const f of filters){
          for(const field of e.extensions.fields){
              if(field.field===f){
                if(field.type)
                  message = field.type
                else if(field.error){
                  message = field.error
                }
              }
              if(message) break
          }
        }
      }
      

    }
  }
  return message
}

/**
 * Shorthand functions that verifies if the given field holds an error
 * @param error 
 * @returns 
 */
 export const hasGraphQLError = (errors: any, filters: String[]): boolean => {
  return (matchError(errors,filters)!=null)
};

export const hasGraphqlErrorSlim = (errors: any, filters: string[]) : boolean => {
  const errorsToString =JSON.stringify(errors)
  let found = false
  for(const f of filters){
    if(errorsToString.includes(f)) found = true
  }
  return found 
}