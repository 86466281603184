import { useEffect } from 'react';
import { toast } from "react-toastify";
import { INukDialogFormHookEffectParams } from '../../../../../../core/hooks/nuk-form-hooks.interfaces';
import { INukFormHookEffectParams } from "../../../../../../core/nuk-core";
import { CreateCremationMutation, useCreateCremationMutation, useCreateMedicalCremationMutation,CreateMedicalCremationMutation } from '../../../../../../graphql/nuk-graphql-main';
import { cremationsResource as resourceConfig } from '../../../../cremations.resource';
import { cremationRequestFormMedicalSchemaType } from '../cremation-request-form-medical.schema';
import { NukTimeUtils } from '../../../../../../core/utils/nuk-time-utils';
import { Package } from '../cremation-request-form-medical.dialog';

/**
 * Cremation Create - Hook Wrapper
 * @param params 
 * @returns 
 */
export  const useCremationRequestFormMedicalCreate = () =>{
    return useCreateMedicalCremationMutation();
}

/**
 * Cremation Create - Executes the create function
 * @param service 
 * @param values 
 */
export const executeCremationRequestFormMedicalCreate = (service: Function,values: cremationRequestFormMedicalSchemaType,packages: Package[]) => {
    //Additional validation can be done here.
    //Launching service    
    service({
        variables: {
            input: {
                alfCompanyId: 20,
                estimatedDeliveryAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.estimatedDeliveryAtDate,
                    time: values.estimatedDeliveryAtTime,
                }),
                packets:packages
            }
        }
    })
}

/**
 * Cremation Create - Post Effect
 * @param params 
 */
export  const useCremationRequestFormMedicalCreateEffect = (params: EffectParams) => {
    const { data,error,setGqlError,refetch } = params
    useEffect(() => {
        if(data){
            //Refreshing List
            refetch()
        } else if(error){
            //Setting Gql Errors
            setGqlError(error);
        }
    },[data,error])
}

//User Create - Interfaces
interface EffectParams extends INukDialogFormHookEffectParams {data: CreateMedicalCremationMutation | null | undefined,refetch: Function}
