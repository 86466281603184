import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Step,
  StepButton,
  Stepper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { FC, Fragment, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NukResourceListContext } from "../../../../../core/components/lists/nuk-list-context";
import {
  NukResourceListDetailModal,
  NukTextViewLabel,
} from "../../../../../core/nuk-components";
import {
  useGetCremationLazyQuery,
  useUpdateCremationStatusMutation,
} from "../../../../../graphql/nuk-graphql-main";
import { CremationRemoveDialog } from "../../dialogs/cremation-remove.dialog";
import { CremationStatusStepper } from "../../steppers/cremation-status.stepper";
import { NukCountryCodeViewLabel } from "../../../../../core/components/labels/nuk-country-code-view-label";
import { CremationStatusIcon } from "../../icons/cremation-status.icon";
import { CremationStatus } from "../../../constants/cremation-status.enum";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import QRCode from "react-qr-code";
import { CremationEventsTimeline } from "../../timelines/cremation-events.timeline";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../features/hooks";
import { userHasRole } from "../../../../../modules/auth/utils/auth-user-role-checker";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useReactToPrint } from "react-to-print";
import { CremationCertificatePrint } from "../../../prints/cremation-certificate.print";
import { CremationCollectionStickerPrint } from "../../../prints/cremation-collection-sticker.print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { NukButton } from "../../../../../core/components/buttons/nuk-button";
import { CREMATION_MEDICAL_CERTIFICATE_STREAM } from "../../../service/cremations.storage.endpoints";
import { BillingModal } from "./modals/billing-modal";
type Props = {};

/**
 * Users List Detail Dialog
 * @param param0
 * @returns
 */
export const CremationListDetailDialog: FC<Props> = ({}) => {
  const { t } = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const { setDetailDialogOpen, refetch, trns } = useContext(
    NukResourceListContext
  );
  const [billingDialogOpen, setBillingDialogOpen] = useState<boolean>(false);
  //Fetch (Get) Detail query
  const [fetchResource, { data, loading }] = useGetCremationLazyQuery();
  const user = useAppSelector((state) => state.user.value);
  const [
    updateStatusMutation,
    {
      data: statusUpdateData,
      loading: statusUpdateLoading,
      error: statusUpdateError,
    },
  ] = useUpdateCremationStatusMutation();

  //Printing
  const certificatePrintComponentRef = useRef(null);
  const handleCertificatePrint = (resourceId: string) => {
    window.open(
      `${process.env.REACT_APP_API_ENDPOINT}/cremations/${resourceId}/cremation-certificate-document`
    );
  };
  const cremationStickerPrintComponentRef = useRef(null);
  const handleCremationStickerPrint = (resourceId: string) => {
    window.open(
      `${process.env.REACT_APP_API_ENDPOINT}/cremations/${resourceId}/ashes-collection-label`
    );
  };
  const handleCremationNewStickerPrint = (resourceId: string) => {
    window.open(
      `${process.env.REACT_APP_API_ENDPOINT}/cremations/${resourceId}/ashes-collection-label-updated`
    );
  };
  const handleDeadDeliveryDocumentPrint = (resourceId: string) => {
    window.open(
      `${process.env.REACT_APP_API_ENDPOINT}/cremations/${resourceId}/dead-delivery-document`
    );
  };

  /*useReactToPrint({
      content: () => cremationStickerPrintComponentRef.current,
    });*/

  const streamFile = (resourceId: string) => {
    fetch(CREMATION_MEDICAL_CERTIFICATE_STREAM.replace(":id", resourceId), {
      credentials: "include",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        window.open(url);
      });
  };

  const REGISTERED_quickActions = {};

  return (
    <NukResourceListDetailModal
      resource={data?.cremation}
      fetch={fetchResource}
      label={
        (data) ?
            (data.cremation.type==='MEDICAL_REMAINS') ? `RESIDUI MEDICI - ${data.cremation.alfId}` : `${data.cremation.firstName} ${data.cremation.lastName} - ${data.cremation.alfId}` 
        : ``
        }
      loading={loading}
      actions={[
        {
          label: t("remove", { ns: "general" }),
          icon: <DeleteIcon />,
          onClick: () => setDeleteDialogOpen(true),
        },
      ]}
      quickActionsDisabled={
        data &&
        (data.cremation.medicalCertificateSigned ||
          data.cremation.status === CremationStatus.REGISTERED)
          ? false
          : true
      }
      showEditButton={userHasRole(user, ["MODERATOR", "MASTER"])}
      showActions={userHasRole(user, ["MODERATOR", "MASTER"])}
      showQuickActions={
        data &&
        (data.cremation.status == CremationStatus.REGISTERED ||
          data.cremation.status == CremationStatus.COMPLETED ||
          data.cremation.status == CremationStatus.COLLECTED)
          ? true
          : userHasRole(user, ["MODERATOR", "MASTER"])
      }
      quickActionsDisabledMessage="Necessario CMT per procedere."
      quickActions={
        data && data.cremation.status === CremationStatus.REGISTERED
          ? [
              {
                label: "Stampa foglio di consegna",
                icon: <QrCode2Icon />,
                onClick: () => {
                  handleDeadDeliveryDocumentPrint(data.cremation.id.toString());
                },
              },
            ]
          : data && data.cremation.status === CremationStatus.DELIVERED
          ? [
              {
                buttonType: "loading",
                label: "Inizia cremazione",
                loadingText: "Aggiornamento...",
                loading: statusUpdateLoading,
                icon: <CremationStatusIcon status={CremationStatus.STARTED} />,
                onClick: () => {
                  updateStatusMutation({
                    variables: {
                      id: data.cremation.id,
                      input: {
                        status: CremationStatus.STARTED,
                      },
                    },
                  });
                },
              },
            ]
          : data && data.cremation.status === CremationStatus.STARTED
          ? [
              {
                buttonType: "loading",
                label: "Termina cremazione",
                loadingText: "Aggiornamento...",
                loading: statusUpdateLoading,
                icon: (
                  <CremationStatusIcon status={CremationStatus.COMPLETED} />
                ),
                onClick: () => {
                  updateStatusMutation({
                    variables: {
                      id: data.cremation.id,
                      input: {
                        status: CremationStatus.COMPLETED,
                      },
                    },
                  });
                },
              },
            ]
          : data &&
            data.cremation.status === CremationStatus.COMPLETED &&
            userHasRole(user, ["MODERATOR", "MASTER"])
          ? [
              {
                label: "Stampa etichetta per ceneri",
                icon: <QrCode2Icon />,
                onClick: () => {
                  handleCremationNewStickerPrint(data.cremation.id.toString());
                },
              },
            ]
          : data && data.cremation.status === CremationStatus.COLLECTED
          ? [
              {
                label: "Stampa certificato di cremazione",
                icon: <LocalPrintshopIcon />,
                onClick: () => {
                  handleCertificatePrint(data.cremation.id.toString());
                },
              },
            ]
          : []
      }
    >
      {data ? (
        <Fragment>
          <Box sx={{ display: "none" }}>
            <CremationCertificatePrint
              ref={certificatePrintComponentRef}
              data={data}
            />
            <CremationCollectionStickerPrint
              ref={cremationStickerPrintComponentRef}
            />
          </Box>
          <Grid container rowGap={2} columnSpacing={2}>
          {(data.cremation.createdByApplication && data.cremation.createdByApplication.name==='PGFD') && 

          <Grid item md={12}>
          <Tooltip title="Richiesta generata da PGFD">

                          <img
                        src={`/static/images/pgf_black.png`}
                        style={{ width: 40, height: 30, float: "right" }}
                      />
             </Tooltip>
      
                </Grid>
                       }
            {data.cremation.bellinzonaAssignment && (
              <Grid item md={12}>
                <Card>
                  <CardContent>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", padding: 0, alignItems: "center" }}
                    >
                      <img
                        src={`/static/images/bellinzona-crest-enabled.svg`}
                        style={{ width: 30, height: 30, float: "right" }}
                      />
                      <Typography
                        variant="h6"
                        component="span"
                        color="#9e9e9e"
                        sx={{ pl: 2 }}
                      >
                        INCARICO DA PARTE DELLA CITTÀ DI BELLINZONA
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid item md={12}>
              <Card>
                <CardContent>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    {/*
                                        {(data.cremation.status===CremationStatus.REGISTERED) &&
                                        <Grid item md={3}>
                                           <QRCode value={`tc-crematorium-${data.cremation.id}`}/>                                     
                                        </Grid>
                                        }*/}
                    {data.cremation.type === "MEDICAL_REMAINS" ? (
                      //MEDICAL
                      <Grid item md={12}>
                        <Grid container rowSpacing={2}>
                          <Grid item xs={12} md={4}>
                            <NukTextViewLabel
                              label="Azienda"
                              value={
                                data.cremation.company
                                  ? data.cremation.company.name
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      //DEFAULT ##################
                      <Grid item md={12}>
                        <Grid container rowSpacing={2}>
                          <Grid item xs={12} md={4}>
                            <NukTextViewLabel
                              label={t("lastName", { ns: "general" })}
                              fontSize={32}
                              fontWeight="bold"
                              textTransform="uppercase"
                              value={
                                data.cremation.lastName
                                  ? data.cremation.lastName
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <NukTextViewLabel
                              label={t("firstName", { ns: "general" })}
                              fontSize={32}
                              fontWeight="bold"
                              textTransform="uppercase"
                              value={
                                data.cremation.firstName
                                  ? data.cremation.firstName
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <NukTextViewLabel
                              label="Onoranza"
                              value={
                                data.cremation.company
                                  ? data.cremation.company.name
                                  : ""
                              }
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            {data.cremation.birth &&
                            data.cremation.birthYearOnly ? (
                              <NukTextViewLabel
                                label={t("birthYear", { ns: trns })}
                                value={moment(data.cremation.birth).format(
                                  "YYYY"
                                )}
                              />
                            ) : (
                              <NukTextViewLabel
                                label={t("birthDate", { ns: trns })}
                                value={moment(data.cremation.birth).format(
                                  "DD.MM.YYYY"
                                )}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} md={4}>
                            {data.cremation.death &&
                            data.cremation.deathYearOnly ? (
                              <NukTextViewLabel
                                label={t("deathYear", { ns: trns })}
                                value={moment(data.cremation.death).format(
                                  "YYYY"
                                )}
                              />
                            ) : (
                              <NukTextViewLabel
                                label={t("deathDate", { ns: trns })}
                                value={moment(data.cremation.death).format(
                                  "DD.MM.YYYY"
                                )}
                              />
                            )}
                          </Grid>

                          <Grid item xs={12} md={4}>
                            {data.cremation.countryCode && (
                              <NukCountryCodeViewLabel
                                label={t("country", { ns: "general" })}
                                value={data.cremation.countryCode}
                              />
                            )}
                          </Grid>
                          {data.cremation.zipCode && (
                            <Grid item xs={12} md={4}>
                              <NukTextViewLabel
                                label={t("zipCode", { ns: "general" })}
                                value={data.cremation.zipCode}
                              />
                            </Grid>
                          )}
                          {data.cremation.city && (
                            <Grid item xs={12} md={4}>
                              <NukTextViewLabel
                                label={t("city", { ns: "general" })}
                                value={data.cremation.city}
                              />
                            </Grid>
                          )}
                          {data.cremation.alfId && (
                            <Grid item xs={12} md={4}>
                              <NukTextViewLabel
                                label="N°"
                                value={String(data.cremation.alfId).padStart(
                                  5,
                                  "0"
                                )}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={12}>
              <Card>
                <CardContent>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={6} md={6}>
                      <Typography variant="h6">CONTENUTO</Typography>
                    </Grid>
                    {(data.cremation.packets) && data.cremation.packets.map((p)=>
                    <Grid item xs={12}>
                    <Card>
                        <CardContent>
                        <Grid container>
                            <Grid item xs={4}>
                                            <NukTextViewLabel
                                        label="Nr. Colli"
                                        value={p.total.toString()}
                                    />
                            </Grid>
                            <Grid item xs={4}>
                                            <NukTextViewLabel
                                        label="Tipo"
                                        value={p.content.name}
                                    />
                            </Grid>
                            <Grid item xs={4}>
                                            <NukTextViewLabel
                                        label="Origine"
                                        value={p.origin.name}
                                    />
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                    </Grid>)
                    }
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <Card>
                <CardContent>
                  <CremationStatusStepper
                    status={data.cremation.status as CremationStatus}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Grid container rowGap={2}>
                    <Grid item xs={6} md={6}>
                      <Typography variant="h6">DETTAGLI CREMAZIONE</Typography>
                    </Grid>
                    {userHasRole(user, ["MODERATOR", "MASTER"]) && (
                      <Grid item xs={6} md={6} sx={{ textAlign: "right" }}>
                        <NukButton
                          label={"Dettaglio fattura"}
                          size="small"
                          icon={<ReceiptIcon />}
                          onClick={() => setBillingDialogOpen(true)}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid container rowGap={2} sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={4}>
                      <NukTextViewLabel
                        label={t("tipologia", { ns: "general" })}
                        value={t(`cremationType${data.cremation.type}`, {
                          ns: trns,
                        })}
                      />
                    </Grid>

                    {data.cremation.exhumationContainer && (
                      <Grid item xs={12} sm={4}>
                        <NukTextViewLabel
                          label={t(`exhumationContainer`, { ns: trns })}
                          value={t(
                            `exhumationContainer${data.cremation.exhumationContainer}`,
                            { ns: trns }
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={4}>
                      {data.cremation.deliveryEmployee && (
                        <NukTextViewLabel
                          label="Incaricato Onoranza"
                          value={data.cremation.deliveryEmployee}
                        />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
              {data.cremation.type!=='MEDICAL_REMAINS' &&
            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6">CERTIFICATO MEDICO</Typography>
                  <Grid container rowGap={2} sx={{ mt: 2 }}>
                    <Grid item xs={12} md={4}>
                      {data.cremation.medicalCertificateDelivery && (
                        <NukTextViewLabel
                          label="Consegna certificato"
                          value={t(
                            `medicalCertificateDelivery${data.cremation.medicalCertificateDelivery}`,
                            { ns: trns }
                          )}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <NukTextViewLabel
                        label="Certificato medico timbrato"
                        value={
                          data && data.cremation.medicalCertificateSigned
                            ? "Si"
                            : "No"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <NukTextViewLabel
                        label="Certificato medico digitalizzato"
                        value={
                          data && data.cremation.medicalCertificateFile
                            ? ""
                            : "Non caricato"
                        }
                      />

                      {data && data.cremation.medicalCertificateFile && (
                        <NukButton
                          icon={<PictureAsPdfIcon />}
                          size="small"
                          label="Visualizza / Stampa"
                          onClick={() => {
                            streamFile(data.cremation.id.toString());
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          }

            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6">EVENTI</Typography>

                  <CremationEventsTimeline data={data} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}

      {data && (
        <CremationRemoveDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          label={`${data.cremation.firstName} ${data.cremation.lastName}`}
          mode={"static"}
          resourceId={data.cremation.id}
          onCompleted={() => {
            if (setDetailDialogOpen) {
              setDetailDialogOpen(false);
              refetch();
            }
          }}
        />
      )}
      <BillingModal
        setOpen={setBillingDialogOpen}
        open={billingDialogOpen}
        resourceId={data?.cremation.id}
      />
    </NukResourceListDetailModal>
  );
};
