

import { Alert, AlertTitle, Box, capitalize, Card, CardContent, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material"
import { FC, Fragment, useContext } from "react"
import { NukIconTitle } from "../../../../../../core/nuk-components"
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { CreateCremationMutation } from "../../../../../../graphql/nuk-graphql-main"
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { it } from 'date-fns/locale'
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { useAppSelector } from "../../../../../../features/hooks"

type Props = {
    data: CreateCremationMutation | null | undefined
}

export const CremationRequestFormCompleted: FC<Props> = ({data }) => {
    const user = useAppSelector((state) => state.user.value)

    return ( data ? <Box sx={{ p: 5 }}>
        <Alert severity="success" sx={{mb:2}}>
        <AlertTitle>  Richiesta registrata con successo.</AlertTitle>

 
        </Alert>
        <Card>
            <CardContent>
                <Stepper activeStep={1} orientation="vertical">

                    <Step active>
                        <StepLabel>
                            Ricezione richiesta
                        </StepLabel>
                        <StepContent>
                            <Typography>La richiesta concernente il defunto <b>{data.createCremation.lastName?.toUpperCase()} {data.createCremation.firstName?.toLocaleUpperCase()} </b> è stata registrata.</Typography>

                        </StepContent>
                    </Step>

                    <Step active completed>
                        <StepLabel>
                            Riservazione Cella
                        </StepLabel>
                        <StepContent>
                            {data.createCremation.unavailableCellAtReservation ? 
                                                        <Typography sx={{display:'flex',flexDirection:'row',alignItems:'center'}} color="error">
                                                                    <ReportGmailerrorredIcon color="error" sx={{mr:2}}/>
                                                                    Nessuna cella del crematorio è disponibile per la consegna da voi selezionata - contattateci direttamente per organizzare la consegna in altro modo.
                                                        </Typography>

                            :
                                                        <Typography>Un cella è stata prenotata in base alla data di consegna prevista.</Typography>

                            }

                        </StepContent>
                    </Step>


                    <Step active completed>
                        <StepLabel>
                            Invio mail di conferma
                        </StepLabel>
                        <StepContent>
                            <Typography>Una mail di conferma è stata inviata al seguente indirizzo: <b>{user.email}</b></Typography>

                        </StepContent>
                    </Step>

                </Stepper>
            </CardContent>
        </Card>


        <Card sx={{ mt: 2 }}>
            <CardContent>
                <NukIconTitle label="Consegna salma prevista" icon={<LocalShippingIcon />} textTransform="uppercase" />
          
                <Typography variant="h6">
                <b> {capitalize(format(new Date(data.createCremation.estimatedDeliveryAt), "EEEE dd LLLL yyyy ", { locale: it }))} alle ore {capitalize(format(new Date(data.createCremation.estimatedDeliveryAt), "HH:mm", { locale: it }))}</b>

                </Typography>

                <Typography color="error">
                    Ricordarsi di:
                    <ul>
                        <li>Stampare il foglio QR per la consegna. </li>
                        <li>Preparare, inviare o consegnare a mano il certificato medico timbrato.</li>
                    </ul>
                </Typography>
            </CardContent>
        </Card>

        <Alert severity="info" sx={{
            mt:2,
            '& .MuiAlert-icon':{
                display:'none'
            }
        }}>
            <Typography variant="h6">
                    <b>In caso di cambiamenti in merito alla consegna o ai dati inseriti, vi preghiamo di contattarci:
                        
                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <MailIcon sx={{mr:1}}/> <a href="mailto:info@crematorioticino.ch" style={{textDecoration:'none'}}>info@crematorioticino.ch</a>
                        </Box>
                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <LocalPhoneIcon sx={{mr:1}}/> <a style={{textDecoration:'none'}} href="tel:+41796083047">+41 79 608 30 47</a>
                        </Box>
                    </b>
                    </Typography>
         
                </Alert>

        
    </Box> : null)
}