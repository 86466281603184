import { ApolloError } from '@apollo/client';
import CategoryIcon from '@mui/icons-material/Category';
import * as React from 'react';
import { FieldValues, UseFormReturn, UseFormWatch } from 'react-hook-form';
import { MuiColorsEnum } from '../../mui/nuk-mui-default-colors.type';
import { INukResource } from '../../resources/nuk-resource.interface';

export const NukFormDialogContext = React.createContext<NukFormDialogContextInterface>({
    open:false,
    loading: false,
    submitted: false,
    completed:false,
    gqlErrors: null,
    formMethods: null,
    onSubmit: () => {},
    setOpen: () => {},
})


export interface NukFormDialogContextInterface{
    open: boolean,
    loading: boolean
    submitted: boolean
    completed: boolean,
    gqlErrors: ApolloError | null | undefined,    
    formMethods: UseFormReturn<FieldValues, any> | null,
    onSubmit: Function
    setOpen: Function
}