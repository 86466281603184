import { FC } from "react";
import Chart from 'react-apexcharts'


export const ExampleWeeklyCremationsChart : FC = () =>{


    return(
      <Chart options={
        {
          chart: {
            height:10,
            type: 'bar',
            toolbar:{
              show:false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['Lu', 'Ma', 'Me', 'Gio', 'Ve', 'Sa', 'Do'],
          },
          yaxis: {
            show:false,
            title: {
              text: 'Cremazioni'
            }
          },
          fill: {
            opacity: 1
          },
    
        }
      } 
      
      series={[{
        name: 'Cremazioni schedulate',
        data: [3, 1, 1, 2, 3, 2, 1]
      }]} type="bar" height={200} />
    )
}