import { capitalize, FormControl, Grid, InputLabel, Select, TextField, useMediaQuery, useTheme } from "@mui/material";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NukResourceListContext } from '../../../../../core/components/lists/nuk-list-context';
import { NukMenuItem } from '../../../../../themes/nukleus/styles/components/menu/nuk-menu-item.styled';
import { RolesMultiSelectChips } from "../../../../roles/components/selects/roles-multi-select-chips";

/**
 * Users List Filters
 * @returns 
 */
export const UsersListFilters: FC = () => {
  //Resource List Context variables
  const { loading, page, pageSize, refetch, setFiltersLoading, setFiltersCount, clearFilters, setClearFilters } = useContext(NukResourceListContext)

  //Theme hooks
  const theme = useTheme()
  const xsBreakPoint = useMediaQuery(theme.breakpoints.down('sm'))

  //Filter States
  const [filterQ, setFilterQ] = useState<string>('')
  const [filterRoles, setFilterRoles] = useState<number[]>([])
  const [filterEnabled, setFilterEnabled] = useState<boolean | string>('all')

  //Translation
  const { t } = useTranslation()

  /**
   * Users list filter counter function
   * Counts the active filters and displays the number on a badge 
   * (xsBreakPoint only)
   */
  const filterCounter = () => {
    let partialCount = 0
    if (filterQ && filterQ.length) partialCount++
    if (filterRoles && filterRoles.length) partialCount++
    if (filterEnabled !== 'all') partialCount++
    if (setFiltersCount) setFiltersCount(partialCount)
  }

  /**
   * Clear Filters 
   * Resets the filter to the given default values
   */
  const filterClear = () => {
    setFilterQ('')
    setFilterRoles([])
    setFilterEnabled('all')
  }

  /**
   * User Filter Effect Hook
   * Triggered everytime a filter changes with a 500ms delay to avoid to many requests.
   */
  useEffect(() => {
    filterCounter()
    if (!loading) {
      if (setFiltersLoading) setFiltersLoading(true)
      const timeOutId = setTimeout(() => {
        if (setFiltersLoading) setFiltersLoading(false)
        refetch({
          input: {
            pagination: { page: page, limit: pageSize },
            filters: {
              q: filterQ,
              enabled: (typeof filterEnabled === 'number') ? Boolean(filterEnabled) : null,
              roles: filterRoles
            },
          },
        })

      }, 500);
      return () => clearTimeout(timeOutId);
    }

  },
    //Filters to be watched by the hook
    [filterQ, filterRoles, filterEnabled])


  /**
   * Clear Filters Hook
   */
  useEffect(() => {
    if (clearFilters) {
      filterClear()
      if (setClearFilters) setClearFilters(false)
    }
  }, [clearFilters])


  return (
    <Fragment>

      <Grid container spacing={1} rowGap={(xsBreakPoint) ? 2 : 0}>
        <Grid item xs={12} sm={5} >
          <TextField
            fullWidth
            size="small"
            label={`${capitalize(t('search', { ns: 'general' }))}...`}
            value={filterQ}
            onChange={e => setFilterQ(e.target.value)}
          />

        </Grid>
        <Grid item xs={12} sm={5}>
          <RolesMultiSelectChips roles={filterRoles} setRoles={setFilterRoles} size="small" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel sx={{ marginTop: '-7px', textTransform: 'capitalize' }}>{t('status', { ns: 'general' })}</InputLabel>

            <Select
              value={filterEnabled}
              size="small"
              label="Status"
              sx={{ textTransform: 'capitalize' }}
              onChange={e => setFilterEnabled(e.target.value)}
            >
              <NukMenuItem value="all">{t('all', { ns: 'general' })}</NukMenuItem>
              <NukMenuItem value={1}>{t('enabled', { ns: 'general' })}</NukMenuItem>
              <NukMenuItem value={0}>{t('disabled', { ns: 'general' })}</NukMenuItem>

            </Select>
          </FormControl>

        </Grid>

      </Grid>

    </Fragment>

  )
}