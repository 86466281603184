import { WatchQueryFetchPolicy } from "@apollo/client";
import { ConstructionOutlined } from "@mui/icons-material";
import { Box, capitalize, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, SelectProps, Skeleton, Typography } from "@mui/material"
import { FC, Fragment, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
import { Company, useListCompaniesQuery, useListRolesQuery } from "../../../../graphql/nuk-graphql-main";
import { CremationStatus } from "../../constants/cremation-status.enum";
import { CremationStatusChip } from '../chips/cremation-status.chip';


interface Props extends SelectProps {
    statuses: number[],
    setStatuses: React.Dispatch<React.SetStateAction<number[]>>
    fetchPolicy?: WatchQueryFetchPolicy,
}

/**
 * A multi select with chips label for the Roles Resource
 * @param param
 * @returns 
 */
export const CompanyMultiSelect: FC<Props> = ({
    statuses, 
    setStatuses,
    size = "medium",
    fetchPolicy = "cache-first"
}) => {

    const {t} = useTranslation()


    const {loading,data,error} = useListCompaniesQuery({
        variables:{
            input:{
                filters:{},
                pagination:{limit:100}
            }
        },
        fetchPolicy:'network-only'})

    const onFilterStatusesChange = (event: SelectChangeEvent<any>) => {
        const {target: { value }} = event;
        setStatuses(value);
    };

    const getCompanyNameById = (id: number) => {
        if(data && data.companies && data.companies.items && data.companies.items.length){
            const found = data.companies.items.find((c: Company) => c.id == id)
            return found?.name
        }
        return ``
    }

    

   
    return (
            <FormControl sx={{ width: '100%'}} >
                <InputLabel sx={{marginTop:'-7px'}}>Azienda</InputLabel>
                <Select
                    size={size}
                    multiple
                    value={statuses}
                    onChange={onFilterStatusesChange}
                    input={<OutlinedInput label={capitalize(t('status',{ns:'general'}))} />}
                    renderValue={(selected) => 
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, margin:0}}>
                        {selected.map((status) =>  <div key={`status-selected-${status}`} style={{
                            background:'#455A64',
                            padding:1,
                            paddingLeft:8,
                            paddingRight:8,
                            borderRadius:'12px',
                            color:'#FFF'
                        }}>
                            {getCompanyNameById(status)}
                        </div>)}
                        </Box>
                        }
                    >
                    {(!loading && data) ? 
                    data.companies.items.map((company: Company) => (
                        <MenuItem key={`status-company-checkbox-${company.id}`} value={company.id}>
                             <Checkbox checked={statuses.includes(company.id)} /> 
                                {company.name}
                        </MenuItem>
                    )) : null

                    }
                </Select>
            </FormControl>              
    )
} 