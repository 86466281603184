import { ButtonProps, capitalize, Menu } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import * as React from 'react';
import { FC, Fragment } from 'react';
import { NukStringManipulator } from '../../utils/nuk-string-manipulator.class';
import { NukButton } from './nuk-button';

interface Props extends ButtonProps {
    label: string,
    icon: JSX.Element,
    items: NukMenuButtonItem[]
    slim?: boolean,
    skeleton?: boolean,
    
}

export type NukMenuButtonItem = {
  icon: JSX.Element
  label: string
  onClick: Function
  dividerBefore? : boolean
  dividerAfter? : boolean
  buttonType?: 'default' | 'loading',
  loadingText?: string,
  loading?: boolean
}

export const NukMenuButton : FC<Props> = ({
    label,
    icon,
    slim = false,
    skeleton = false,
    items,
    sx
}) => {
   
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  return (
      //TODO: Find a way to dinamically name components
      <Fragment>
        <NukButton 
        label={label} 
        icon={icon}      
        aria-controls={open ? `${NukStringManipulator.normalizeForDom(label)}-nuk-button-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        slim={slim}
        sx={sx}
        skeleton={skeleton}
        />
    <Menu
        id={`${NukStringManipulator.normalizeForDom(label)}-nuk-button-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
       
      <MenuList>
    
      { items.map((item) => {
                 return(
                  <div key={`${item.label}-nuk-menu-button-item`}>
                  {item.dividerBefore && <Divider/>}
                  <MenuItem onClick={() => {
                      item.onClick()
                      handleClose()}}>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText>{capitalize(item.label)}</ListItemText>
                </MenuItem>
                {item.dividerAfter && <Divider/>}
                </div>
                 )
               })
            }
      </MenuList>
      </Menu>
      </Fragment>
  );
}