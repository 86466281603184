import { GridLocaleText } from "@mui/x-data-grid-pro";
import { isNumericLiteral } from "typescript";

export class  NukMuiDataGridTranslator{
    static get(t: Function) : GridLocaleText{
        const ns = {ns:'muiDataGrid'}
        return {
            // Root
            noRowsLabel: t('noRowsLabel',ns),
            noResultsOverlayLabel: t('noResultsOverlayLabel',ns),
            errorOverlayDefaultLabel: t('errorOverlayDefaultLabel',ns),
          
            // Density selector toolbar button text
            toolbarDensity: t('toolbarDensity',ns),
            toolbarDensityLabel: t('toolbarDensityLabel',ns),
            toolbarDensityCompact: t('toolbarDensityCompact',ns),
            toolbarDensityStandard: t('toolbarDensityStandard',ns),
            toolbarDensityComfortable: t('toolbarDensityComfortable',ns),
          
            // Columns selector toolbar button text
            toolbarColumns: t('toolbarColumns',ns),
            toolbarColumnsLabel: t('toolbarColumnsLabel',ns),
          
            // Filters toolbar button text
            toolbarFilters: t('toolbarFilters',ns),
            toolbarFiltersLabel:  t('toolbarFiltersLabel',ns),
            toolbarFiltersTooltipHide: t('toolbarFiltersTooltipHide',ns),
            toolbarFiltersTooltipShow: t('toolbarFiltersTooltipShow',ns),
            toolbarFiltersTooltipActive: (count : any) =>
              count !== 1 ? `${count} active filters` : `${count} active filter`,
          
            // Export selector toolbar button text
            toolbarExport: t('toolbarExport',ns),
            toolbarExportLabel: t('toolbarExportLabel',ns),
            toolbarExportCSV: t('toolbarExportCSV',ns),
            toolbarExportPrint: t('toolbarExportPrint',ns),
          
            // Columns panel text
            columnsPanelTextFieldLabel: t('columnsPanelTextFieldLabel',ns),
            columnsPanelTextFieldPlaceholder: t('columnsPanelTextFieldPlaceholder',ns),
            columnsPanelDragIconLabel: t('columnsPanelDragIconLabel',ns),
            columnsPanelShowAllButton: t('columnsPanelShowAllButton',ns),
            columnsPanelHideAllButton: t('columnsPanelHideAllButton',ns),
          
            // Filter panel text
            filterPanelAddFilter: t('filterPanelAddFilter',ns),
            filterPanelDeleteIconLabel:t('filterPanelDeleteIconLabel',ns),
            filterPanelLinkOperator: t('filterPanelLinkOperator',ns),
            filterPanelOperators: t('filterPanelOperators',ns), // TODO v6: rename to filterPanelOperator
            filterPanelOperatorAnd: t('filterPanelOperatorAnd',ns),
            filterPanelOperatorOr:t('filterPanelOperatorOr',ns),
            filterPanelColumns: t('filterPanelColumns',ns),
            filterPanelInputLabel: t('filterPanelInputLabel',ns),
            filterPanelInputPlaceholder: t('filterPanelInputPlaceholder',ns),
          
            // Filter operators text
            filterOperatorContains: t('filterOperatorContains',ns),
            filterOperatorEquals: t('filterOperatorEquals',ns),
            filterOperatorStartsWith: t('filterOperatorStartsWith',ns),
            filterOperatorEndsWith: t('filterOperatorEndsWith',ns),
            filterOperatorIs: t('filterOperatorIs',ns),
            filterOperatorNot: t('filterOperatorNot',ns),
            filterOperatorAfter: t('filterOperatorAfter',ns),
            filterOperatorOnOrAfter: t('filterOperatorOnOrAfter',ns),
            filterOperatorBefore: t('filterOperatorBefore',ns),
            filterOperatorOnOrBefore: t('filterOperatorOnOrBefore',ns),
            filterOperatorIsEmpty: t('filterOperatorIsEmpty',ns),
            filterOperatorIsNotEmpty: t('filterOperatorIsNotEmpty',ns),
            filterOperatorIsAnyOf: t('filterOperatorIsAnyOf',ns),
          
            // Filter values text
            filterValueAny: t('filterValueAny',ns),
            filterValueTrue: t('filterValueTrue',ns),
            filterValueFalse: t('filterValueFalse',ns),
          
            // Column menu text
            columnMenuLabel:t('columnMenuLabel',ns),
            columnMenuShowColumns: t('columnMenuShowColumns',ns),
            columnMenuFilter: t('columnMenuFilter',ns),
            columnMenuHideColumn: t('columnMenuHideColumn',ns),
            columnMenuUnsort: t('columnMenuUnsort',ns),
            columnMenuSortAsc: t('columnMenuSortAsc',ns),
            columnMenuSortDesc: t('columnMenuSortDesc',ns),
          
            // Column header text
            columnHeaderFiltersTooltipActive: (count : any) =>
              count !== 1 ? `${count} ${t('activeFilters',ns)}` : `${count} ${t('activeFilter',ns)}`,
            columnHeaderFiltersLabel: t('columnHeaderFiltersLabel',ns),
            columnHeaderSortIconLabel: t('columnHeaderSortIconLabel',ns),
          
            // Rows selected footer text
            footerRowSelected: (count : any) =>
              count !== 1
                ? `${count.toLocaleString()} ${t('rowsSelected',ns)}`
                : `${count.toLocaleString()} ${t('rowSelected',ns)}`,
          
            // Total row amount footer text
            footerTotalRows: t('footerTotalRows',ns),
          
            // Total visible row amount footer text
            footerTotalVisibleRows: (visibleCount : any, totalCount : any) =>
              `${visibleCount.toLocaleString()} ${t('of',ns)} ${totalCount.toLocaleString()}`,
          
            // Checkbox selection text
            checkboxSelectionHeaderName:t('checkboxSelectionHeaderName',ns),
            checkboxSelectionSelectAllRows: t('checkboxSelectionSelectAllRows',ns),
            checkboxSelectionUnselectAllRows:t('checkboxSelectionUnselectAllRows',ns),
            checkboxSelectionSelectRow: t('checkboxSelectionSelectRow',ns),
            checkboxSelectionUnselectRow: t('checkboxSelectionUnselectRow',ns),
          
            // Boolean cell text
            booleanCellTrueLabel: t('booleanCellTrueLabel',ns),
            booleanCellFalseLabel: t('booleanCellFalseLabel',ns),
          
            // Actions cell more text
            actionsCellMore: t('actionsCellMore',ns),
          
            // Column pinning text
            pinToLeft: t('pinToLeft',ns),
            pinToRight: t('pinToRight',ns),
            unpin: t('unpin',ns),
          
            // Tree Data
            treeDataGroupingHeaderName: t('treeDataGroupingHeaderName',ns),
            treeDataExpand: t('treeDataExpand',ns),
            treeDataCollapse: t('treeDataCollapse',ns),
          
            // Grouping columns
            groupingColumnHeaderName: t('groupingColumnHeaderName',ns),
            groupColumn: (name : string) => `${t('groupBy',ns)} ${name}`,
            unGroupColumn: (name : string) => `${t('stopGroupingBy',ns)} ${name}`,
          
            // Master/detail
            expandDetailPanel: t('expandDetailPanel',ns),
            collapseDetailPanel: t('collapseDetailPanel',ns),
          
            // Used core components translation keys
            MuiTablePagination: {
                getItemAriaLabel: (type) => {
                    if (type === 'first') {
                        return t('labelToFirstPage',ns);
                    }
                    if (type === 'last') {
                        return t('labelToLastPage',ns);
                    }
                    if (type === 'next') {
                        return t('labelToNextPage',ns);
                    }
                    if (type === 'previous') {
                        return t('labelToPreviousPage',ns);
                    }
                },
                labelRowsPerPage: t('labelRowsPerPage',ns),
                labelDisplayedRows: ({ from, to, count }) =>
                    `${from}-${to} ${t('labelTo',ns)} ${count !== -1 ? count : `${t('labelMoreThan',ns)} ${to}`}`,
                },
          }

    }
}

