import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { INukResource } from '../../resources/nuk-resource.interface';
import CategoryIcon from '@mui/icons-material/Category';
import { MuiColorsEnum } from '../../mui/nuk-mui-default-colors.type';

//TODO: Rename in editor context
export const NukResourceListContext = React.createContext<NukResourceListContext>({
    data: null,
    meta: null,
    loading:false,
    page: 1,
    setPage: null,
    columns: [],
    slimColumns: [],
    slimRowHeight: 50,
    pageSize: 10,
    setPageSize: null,
    rowCount: 0,
    setRowCount: null,
    filtersLoading: false,
    setFiltersLoading: null,
    filtersCount: 0,
    setFiltersCount: null,
    clearFilters: false,
    setClearFilters: null,
    detailDialogOpen: false,
    setDetailDialogOpen: null,
    detailId:null,
    setDetailId: null,
    refetch: () => {},
    resourceConfig: {
        slug: "resource",
        nameSingular: "Resource",
        namePlural: "Resources",
        icon: <CategoryIcon/>,
        indexPath: '/resources',
        translationNamespace: 'resourceResource',
        routes: [],
        color: MuiColorsEnum.primary,
    },
    trns: "",
    getItems: () => {}
})


export interface NukResourceListContext{
    data: any | null,
    meta: any,
    loading:boolean,
    page: number,
    columns: any[],
    slimColumns: any[],
    slimRowHeight: number,
    setPage: Dispatch<SetStateAction<number>> | null,
    pageSize: number,
    setPageSize: Dispatch<SetStateAction<number>> | null,
    rowCount: number,
    setRowCount:Dispatch<SetStateAction<number>> | null,
    filtersLoading: boolean,
    setFiltersLoading: Dispatch<SetStateAction<boolean>> | null,
    filtersCount: number,
    setFiltersCount: Dispatch<SetStateAction<number>> | null,
    clearFilters: boolean,
    setClearFilters: Dispatch<SetStateAction<boolean>> | null,
    detailDialogOpen: boolean,
    setDetailDialogOpen: Dispatch<SetStateAction<boolean>> | null,
    detailId: number | null,
    setDetailId: Dispatch<SetStateAction<number | null>> | null,
    refetch: Function,
    resourceConfig: INukResource
    trns: string
    getItems: Function,
    
}