import { Add } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { AppBar, Badge, Collapse, Grid, LinearProgress, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { FC, Fragment, useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { RouteGenerator } from "../../nuk-utils";
import { NukButton } from "../buttons/nuk-button";
import { NukFloatButton } from "../buttons/nuk-float-button";
import { NukIconTitle } from "../titles/nuk-icon-title";
import { NukResourceListContext } from "./nuk-list-context";
type Props = {
  loading: boolean,
  createButtonLabel?: string,
  createButtonOnClick?: Function

}

/**
 * Filter Bar - shows the filter fields.
 * When on xsBreakpoint, shows a float bar that opens the filters in a modal.
 * @param param0 
 * @returns 
 */
export const NukListResourceAppBar: FC<Props> = ({
  createButtonLabel,
  createButtonOnClick,
  children
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { trns, resourceConfig, loading, filtersLoading, filtersCount,setClearFilters } = useContext(NukResourceListContext)
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const xsBreakPoint = useMediaQuery(theme.breakpoints.down('sm'))
  const mdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Fragment>
      <AppBar position="static" color="default" sx={
        {
          zIndex: 800,
          paddingTop: (mdBreakPoint) ? 2 : 3,
          transition: theme.transitions.create('padding', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),

        }

      }>
        <Toolbar sx={{ flexDirection: 'column' }}>

          <Grid container>
            <Grid item xs={9} sm={7}>

              <NukIconTitle
                icon={resourceConfig.icon}
                label={t(resourceConfig.namePlural, { ns: trns })}
                color={resourceConfig.color}
              />
            </Grid>




            <Grid item xs={3} sm={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>



              {xsBreakPoint ?
                <Fragment>
                  <Badge color="secondary" overlap="circular" badgeContent={filtersCount}>

                    <NukFloatButton icon={<FilterAltIcon />} sx={{ mr: 1 }} onClick={() => setShowFilters(!showFilters)} />
                  </Badge>
                  <NukFloatButton 
                    icon={<Add />} 
                    href={(!createButtonOnClick) ? RouteGenerator.CREATE(resourceConfig.slug) : undefined}
                    onClick={(createButtonOnClick) ? () => createButtonOnClick() : undefined }

                    />
                </Fragment>
                :
                <NukButton
                  href={(!createButtonOnClick) ? RouteGenerator.CREATE(resourceConfig.slug) : undefined}
                  label={(!createButtonLabel) ? `${t('general:new')} ${t(resourceConfig.nameSingular, { ns: trns })}` : createButtonLabel}
                  icon={<Add />}
                  color="primary"
                  slim={xsBreakPoint}
                  onClick={(createButtonOnClick) ? () => createButtonOnClick() : undefined }
                />
              }

            </Grid>
          </Grid>


        </Toolbar>

        <Collapse in={(!xsBreakPoint || (xsBreakPoint && showFilters))}>
          <Toolbar sx={{ background: 'white', padding: 2, display: 'flex', flexDirection: "column" }}>
            <Grid container>
              <Grid item xs={12}>
                {children}
              </Grid>
            </Grid>

            <Grid container sx={{mt:(xsBreakPoint) ? 2 : 0}}>
              <Grid item xs={12} sx={{display:'flex',justifyContent:(xsBreakPoint) ? 'space-between' : 'flex-end'}}>

              <Collapse in={(xsBreakPoint)}>
              <NukButton 
                onClick={() => (setShowFilters) ? setShowFilters(!showFilters) : null}
                variant="text" 
                icon={<CloseIcon />} 
                label={`${t('close', { ns: 'general' })}`} 
                size="small"  
              />
              </Collapse>
              <Collapse in={(filtersCount > 0)}>

              <NukButton 
                onClick={() => (setClearFilters) ? setClearFilters(true) : null}
                variant="text" 
                icon={<FilterAltOffIcon />} 
                label={`${t('clear', { ns: 'general' })} ${t('filters', { ns: 'general' })}`} 
                size="small" />
            </Collapse>
              </Grid>

            </Grid>

      


          </Toolbar>

        </Collapse>



        {(loading || filtersLoading) &&
          <div>
            <LinearProgress />
          </div>
        }

      </AppBar>




    </Fragment>
  )
}