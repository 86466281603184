import { History } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from "@mui/material";
import { FC, Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NukChipListLabel } from '../../../../../core/components/labels/nuk-chip-list-label';
import { NukEnabledLabel } from '../../../../../core/components/labels/nuk-enabled-label';
import { NukResourceListContext } from '../../../../../core/components/lists/nuk-list-context';
import { NukResourceListDetailModal, NukTextViewLabel } from "../../../../../core/nuk-components";
import { useGetUserLazyQuery } from "../../../../../graphql/nuk-graphql-main";
import { UserRemoveDialog } from "../../dialogs/user-remove.dialog";
type Props = {}

/**
 * Users List Detail Dialog  
 * @param param0 
 * @returns 
 */
export const UserListDetailDialog: FC<Props> = ({ }) => {
    const { t } = useTranslation()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
    const { setDetailDialogOpen, refetch } = useContext(NukResourceListContext)
    //Fetch (Get) Detail query
    const [fetch, { data }] = useGetUserLazyQuery()

    return (
        <NukResourceListDetailModal
            resource={data?.user}
            fetch={fetch}
            label={(data) ? `${data.user.firstName} ${data.user.lastName}` : ""}
            loading={false}
            actions={[
                {
                    label: t('remove', { ns: 'general' }),
                    icon: <DeleteIcon />,
                    onClick: () => setDeleteDialogOpen(true)

                },
                {
                    label: t('resources:viewHistory'),
                    icon: <History />,
                    onClick: () => {
                        alert("Opening editor")
                    }
                },
            ]}
        >
            {data ?
                <Grid container rowGap={2}>
                    <Grid item xs={12} md={12}>
                        <NukTextViewLabel label="Id" value={data.user.id.toString()} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NukTextViewLabel label={t('firstName', { ns: 'resourceUser' })} value={data.user.firstName} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NukTextViewLabel label={t('lastName', { ns: 'resourceUser' })} value={data.user.lastName} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NukTextViewLabel label={t('username', { ns: 'resourceUser' })} value={data.user.username} capitalize={false} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NukTextViewLabel label={t('email', { ns: 'resourceUser' })} value={data.user.email} capitalize={false} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NukChipListLabel label={t('roles', { ns: 'general' })} items={
                            (data.user.roles) ? data.user.roles.map((role) => { return { key: role.id.toString(), label: role.title, description: role.description } }) : []
                        } />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NukEnabledLabel label={t('enabled', { ns: 'general' })} value={data.user.enabled} />
                    </Grid>
                </Grid>


                : <Fragment></Fragment>}

            {data &&
                <UserRemoveDialog
                    open={deleteDialogOpen}
                    setOpen={setDeleteDialogOpen}
                    label={`${data.user.firstName} ${data.user.lastName}`}
                    mode={'static'}
                    resourceId={data.user.id}
                    onCompleted={() => {
                        if (setDetailDialogOpen) {
                            setDetailDialogOpen(false)
                            refetch()
                        }
                    }}
                />
            }
        </NukResourceListDetailModal>

    )
}