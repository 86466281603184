import { FC, Fragment, useContext } from "react"
import { NukFormAutoCompleteTextField } from "../../../../core/components/forms/nuk-form-autocomplete-text-field"
import swissCities from '../../../../data/cities/CH_ti_gr.json'
import { NukFormDialogContext } from "../../../../core/nuk-components"
import { useTranslation } from "react-i18next"
import { useFormContext } from 'react-hook-form';

 type Props = {}

 export const CremationCityField : FC<Props> = ({}) => {
    const { setValue } = useFormContext()
    const { t } = useTranslation()
 return(
      <NukFormAutoCompleteTextField 
      name="city" 
      label={t('city', { ns: 'general' })}
      dataSet={swissCities}
      valueKey="cityMain"
      filterKeys={['cityMain']}
      renderOption={(option: any) => (
          <div>
            <b>{option?.cityMain}</b> &nbsp; {option?.zip}
          </div>            
      )
    }
      onChange={(option : any) => {
        //Updates the zipCode field 
        if(option != null && option.hasOwnProperty('zip')){
          setValue('zipCode',option.zip)
        }
      }}
    />

 )

}