import { FC } from "react";
import { NukStructure } from "../../../core/nuk-components";
import { UsersList } from "../components/lists/users-list/users-list";
/**
 * Users List Page
 * @returns FC
 */
export const UsersListPage: FC = () => {
  return (
      <NukStructure>
            <UsersList/>
      </NukStructure>
  )
}