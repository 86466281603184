import * as Yup from 'yup'
import { nukYupI18nextLocale } from '../../../../../core/nuk-core'

//Enabling localization
Yup.setLocale(nukYupI18nextLocale)

/**
 * Cremation editor form create validation schema
 */
export const cremationRequestFormSchema = Yup.object().shape({

  alfCompanyId: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  bellinzonaAssignment: Yup.boolean(),
  type: Yup.string().required(),
  birth: Yup.date().required(),
  death: Yup.date().required(),
  city: Yup.string().required(),
  zipCode: Yup.string().required(),
  countryCode: Yup.string().required(),
  estimatedDeliveryAtDate: Yup.date().required(),
  estimatedDeliveryAtTime: Yup.string().required(),
  medicalCertificateDelivery: Yup.string().required(),
  deliveryEmployee: Yup.string().required(),
  exhumationContainer: Yup.string().when('type', {
    is: 'EXHUMED',
    then: Yup.string()
      .required()            
  })
  
})


export type cremationRequestFormSchemaType = Yup.InferType<typeof cremationRequestFormSchema>
