import { Box, Typography } from "@mui/material"
import { FC, Fragment, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { NukFormSelectField, NukFormSelectFieldItem } from "../../../core/components/forms/nuk-form-select-field"
import { useAppSelector } from "../../../features/hooks"
import { useListCompaniesQuery } from "../../../graphql/nuk-graphql-main"

type Props = {}


export const CompaniesSelectField : FC<Props> = ({}) =>{
    const user = useAppSelector((state) => state.user.value)

    const {loading,data,error} = useListCompaniesQuery({
        variables:{
            input:{
                filters:{},
                pagination:{limit:100}
            }
        },
        fetchPolicy:'network-only'
        

    })
    const { register } = useFormContext()

    const [items,setItems] = useState<NukFormSelectFieldItem[]>([])

    useEffect(() =>{
        if(!loading && data){
            setItems(data.companies.items.map((company) => {return {
                label: company.name,
                value: company.id
            }}))
        }
    },[loading,data])

    return (
        <Fragment>
            {
                (data && items.length>1) ?
        <NukFormSelectField name={"alfCompanyId"} items={items} label={""}>
            
        </NukFormSelectField>
        : (data && items.length===1) && <Box>
            <Typography variant="h6">
            {items[0].label}
            </Typography>
            <input type="hidden" {...register("alfCompanyId")} value={items[0].value}></input>
        </Box>
            }
        </Fragment>

)
}