import { yupResolver } from "@hookform/resolvers/yup";
import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, capitalize, Grid, TextField } from "@mui/material";
import { t } from "i18next";
import { FC, Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { GraphqlFormError, hasGraphQLError } from "../../../../../../../core/components/forms/graphl-form-error";
import { hasError, NukFormError } from "../../../../../../../core/components/forms/nuk-form-error";
import { useUpdateUserPersonalPasswordMutation } from "../../../../../../../graphql/nuk-graphql-main";
import { authProfileUpdatePasswordFormSchema } from './auth-profile-update-password-form.schema';


/**
 * Auth Profile Tab that lets the user change the password
 * @param props 
 * @returns 
 */
export const AuthProfileUpdatePasswordTab: FC = (props) => {
    //Form Setup
    const methods = useForm(
        {
            mode: 'onSubmit',
            resolver: yupResolver(authProfileUpdatePasswordFormSchema)
        });
    const { register, reset, handleSubmit, formState: { errors: formErrors } } = methods

    //Gql Query
    const [updatePersonalPassword, { data, loading, error: gqlError }] = useUpdateUserPersonalPasswordMutation(
        { errorPolicy: 'all' }
    );

    //Gql query post execution hook
    useEffect(() => {
        if (data && data.updateUserPersonalPassword.success) {
            toast.success(capitalize(t('passwordUpdated', { ns: 'general' })));
            reset()
        }
    }, [data])

    //Form Submit
    const onSubmit = (formData: any) => {
        updatePersonalPassword({
            variables: {
                input: {
                    oldPassword: formData.oldPassword,
                    password: formData.newPassword,
                    repeatPassword: formData.rePassword,
                }
            }
        })

    };

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>

                        <TextField
                            {...register('oldPassword', { required: true })}
                            margin="normal"
                            fullWidth
                            id="oldPassword"
                            label={capitalize(t('currentPassword', { ns: 'general' }))}
                            name="oldPassword"
                            type="password"
                            error={(hasError(formErrors.oldPassword) || hasGraphQLError(gqlError, ['OLD_PASSWORD_NOT_MATCHING']))}
                            disabled={loading}
                        />
                        <NukFormError field="oldPassword" errors={formErrors}></NukFormError>
                        <GraphqlFormError errors={gqlError} filters={['OLD_PASSWORD_NOT_MATCHING']} />

                        <TextField
                            {...register('newPassword', { required: true, minLength: 8, maxLength: 20 })}
                            margin="normal"
                            required
                            fullWidth
                            id="newPassword"
                            label={capitalize(t('newPassword', { ns: 'general' }))}
                            name="newPassword"
                            type="password"
                            error={(hasError(formErrors.newPassword) || hasGraphQLError(gqlError, ['password cannot be equal to oldPassword']))}
                            disabled={loading}
                        />
                        <NukFormError field="password" errors={formErrors}></NukFormError>
                        <GraphqlFormError errors={gqlError} filters={['password cannot be equal to oldPassword']} />

                        <TextField
                            {...register('rePassword', { required: true, minLength: 8, maxLength: 20 })}
                            margin="normal"
                            required
                            fullWidth
                            id="rePassword"
                            label={capitalize(t('repeatPassword', { ns: 'general' }))}
                            name="rePassword"
                            type="password"
                            error={hasError(formErrors.rePassword)}

                            disabled={loading}
                        />
                        <NukFormError field="rePassword" errors={formErrors}></NukFormError>

                        <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<Save />}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {(!gqlError && loading)
                                ? t("loading", { ns: 'general' })
                                : t("save", { ns: 'general' })

                            }
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )

}