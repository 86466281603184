import { FC } from 'react';
import { NukStructure } from "../../../core/nuk-components";
import { UserEditorForm } from '../components/forms/user-editor/user-editor.form';
/**
 * User Editor Page
 * @param props 
 * @returns 
 */
export const UsersEditorPage: FC = (props) => {
  return (
    <NukStructure>
        <UserEditorForm/>
    </NukStructure>
  )
}