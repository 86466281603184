import { Cancel, Inventory, Person, Warning } from "@mui/icons-material";
import { Avatar, Box, Chip, styled, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams, GridRowParams } from "@mui/x-data-grid-pro";
import { DataGridActionsBuilder } from "../../../../../core/nuk-utils";
import { CremationStatus } from "../../../constants/cremation-status.enum";
import { cremationsResource as resourceConfig } from '../../../cremations.resource';
import { CremationStatusChip } from "../../chips/cremation-status.chip";
import { cremationListDetailColumn } from './columns/cremations-list-detail.column';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { it } from 'date-fns/locale'
import { Fragment } from "react";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import BadgeUnstyled, { badgeUnstyledClasses } from '@mui/base/BadgeUnstyled';
/**
 * Cremations list default columns (smBreakPoint and up)
 * @param t 
 * @returns 
 */

 const blue = {
  500: '#71767c',
};

const grey = {
  300: '#afb8c1',
  900: '#24292f',
};

const StyledBadge = styled(BadgeUnstyled)(
  ({ theme }) => `
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  list-style: none;
  font-family: IBM Plex Sans, sans-serif;
  position: relative;
  display: inline-block;
  line-height: 1;

  & .${badgeUnstyledClasses.badge} {
    z-index: auto;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 22px;
    height: 22px;
    padding: 0 6px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
    text-align: center;
    border-radius: 12px;
    background: ${blue[500]};
    box-shadow: 0px 4px 6x ${theme.palette.mode === 'dark' ? grey[900] : grey[300]};
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
  }

  & .${badgeUnstyledClasses.invisible} {
    display: none;
  }
  `,
);
export const getColumns = (t: Function) => {
  const ns = {ns: resourceConfig.translationNamespace}
  return [
    {
      field: 'status',
      headerName: 'Stato',
      flex:1,
      renderCell: (params: GridRowParams) => 
        <Fragment>
        <CremationStatusChip 
          animated={true} 
          value={params.row.status as CremationStatus}
          detail={(params.row.status===CremationStatus.DELIVERED && params.row.enteredCell) ? params.row.enteredCell : null}
          />
        {(params.row.status===CremationStatus.REGISTERED && params.row.unavailableCellAtReservation) &&
        <Tooltip title="Nessuna cella disponibile al momento della riservazione.">
        <ReportGmailerrorredIcon color="error" />
        </Tooltip>

    }
        </Fragment>

    },
    {
      field: 'medicalCertificateSigned',
      headerName: 'CMT',
      width:100,
      renderCell : (params: GridRowParams) => (
        <Avatar sx={{ bgcolor: params.row.medicalCertificateSigned ? '#fb637f' : undefined,width:33,height:33,'& .MuiSvgIcon-root':{width:23,height:23}}}>
        <MedicalInformationIcon/>
        </Avatar>
      )

    },
    {
      field: 'identifier',
      headerName: 'Identificatore',
      flex:2,
      renderCell: (params: GridRowParams) => 
        <Fragment>
          {params.row.type==='MEDICAL_REMAINS' ? 
          
          <Typography variant="h5" textTransform="uppercase" fontWeight="bold" sx={{display:'flex',alignItems:'center'}}>
            <StyledBadge badgeContent={(params.row.packets) ? params.row.packets.reduce((a : any,v : any) =>  a = a + v.total , 0 ) : 0} sx={{mr:2}}>
            <Inventory sx={{color:'rgba(0,0,0,0.5)'}} />
          </StyledBadge>
            Residui Medici
          
          </Typography>

          : 
          
          <Typography variant="h5" textTransform="uppercase" fontWeight="bold" sx={{display:'flex',alignItems:'center'}}>
            <Person sx={{color:'rgba(0,0,0,0.5)',mr:2}}/>
            {params.row.lastName} {params.row.firstName} </Typography>
          }
        </Fragment>

    },

    { 
      field: 'funeralHome', 
      headerName: t('funeralHome',{ns: 'general'}), 
      width:300,
      renderCell : (params: GridRowParams) => (<Typography variant="subtitle1" textTransform="uppercase">{
        (params.row.company) &&
        params.row.company.name
        
        }</Typography>)
    },


    { 
      field: 'alfId', 
      headerName: "N°", 
      width:100,
      renderCell : (params: GridRenderCellParams<string>) => <Box>
        {(params.value) ? 
          String(params.value).padStart(5,'0')
          : `-`}
      </Box>
    },
    { 
      field: 'createdAt', 
      headerName: "Data riservazione", 
      flex: 1,
      renderCell : (params: GridRenderCellParams<string>) => <Box>
        {params.value && 
          <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <b style={{marginRight:40}}>
            {format(new Date(params.value), 'dd.MM.yyyy', { locale: it })}
            </b>
            <b>
            {format(new Date(params.value), 'HH:mm', { locale: it })}
            </b>
          </Box>
        }
      </Box>
    },
    /** 
    { 
      field: 'completedAt', 
      headerName: "Ceneri pronte al ritiro", 
      flex: 1,
      renderCell :  (params: GridRenderCellParams<string>) => <Box>
        {params.value ?
          <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <b style={{marginRight:40}}>
          {format(new Date(params.value), 'dd.MM.yyyy', { locale: it })}
          </b>
          <b>
          {format(new Date(params.value), 'HH:mm', { locale: it })}
          </b>
        </Box>
          : <HourglassEmptyIcon sx={{color:(theme) => theme.palette.grey[200]}}/>
        }
      </Box>
    },
    **/
   /** 
    { 
      field: 'collectedAt', 
      headerName: "Ceneri ritirate ", 
      flex: 1,
      renderCell :  (params: GridRenderCellParams<string>) => <Box>
        {params.value ?
          <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <b style={{marginRight:40}}>
          {format(new Date(params.value), 'dd.MM.yyyy', { locale: it })}
          </b>
          <b>
          {format(new Date(params.value), 'HH:mm', { locale: it })}
          </b>
        </Box>
          : <HourglassEmptyIcon sx={{color:(theme) => theme.palette.grey[200]}}/>
        }
      </Box>
    },
    **/
    //Actions
    //DataGridActionsBuilder.buildDefaultResourceActions(resourceConfig.slug)
  ];

}


/**
 * Cremations list slim columns (xsBreakPoint down)
 * @param t 
 * @returns 
 */
export const getSlimColumns =(t: Function) => {
  return [cremationListDetailColumn(t)]
}

