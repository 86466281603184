import * as Yup from 'yup'
import { nukYupI18nextLocale } from '../../../../../core/nuk-core'

//Enabling localization
Yup.setLocale(nukYupI18nextLocale)

/**
 * Role editor form create validation schema
 */
export const roleEditorFormCreateSchema = Yup.object().shape({
  title: Yup.string().required(),
  value: Yup.string().required(),
  description: Yup.string().required(),
})

/**
 * Role editor form update validation schema
 */
export const roleEditorFormUpdateSchema = Yup.object().shape({
  title: Yup.string().required(),
  value: Yup.string().required(),
  description: Yup.string().required(),
})
export type roleEditorFormCreateSchemaType = Yup.InferType<typeof roleEditorFormCreateSchema>
export type roleEditorFormUpdateSchemaType = Yup.InferType<typeof roleEditorFormUpdateSchema>