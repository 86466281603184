import { capitalize, Skeleton, TextField } from "@mui/material";
import { FC, Fragment, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { GraphqlFormError, hasGraphqlErrorSlim } from './graphl-form-error';
import { NukFormContext } from './nuk-form-context';
import { NukFormError } from "./nuk-form-error";

type Props = {
    name: string
    id?: string
    label: string
    rules?: any
    multiline?: boolean
    minRows?: number,
    maxRows?: number,
    sx?: any,
}

/**
 * Mui Text Field Component wrapped in a React Hook Form Controller for maximum compatibility.
 * id: if not specified, is the same as name
 * @param props 
 * @returns 
 */
export const NukFormTextField : FC<Props> = (
  { name,
    id,
    label,
    rules,
    multiline=false,
    minRows=undefined,
    maxRows=undefined,
    sx,
  }) => {
    if(id==null) id = name
    const { control,formState: { errors }} = useFormContext()
    const { loading,gqlErrors } = useContext(NukFormContext)
    const component =         
    <Fragment> 
      <Controller
              name={name}
              control={control}
              defaultValue=""
              rules={rules}
              render={({ field: { onChange, value } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  multiline={multiline}
                  minRows={minRows}
                  maxRows={maxRows}
                  id={id}
                  label={capitalize(label)}
                  type="text"
                  value={value}
                  onChange={onChange}
                  sx={(sx) ? sx : {} }
                  error={(errors.hasOwnProperty(name) || hasGraphqlErrorSlim(gqlErrors,[name])) ? true : false}
                />
              )}
      />
      <NukFormError field={name} errors={errors} />
      <GraphqlFormError errors={gqlErrors} filters={[name]}/>
    </Fragment>

    const skeleton = <Skeleton animation="wave" height={100}/>

    
    return ((!loading) ? component : skeleton)
}