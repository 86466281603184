import { ButtonProps, capitalize, Menu } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import * as React from 'react';
import { FC, Fragment } from 'react';
import nextId from 'react-id-generator';
import { NukFloatButton } from './nuk-float-button';
interface Props extends ButtonProps {
    icon: JSX.Element,
    items: NukMenuButtonItem[]
    skeleton?: boolean,
    
}



export type NukMenuButtonItem = {
  icon: JSX.Element
  label: string
  onClick: Function
  dividerBefore? : boolean
  dividerAfter? : boolean
}

export const NukFloatMenuButton : FC<Props> = ({
    icon ,
    skeleton = false,
    items,
    sx
}) => {
   
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const mainId = nextId()
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  return (
      <Fragment>
                <NukFloatButton
                    icon={icon}
                    size="medium" 
                    color="primary" 
                    aria-label="add"  
                    aria-controls={open ? `${mainId}-nuk-float-button-menu` : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    skeleton={skeleton}
                />

                
    <Menu
        id={`${mainId}-nuk-float-button-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `${mainId}-nuk-float-button-menu-list`,
        }}
      >
          
       
      <MenuList>
    
      { items.map((item) => {
                 return(
                  <div key={`${nextId()}-${mainId}nuk-menu-button-item`}>
                  {item.dividerBefore && <Divider/>}
                  <MenuItem onClick={() => {
                      item.onClick()
                      handleClose()}}>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText>{capitalize(item.label)}</ListItemText>
                </MenuItem>
                {item.dividerAfter && <Divider/>}
                </div>
                 )
               })
            }
      </MenuList>
      </Menu>
      </Fragment>
  );
}