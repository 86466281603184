import { capitalize, Skeleton, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { NukFormContext } from './nuk-form-context';

type Props = {
    title: string
    variant?: string
}

export const NukFormLabel : FC<Props> = ({
    title,
    variant = "h6"
}) => {
    const { control,formState: { errors }} = useFormContext()
    const { loading } = useContext(NukFormContext)
    
    const component = 
    <Typography variant="h6" gutterBottom component="div">
        {capitalize(title)}
    </Typography>
    
    const skeleton = <Skeleton animation="wave" height={30} width={200}/>
    
    return((!loading) ? component : skeleton)
}