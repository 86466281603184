import { LoadingButton, LoadingButtonProps } from "@mui/lab"
import { CircularProgress, IconButton, Skeleton } from "@mui/material"
import { FC } from "react"


interface Props extends LoadingButtonProps {
    label: string
    icon: JSX.Element
    loading?: boolean
    loadingText?: string,
    onClick?: any
    slim?: boolean
    skeleton?: boolean,


}
export const NukLoadingButton : FC<Props> = (
    {
        label,
        icon,
        loading = false,
        skeleton = false,
        loadingText = "Loading",
        type = "button",
        slim = false,
        color = "primary",
        onClick,
        variant = "contained",
        size = "medium",
        sx
    }) =>{
    return (
        slim ? 
            skeleton ?
                    <Skeleton animation="wave" variant="circular" width={30} height={30} />
                :
                loading ? 
                    <CircularProgress color={color} size={25} sx={{mt:'11px'}}/>
                :
                <IconButton  aria-label={label} color={color} onClick={(onClick) ? onClick : null} type={type}>
                    {icon}
                </IconButton> 
        :
        skeleton ?
                    <Skeleton animation="wave" width={100} height={70} />
        :
        <LoadingButton
        loading={loading}
        loadingPosition="start"
        startIcon={icon}
        type={type}
        variant={variant}
        color={color}
        onClick={onClick}
        sx={sx}
        size={size}
      >
        {loading
          ? loadingText
          : label
        }
      </LoadingButton>
    )
}