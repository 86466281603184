import GroupIcon from '@mui/icons-material/Group';
import { UsersEditorPage } from './pages/users.editor.page';
import { UsersListPage } from './pages/users.list.page';
import { INukResource } from '../../core/resources/nuk-resource.interface';
import { MuiColorsEnum } from '../../core/mui/nuk-mui-default-colors.type';
/**
 * Users resource definition
 */
export const usersResource : INukResource = {
    slug: "users",
    nameSingular: "user",
    namePlural: "users",
    icon: <GroupIcon/>,
    indexPath: '/users',
    translationNamespace: 'resourceUser',
    color: MuiColorsEnum.warning,
    roles: ['MASTER'],
    routes:[
        {
            path: '/users',
            element: <UsersListPage/>
        },
        {
            path: '/users/create',
            element: <UsersEditorPage/>
        },
        {
            path: '/users/:id/edit',
            element: <UsersEditorPage/>
        },
    ]
}
