import { Delete, Edit, GetAppRounded, Settings } from "@mui/icons-material";
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";
import { RouteGenerator } from "../../route-generator.class";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import PreviewIcon from '@mui/icons-material/Preview';
export class DataGridActionsBuilder{
    static buildDefaultResourceActions(slug: string){
        return {
            field: 'actions',
            headerName: 'Actions',
            renderHeader: () => <SettingsApplicationsIcon/>,
            type: 'actions',
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                icon={<PreviewIcon />}
                label="Edit"
                component={Link}
                to={RouteGenerator.EDIT(slug,params.id)}
              />,
                <GridActionsCellItem
                icon={<Edit />}
                label="Edit"
                component={Link}
                to={RouteGenerator.EDIT(slug,params.id)}
              />,
            ]
          }
    }
}