import { useEffect } from "react";
import { INukFormHookEffectParams, INukFormHookGetParams } from "../../../../../../core/nuk-core";
import { GetUserQuery, GetUserQueryResult, useGetUserQuery } from "../../../../../../graphql/nuk-graphql-main";

/**
 * User Get - Fetch Hook
 * @param INukFormHookGetParams 
 * @returns GetUserQueryResult
 */
export  const useUserFormEditorGet = (params: INukFormHookGetParams) : GetUserQueryResult => {
    const { id } = params
    return useGetUserQuery({
        variables:{id:id},
        skip:(isNaN(id) || !id)
    });

}

/**
 * User Get - Post Effect
 * @param EffectParams 
 */
export const useUserFormEditorGetEffect = (params: EffectParams) => {
    const { data,setValue,setResourceLabel } = params
    useEffect(() => {
        if(data){
        //Compiling form values
        setValue('firstName', data.user.firstName)
        setValue('lastName', data.user.lastName)
        setValue('email', data.user.email)
        setValue('username', data.user.username)
        setValue('enabled', data.user.enabled)
        //Mapping and setting roles
        setValue('roles',(data.user.roles) ? data.user.roles.map((role) => (role.id)) : [])
        setValue('companies',(data.user.companies) ? data.user.companies.map((companyId) => Number(companyId)) : [])
        //Setting resource label
        setResourceLabel(`${data.user.firstName} ${data.user.lastName}`)
        }
    },[data])
}

//User Get - Interfaces
interface EffectParams extends INukFormHookEffectParams {data: GetUserQuery | null | undefined}
