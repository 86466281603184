import { Autocomplete, capitalize, TextField } from '@mui/material';
import { matchSorter } from 'match-sorter';
import { FC, Fragment, useContext, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import nextId from 'react-id-generator';
import { GraphqlFormError, hasGraphqlErrorSlim } from './graphl-form-error';
import { NukFormContext } from './nuk-form-context';
import { NukFormError } from './nuk-form-error';
type Props = {
    name: string
    label?: string,
    dataSet: any[],
    filterKeys?: string[],
    valueKey: string,
    onChange?: Function,
    renderOption: Function,
    id?: string,
    rules?: any
    sx?: any,
    mode?: String,
}


export const NukFormAutoCompleteTextField: FC<Props> = (
    { name,
        id,
        label = '',
        rules,
        dataSet = [],
        filterKeys = [],
        valueKey,
        onChange = () => {},
        renderOption = () => {},
        sx,
    }
) => {
    const { control, formState: { errors }, setValue } = useFormContext()
    const { loading, gqlErrors } = useContext(NukFormContext)
    const [options, setOptions] = useState<any>(dataSet)
    return (
        <Fragment>
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange : defaultOnChange, value } }) => (
                <Autocomplete
                    freeSolo
                    disablePortal
                    options={options}
                    autoSelect
                    onChange={(event, item) => {
                        defaultOnChange(item)
                        onChange(item)
                    }}
                    onInputChange={(event, value) => {
                        const matched = matchSorter(dataSet, value, {
                            keys: filterKeys
                        })
                        
                        setOptions(matched)
                    }}
       
                    renderOption={(props: any, option: any) =>
                        <li {...{ ...props, ...{ key: nextId() } }}>
                                <div>
                                   { renderOption(option)}
                                </div>
                        </li>}
                    value={value || null}

                    getOptionLabel={(option: any) => (typeof option==='string' || typeof option==='number') ? `${option}` : `${option[valueKey]}`}
                    renderInput={(params) => 
                        <TextField 
                            {...params} 
                            sx={{ mt: 2 }} 
                            label={capitalize(label)}
                            error={(errors.hasOwnProperty(name) || hasGraphqlErrorSlim(gqlErrors,[name])) ? true : false}
                    />} 
         
                />
            )}
        />
        <NukFormError field={name} errors={errors} />
        <GraphqlFormError errors={gqlErrors} filters={[name]} />
    </Fragment>

    )
}


