import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoginIcon from '@mui/icons-material/Login';
import { LoadingButton } from '@mui/lab';
import { Alert, capitalize, Card, CardContent, CardMedia, createTheme,CssBaseline,LinearProgress,Paper,styled,ThemeProvider, useMediaQuery } from '@mui/material';
import { Theme } from '../../../themes/nukleus/Theme';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../features/hooks';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";

type FormValues = {
  email: string;
  password: string;
};

export const SuspensePage: FC = () => {


const theme = createTheme(Theme, {
  components:{
    MuiCssBaseline:{
      styleOverrides: {
        body:{
          backgroundColor:Theme.palette.primary.main,
        }
        
      }
    }
  },

});
const smBreakPoint = useMediaQuery(theme.breakpoints.down('md'));
const xsBreakPoint = useMediaQuery(theme.breakpoints.down('sm'))

  const fieldSx = 
    {
      '& .MuiInputLabel-root':{
        color: Theme.palette.primary.contrastText
      },
      '& .MuiFilledInput-root,.MuiInputLabel-filled,.MuiFormLabel-filled':{
        color:  Theme.palette.primary.contrastText
      },
      '& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused':{
        color: Theme.palette.primary.contrastText
      }
      
    }
  

  return (

    <ThemeProvider theme={theme}>
            <CssBaseline />

            <Container component="main" sx={{width:(smBreakPoint) ? '100%' : '40%'}}>
    

 

      
      <Box sx={{
          display: 'flex',
          flex:0.5,
          flexDirection: 'column',
          alignItems: 'center',
          mt:12
        }}>


<Hypnosis color={theme.palette.primary.contrastText} width="300px" height="300px" duration="3s" />


 
      </Box>
     
    
  </Container>

    </ThemeProvider>

      

  );


}