import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import { FC, useContext } from "react";
import { NukFormDateField, NukIconTitle } from "../../../../../core/nuk-components";
import { NukFormDialogContext } from "../../../../../core/components/dialogs/nuk-form.dialog.context";
import { NukFormSelectField } from "../../../../../core/components/forms/nuk-form-select-field";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { NukTimeUtils } from "../../../../../core/utils/nuk-time-utils";
import { useAppSelector } from "../../../../../features/hooks";
import InventoryIcon from "@mui/icons-material/Inventory";
import { PackageContentEntry } from "./components/package-content-entry";
import { Add } from "@mui/icons-material";
import { useListContentsQuery, useListOriginsQuery } from "../../../../../graphql/nuk-graphql-main";
import { Package } from "./cremation-request-form-medical.dialog";
type Props = {
  packages: Package[],
  addPackage: Function,
  updatePackage: Function,
  removePackage: Function
};



const packageTotalValues : number[] = [1,2,3,4,5,6,7,8,9,10]

export const CremationRequestFormMedicalContent: FC<Props> = ({addPackage,updatePackage,removePackage,packages}) => {
  const { formMethods } = useContext(NukFormDialogContext);
  const user = useAppSelector((state) => state.user.value);

  //Origins
  const { loading: originsLoading, data: originsData, error: originsError } = useListOriginsQuery({
    variables: {
      input: {
        pagination: {
          page: 1,
          limit: 1000
        }
      }
    }
  });

  //Types
  const { loading: contentsLoading, data: contentsData, error: contentsError } = useListContentsQuery({
    variables: {
      input: {
        pagination: {
          page: 1,
          limit: 1000
        }
      }
    }
  });


  /**
   * Updates city based on selected zipCode
   */

  return (
    <Box>
      <Card>
        <CardContent>
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <NukIconTitle
                label="Contenuto"
                icon={<InventoryIcon />}
                textTransform="uppercase"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              {packages.map((p: Package, i: number) => (
                (originsData && contentsData) &&
                <PackageContentEntry
                  key={`package-${i}`}
                  data={p}
                  index={i}
                  remove={removePackage}
                  update={updatePackage}
                  totalValues={packageTotalValues}
                  originValues={originsData}
                  contentValues={contentsData}
                />
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => addPackage()}
              >
                Aggiungi
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ mt: 1 }}>
        <CardContent>
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <NukIconTitle
                label="Consegna prevista"
                icon={<LocalShippingIcon />}
                textTransform="uppercase"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <NukFormDateField name="estimatedDeliveryAtDate" label="Data" />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <NukFormSelectField
                name={"estimatedDeliveryAtTime"}
                items={NukTimeUtils.getTimeValuesForSelect({
                  startHour: 0,
                  endHour: 23,
                  minuteBreak: 30,
                })}
                label={"Ora"}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
