

import { Box, Grid } from "@mui/material"
import { FC, Fragment, useContext } from "react"

type Props = {
    paper: "A4"
}

export const NukPrintContainer: FC<Props> = ({
    paper = "A4",
    children
}) => {
    return (<Box sx={{
        width: '100%',
        padding:'20px',
    }}>
        {children}

    </Box>)
}