import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin  from '@fullcalendar/resource-timeline';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCremationsByCellOccupationLazyQuery } from '../../../graphql/nuk-graphql-main';

type Props = {}


type Event = {
  resourceId:string,
  title: string,
  start: Date,
  end: Date,
}

export const DashboardDailyTimeline : FC<Props> = ({}) =>{
    const {t,i18n} = useTranslation()
    const [query,{data,loading,error}] = useGetCremationsByCellOccupationLazyQuery()
    const [events,setEvents] = useState<Event[]>([])
    useEffect(() =>{

    },[])

    useEffect(() =>{
      if(data){
        setEvents(data.getCremationsByCellOccupation.map((c) => {
          return{
          resourceId:(c.enteredCell) ? c.enteredCell.toString() : "",
          title: (c.type!=='MEDICAL_REMAINS') ? `${c.lastName} ${c.firstName}` : `Residui medici`,
          start: c.enteredCellAt,
          end: c.exitedCellAt
        }
        }))
      }
    },[data,loading])
    return(
                 
        <FullCalendar
        height={'auto'}
      plugins={[ resourceTimelinePlugin ]}
      datesSet={(arg: any)=>{
        query({variables:{
          input:{
            start: arg.start,
            end: arg.end
          }
        }})
      }}
      resources={[
        {"id": "1","title": "Cella 1","eventColor": "#038ffb"},
        {"id": "2","title": "Cella 2","eventColor": "#038ffb"},
        {"id": "3","title": "Cella 3","eventColor": "#038ffb"},
        {"id": "4","title": "Cella 4","eventColor": "#038ffb"},
        {"id": "5","title": "Cella 5","eventColor": "#038ffb"},
        {"id": "6","title": "Cella 6","eventColor": "#038ffb"},
        {"id": "7","title": "Cella 7","eventColor": "#038ffb"},
        {"id": "8","title": "Cella 8","eventColor": "#038ffb"},
        {"id": "9","title": "Cella 9","eventColor": "#038ffb"}
      ]}
      events={events}
      slotMinTime="08:00"
      slotMaxTime="18:00"
      slotDuration="00:01:00"
      slotMinWidth={20}
      
      stickyHeaderDates
      initialView="resourceTimelineWeek"
      resourceAreaHeaderContent="Permanenze"
      firstDay={1}
      resourceAreaWidth={150}
      nowIndicator={true}
      locale={i18n.language}
      buttonText={{
        today:    'oggi',
      }}
      
      slotLabelFormat={[
        { weekday: 'long' },
        {hour: '2-digit',
        hour12:false}, // top level of text

      ]}
      schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
    />
    )
} 