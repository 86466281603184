import * as Yup from 'yup'
import { nukYupI18nextLocale } from '../../../../../core/nuk-core'

//Enabling localization
Yup.setLocale(nukYupI18nextLocale)

/**
 * Cremation editor form create validation schema
 */
export const cremationRequestFormMedicalSchema = Yup.object().shape({
  estimatedDeliveryAtDate: Yup.date().required(),
  estimatedDeliveryAtTime: Yup.string().required(),
})


export type cremationRequestFormMedicalSchemaType = Yup.InferType<typeof cremationRequestFormMedicalSchema>
