
import { FC } from "react";
import { CremationStatus } from "../../constants/cremation-status.enum";
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddTaskIcon from '@mui/icons-material/AddTask';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
type Props = {
    status: CremationStatus | string | undefined
}

export const CremationStatusIcon : FC<Props> = ({status}) => {

    switch(status){
        case CremationStatus.REGISTERED:
            return <MoveToInboxIcon/>
        case CremationStatus.DELIVERED:
            return <AcUnitIcon/>
        case CremationStatus.STARTED:
            return <LocalFireDepartmentIcon/>
        case CremationStatus.COMPLETED:
            return <CheckCircleOutlinedIcon/>
        case CremationStatus.COLLECTED:
            return <AddTaskIcon/>
    }

    return <HelpOutlineIcon/>
}  