import { CardHeader, styled } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';


const drawerWidth: number = 210;
  export const MainDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        WebkitTransform: 'translate3d(0,0,0)', //Safari: to force a respect of zIndexes 
        position: 'relative',
        whiteSpace: 'nowrap',
        background: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        zIndex: 1000,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
      }),
        boxSizing: 'border-box',
        
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.breakpoints.down('sm') ? theme.spacing(0) : theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
      //Icons
    
    }),
  );
