import { capitalize as capitalizeFunc, Typography } from "@mui/material"
import { FC, Fragment } from "react"
import { NukLabel } from "./nuk-label"
import { countries } from "../forms/nuk-form-country-select/nuk-form-country-select.countries"
import { useTranslation } from 'react-i18next';
type Props = {
    label: string,
    value: string,
    capitalize?: boolean
}

export const NukCountryCodeViewLabel : FC<Props> = ({
    label,
    value,
    capitalize = true,
}) =>{
    const country = countries.find(country => country.code===value)
    const {t} = useTranslation()
    return(
        <Fragment>
            <NukLabel label={label}/>
            {country &&
            
                <Typography fontSize={26}  >
                    <img
                      loading="lazy"
                      width="20"
                      height="auto"
                      src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                      alt=""
                      style={{marginRight:8}}
                    />
           
                    {(capitalize) ? capitalizeFunc(t(country.label,{ns:'countries'})) : t(country.label,{ns:'countries'})}
                </Typography>

            }
        </Fragment>
    )
}