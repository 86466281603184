import KeyIcon from '@mui/icons-material/Key'; 
import { MuiColorsEnum } from '../../core/mui/nuk-mui-default-colors.type';
import { INukResource } from '../../core/resources/nuk-resource.interface';
import { ApplicationKeysEditorPage } from './pages/application-keys.editor.page';
import { ApplicationKeysListPage } from './pages/application-keys.list.page';
/**
 * Application Keys Resource Definition
 */
export const applicationKeysResource : INukResource = {
    slug: "application-keys",
    nameSingular: "application key",
    namePlural: "application keys",
    icon: <KeyIcon />,
    indexPath: '/application-keys',
    translationNamespace: 'resourceApplicationKey',
    color: MuiColorsEnum.warning,
    roles:["MASTER"],
    routes:[
        {
            path: '/application-keys',
            element: <ApplicationKeysListPage/>
        },
        {
            path: '/application-keys/create',
            element: <ApplicationKeysEditorPage/>
        },
        {
            path: '/application-keys/:id/edit',
            element: <ApplicationKeysEditorPage/>
        },
    ]
}

