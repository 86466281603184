import { assertValidSchema } from "graphql"
import { TFunction } from "i18next"
import { string } from "yup-locales/dist/locales/tr"

export const translateYupError = (t: TFunction,trns: string,field: string,message: string) : string => {
    let foundPatterns : string[] = []

    //Finding patterns inside the message
    for(const pattern of nukYupI18nextLocaleVars){
        if(message.includes(pattern)) foundPatterns.push(pattern)
    }

    //Replacing found patterns
    for(const foundPattern of foundPatterns){
        message = message.replace(foundPattern,t(foundPattern,{ns:'validation'}))
    }

    //Replacing name
    //message = message.replace(field,t(field,{ns:trns}))
    message = message.replace(field,'Campo ')
    
    return message
}




/**
 * Yup locale structure
 */
export const nukYupI18nextLocale = {
    "mixed": {
      "default": "${path} Invalid value",
      "required": "${path} mixed.required",
      "oneOf": "${path} mixed.oneOf: ${values}",
      "notOneOf": "${path} mixed.notOneOf: ${values}",
      "notType": "${path} mixed.notType types.${type}",
      "defined": "${path} mixed.defined"
    },
    "string": {
      "length": "${path} string.length ${length} string.characters",
      "min": "${path} string.min ${min} string.characters",
      "max": "${path} string.max ${max} string.characters",
      "matches": "${path} string.matches: \"${regex}\"",
      "email": "${path} string.email",
      "url": "${path} string.url",
      "uuid": "${path} string.uuid",
      "trim": "${path} string.trim",
      "lowercase": "${path} string.lowercase",
      "uppercase": "${path} string.uppercase"
    },
    "number": {
      "min": "${path} number.min ${min}",
      "max": "${path} number.max ${max}",
      "lessThan": "${path} number.lessThan ${less}",
      "moreThan": "${path} number.moreThan ${more}",
      "positive": "${path} number.positive",
      "negative": "${path} number.negative",
      "integer": "${path} number.integer"
    },
    "date": {
      "min": "${path} date.min ${min}",
      "max": "${path} date.max ${max}"
    },
    "boolean": {
      "isValue": "${path} boolean.isValue ${value}"
    },
    "object": {
      "noUnknown": "${path} object.noUnknown ${unknown}"
    },
    "array": {
      "min": "${path} array.min ${min} items",
      "max": "${path} array.max ${max} items",
      "length": "${path} array.length ${length} items",
    }
  }

  /**
   * Yup/i18n locale vars - for quick comparison
   */
  export const nukYupI18nextLocaleVars = [
    'mixed.default',
    'mixed.required',
    'mixed.oneOf',
    'mixed.notOneOf',
    'mixed.notType',
    'mixed.defined',
    'string.length',
    'string.min',
    'string.max',
    'string.min',
    'string.matches',
    'string.email',
    'string.url',
    'string.uuid',
    'string.trim',
    'string.lowercase',
    'string.uppercase',
    'string.characters',
    'number.min',
    'number.max',
    'number.lessThan',
    'number.moreThan',
    'number.positive',
    'number.negative',
    'number.integer',
    'date.min',
    'date.max',
    'boolean.isValue',
    'object.noUnknown',
    'array.min',
    'array.max',
    'array.length',
    'types.number',
    'types.date',
    'types.array',
    'types.string',
    'types.boolean',
    'types.object',
    'custom.repeatPassword',
]