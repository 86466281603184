import { createSlice } from '@reduxjs/toolkit'


type LayoutState =
    {
        open: boolean, 
    }

const initialState : LayoutState ={
    open: true
}

export const layoutSlice = createSlice({
    name: 'layout',
    initialState: {value: initialState },
    reducers: {
        toggleDrawer: (state) => {
            state.value.open = !state.value.open
        },

    }
})

export const {toggleDrawer} = layoutSlice.actions 
export default layoutSlice.reducer