import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, capitalize, Card, Grid, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NukIconCard, NukStructure, NukTextViewLabel, NukUserAvatarColored } from '../../../../core/nuk-components';
import { useAppSelector } from '../../../../features/hooks';
import { PrimaryAvatar } from '../../../../themes/nukleus/styles/components/avatar/primary-avatar.styled';
import { AuthProfileUpdatePasswordTab } from './components/tabs/update-password/auth-profile-update-password.tab';


/**
 * Authorized user profile page
 * - Recaps user data and roles
 * - Action: Reset Password
 * @returns 
 */
export default function AuthProfilePage() {

    const user = useAppSelector((state) => state.user.value)
    const { t } = useTranslation()

    const [currentTab, setCurrentTab] = React.useState('passwordTab');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    };

    return (
        <NukStructure>
            <Box sx={{ pl: 2, pt: 0 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2, mb: 2 }}>
                    <NukUserAvatarColored firstName={user.firstName} lastName={user.lastName} />
                    <Typography variant="h5" sx={{ ml: 2 }}>
                        {capitalize(t('yourProfile', { ns: 'general' }))}
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <NukIconCard title={t('personalData', { ns: 'general' })} icon={<AssignmentIndIcon />} sx={{ mb: 2 }}>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid item md={6} sm={12}>
                                    <NukTextViewLabel label={t('firstName', { ns: 'general' })} value={user.firstName}></NukTextViewLabel>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <NukTextViewLabel label={t('lastName', { ns: 'general' })} value={user.lastName}></NukTextViewLabel>
                                </Grid>
                                <Grid item md={12} >
                                    <NukTextViewLabel label={t('email', { ns: 'general' })} capitalize={false} value={user.email}></NukTextViewLabel>
                                </Grid>
                                <Grid item md={12} >
                                    <NukTextViewLabel label={t('username', { ns: 'general' })} value={user.username}></NukTextViewLabel>
                                </Grid>
                            </Grid>
                        </NukIconCard>

                        <NukIconCard title={t('roles', { ns: 'general' })} icon={<WorkspacePremiumIcon />} >
                            {user.roles.map((role: any) => {
                                return (
                                    <Grid container sx={{ pb: 2 }} key={role.id}>
                                        <Grid item xs={12}>
                                            <Card sx={{ padding: 2 }}>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <PrimaryAvatar color="primary" variant="square">
                                                            <WorkspacePremiumIcon />
                                                        </PrimaryAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={role.title} secondary={role.description} />
                                                </ListItem>

                                            </Card>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </NukIconCard>


                    </Grid>
                    <Grid item xs={12} md={9}>
                        <NukIconCard title={t('actions', { ns: 'general' })} icon={<AutoFixHighIcon />} >
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={currentTab}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label={`${t('change', { ns: 'general' })} password`} value="passwordTab" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="passwordTab">
                                        <AuthProfileUpdatePasswordTab />
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </NukIconCard>
                    </Grid>
                </Grid>
            </Box>
        </NukStructure>
    )
}