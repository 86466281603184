import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { NukToastContainer } from './core/components/notifications/nuk-toast-container';
import { getAuthFilteredResources } from './core/utils/resources/nuk-resource-auth-filter';
import { useAppSelector } from './features/hooks';
import "./i18n";
import { LoginPage } from './modules/auth/pages/auth-login.page';
import { DashboardPage } from './modules/dashboard/pages/dashboard.page';
import { NotFound404Page } from './modules/errors/pages/not-found-404.page';
import { StatsPage } from './modules/stats/pages/stats.page';
import { SuspensePage } from './modules/suspense/pages/suspense.page';
import { resources } from './Resources';
import AuthProfilePage from './modules/auth/pages/profile/auth-profile.page';
import { userHasRole } from './modules/auth/utils/auth-user-role-checker';
import { CremationsListPage } from './resources/cremations/pages/cremations.list.page';
import { IntroPage } from './modules/auth/pages/intro.page';

function App() {

  const authUser = useAppSelector((state) => state.user.value)
  const authFilteredResources = getAuthFilteredResources(authUser,resources)

  useEffect(()=>{
    document.title = `${process.env.REACT_APP_NAME}`
  },[])
  return (
    
    <Suspense fallback={<SuspensePage/>}>
  
    <div className="App" style={{fontFamily:'Inter'}}>
            <BrowserRouter>
              {authUser.id==null ?
              <Routes>
                <Route path="*" element={<IntroPage />} />
                <Route path="/login" element={<LoginPage />} />

              </Routes>
              :
              <Routes>
                <Route path="/" element={<CremationsListPage />} />

                {userHasRole(authUser,["MASTER","MODERATOR"]) &&
                  <Route path="/stats" element={<StatsPage />} />
                }
                {userHasRole(authUser,["MASTER","MODERATOR"]) &&
                  <Route path="/dashboard" element={<DashboardPage />} />
                }
                <Route path="/profile" element={<AuthProfilePage />} />
                {authFilteredResources.map(resource => resource.routes.map(route => <Route key={route.path} path={route.path} element={route.element} />))}

                <Route path="*" element={<NotFound404Page />} />
              </Routes>
              }
            </BrowserRouter>
            <NukToastContainer/>
    </div>
    </Suspense>
  );
}

export default App;
