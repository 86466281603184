import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, LinearProgress, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { FC, Fragment, useContext } from "react"
import { NukFormDialogContext } from './nuk-form.dialog.context';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { NukButton } from "../buttons/nuk-button";
import SendIcon from '@mui/icons-material/Send';
import { NukTransitionSlideDown } from "../transitions/nuk-transition-slide-down";
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  title?: string | JSX.Element,
  size?: string,
  completedViewComponent?: JSX.Element
}

export const NukFormDialog: FC<Props> = ({
  title,
  size = "sm",
  children,
  completedViewComponent = <div>Completed</div>
}) => {
  const { completed, submitted, loading, onSubmit, formMethods, open, setOpen } = useContext(NukFormDialogContext)
  const theme = useTheme()
  const xsBreakPoint = useMediaQuery(theme.breakpoints.down('sm'))
  const smBreakPoint = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Dialog
      open={open}
      TransitionComponent={NukTransitionSlideDown}
      //@ts-ignore
      maxWidth={size}
      fullWidth={true}
      onClose={() => setOpen(false)}
      scroll="body"
    >
      <Box component="form" onSubmit={formMethods?.handleSubmit(data => onSubmit(data))}>

        {(!loading && !submitted && !completed) &&
          <DialogTitle>
            <Grid container>


              <Grid item xs={8} sm={8} sx={{textOverflow:'ellipsis'}}>
                {title}
              </Grid>
              <Grid item sm={4} xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>


                <IconButton aria-label="delete"
                  color="inherit"
                  onClick={() => setOpen(false)}
                  sx={{ ml: 2 }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>

              </Grid>


            </Grid>
          </DialogTitle>
        }





        <DialogContent>
          {submitted ?
            <Box sx={{ pt: 10, pb: 10 }}>
              <Typography variant="h6">
                Invio della richiesta in corso...
              </Typography>
              <LinearProgress />


            </Box>
            : completed ? completedViewComponent :
              children

          }
        </DialogContent>

        <DialogActions sx={{mt:2,mb:2}}>
          {(!loading && !submitted && !completed) &&
            <NukButton
              label="Invia richiesta"
              icon={<SendIcon />}
              type="submit"
              sx={{ mr: 1 }}
              skeleton={loading}
            />
          }

          {completed &&
            <NukButton
              label="Chiudi"
              icon={<SendIcon />}
              onClick={() => setOpen(false)}
            />
          }
        </DialogActions>
      </Box>
    </Dialog>)
}