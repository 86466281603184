import { Box, capitalize as capitalizeFunc, Typography } from "@mui/material"
import { FC, Fragment } from "react"

type Props = {
    label: string,
    icon?: JSX.Element
}

export const NukLabel : FC<Props> = ({
    label,
    icon,
}) =>{
    return(
            icon ?
                <Box>
                    <Typography 
                        fontSize={18} 
                        fontWeight="bold" 
                        color={(theme) => theme.palette.grey[500]} 
                        textTransform="uppercase"
                        sx={{display:'flex',alignItems:'center'}}    
                    >
                    {icon}
                    {label}
                    </Typography>
                </Box>
            :
            <Typography fontSize={18} fontWeight="bold" color={(theme) => theme.palette.grey[500]} textTransform="uppercase">
                {label}
            </Typography>

    )
}