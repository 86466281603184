import { Grain, Whatshot } from "@mui/icons-material"
import { Breadcrumbs, Link, Typography } from "@mui/material"
import { FC } from "react"


type Props = {
    items: TNukBreadCrumb[]
}

export type TNukBreadCrumb = {
    label: string,
    path?: string,
}

export const NukBreadcrumbs: FC<Props> = ({items}) => {
    return (
        <Breadcrumbs aria-label="breadcrumb" separator=">">
            {
                items.map((item) => {
                    return(
                        
                            <Link
                            key={`${item.path?.replace('/','-')}-nuk-breadcrumb-path`}
                            underline="none"
                            color="inherit"
                            href={item.path}
                            sx={{
                                    '&:hover': {color:'primary.main',},
                                  }
                                }
                            >
                            <Typography fontSize="small">
                            {item.label}
                            </Typography>
                        </Link>
                    )
                })
            }

        </Breadcrumbs>
    )
}

