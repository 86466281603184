import { Close, Inventory, Person } from "@mui/icons-material";
import { Card, CardContent, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { NukResourceRemoveDialog } from "../../../../core/components/dialogs/nuk-resource-remove.dialog";
import {
  useGetCremationLazyQuery,
  useRemoveCremationMutation,
} from "../../../../graphql/nuk-graphql-main";
import { cremationsResource as resourceConfig } from "../../cremations.resource";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setCreateDialogOpen: Dispatch<SetStateAction<boolean>>,
  setCreateMedicalDialogOpen: Dispatch<SetStateAction<boolean>>
};

/**
 * Cremation Remove Dialog Component
 * Static mode: Expects a label and the resource id
 * Fetch mode: Expects the resourceId and fetches the data
 * @param param0
 * @returns
 */
export const CremationTypeChoiceDialog: FC<Props> = ({
  open = false,
  setOpen,
  setCreateDialogOpen,
  setCreateMedicalDialogOpen,
}) => (
    <Dialog
        onClose={() => {
            setOpen(false);
        } }
        open={open}
        scroll="body"
        fullWidth={true}
        maxWidth="md"

    >

        <DialogContent>
            <Grid container>


                <Grid item xs={8} sm={8} sx={{ textOverflow: 'ellipsis' }}>
                    <Typography variant="h6">
                    Che tipologia di richiesta vuoi effettuare?
                    </Typography>
                </Grid>
                <Grid item sm={4} xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>


                    <IconButton aria-label="delete"
                        color="inherit"
                        onClick={() => setOpen(false)}
                        sx={{ ml: 2 }}
                    >
                        <Close fontSize="inherit" />
                    </IconButton>

                </Grid>


            </Grid>
            <Card sx={{ ":hover": { background: '#429248', color: '#FFF', cursor: 'pointer' } }} onClick={() => {
                setOpen(false);
                setCreateDialogOpen(true);
            } }>
                <CardContent>
                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                        <Person sx={{ color: 'rgba(0,0,0,0.5)', mr: 2 }} />
                        Cremazione di salme o resti esumati
                    </Typography>
                </CardContent>
            </Card>
            <Card sx={{ mt: 2, ":hover": { background: '#429248', color: '#FFF', cursor: 'pointer' } }} onClick={() => {
                setOpen(false);
                setCreateMedicalDialogOpen(true);
            } }>
                <CardContent>
                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                        <Inventory sx={{ color: 'rgba(0,0,0,0.5)', mr: 2 }} />
                        Cremazione di residui medici
                    </Typography>
                </CardContent>
            </Card>

        </DialogContent>
    </Dialog>
);
