import { Avatar, StepIconProps, styled } from "@mui/material";
import { FC } from "react";
import { CremationStatus, CremationStatusColor } from "../../constants/cremation-status.enum";
import { CremationStatusIcon } from "../icons/cremation-status.icon";



const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#784af4',
      }),
      '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
      },
      '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
      },
    }),
  );
  
  interface CremationStatusStepIcon extends StepIconProps {
    status: CremationStatus
} 

 export const CremationStatusStep : FC<CremationStatusStepIcon> = ({active,completed,icon,status}) => {
   return (
       <QontoStepIconRoot ownerState={{ active }} sx={{height:'auto',}}>
               <Avatar sx={{
                               backgroundColor:(theme) => (completed) ? CremationStatusColor[status] : theme.palette.grey[300],
                               color:  (theme )=> (completed) ? theme.palette.success.contrastText : theme.palette.grey[600]
                           }}>
               <CremationStatusIcon status={status} />
               </Avatar>
       </QontoStepIconRoot>
     );
 }