import { Save } from "@mui/icons-material"
import { AppBar, CircularProgress, Grid, LinearProgress, Toolbar, useMediaQuery, useTheme } from "@mui/material"
import React, { FC, Fragment, useContext } from "react"
import { useTranslation } from "react-i18next"
import nextId from "react-id-generator"
import { useAppSelector } from "../../../features/hooks"
import { NukButton } from "../buttons/nuk-button"
import { NukMenuButton, NukMenuButtonItem } from "../buttons/nuk-menu-button"
import { NukBreadcrumbs, TNukBreadCrumb } from "../navigation/nuk-breadcrumbs"
import { NukIconTitle } from "../titles/nuk-icon-title"
import { NukFormContext } from './nuk-form-context'
import { NukFormFloatAppBar } from "./nuk-form-float-app-bar"



export interface NukFormAppBarProps {
    actions? : NukFormAppBarAction[];
    breadcrumbs?: TNukBreadCrumb[],
    resourceTranslationNameSpace?: string 
    resourceLabel?: string | null | undefined
}

export type NukFormAppBarAction = {
  label: string,
  icon: JSX.Element
  items?: NukMenuButtonItem[]
  onClick?: Function
}




export const NukFormAppBar : FC<NukFormAppBarProps> = ({
  actions = [],
  breadcrumbs = [],
  resourceLabel = ""
}) =>{
  const { loading,resourceConfig,submitted,mode,trns } = useContext(NukFormContext)
  const drawerOpen = useAppSelector((state) => state.layout.value.open)
  const theme = useTheme();
  const mdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
  const smBreakPoint = useMediaQuery(theme.breakpoints.down('md'));
  const xsBreakPoint = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation();


    return(
        <Fragment>
        <AppBar position="fixed" color="default" sx={
            { top: (mdBreakPoint) ? 64 : (smBreakPoint) ? 56 : 64,
              zIndex: 800,
              paddingLeft: (drawerOpen) ? 
                29 : 
                (xsBreakPoint) ? 0 : 8,
              paddingTop:(mdBreakPoint) ? 1 : 2,
              transition: theme.transitions.create('padding', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            
            }
             
             }>
           <Toolbar sx={{flexDirection:'column'}}>
             <Grid container>
               <Grid item sx={{display:'flex',alignItems:'left'}}>

                <NukBreadcrumbs items={breadcrumbs}/>

               </Grid>
             </Grid>
             <Grid container>
               <Grid item xs={12} sm={7}>
           
               <NukIconTitle 
                skeleton={loading} 
                label={(mode=='create') ? 
                        `${t('general:create')} ${t('general:new')} ${t(resourceConfig.nameSingular,{ns:trns})}` : 
                        `${resourceLabel}`} icon={resourceConfig.icon} 
                color={resourceConfig.color}
                />
               </Grid>
              
          
   
   
               <Grid item xs={3} sm={5} sx={{ display:(smBreakPoint) ? 'none' : 'flex',justifyContent: 'flex-end'}}>
               
                {/* Action Buttons and progress spinner if submitted */}
                { submitted ? 
                <CircularProgress color="primary" size={40}/>
                :  
                <Fragment>
                {actions.map((action) => {
                  return (action.items !== undefined) ? 
                             <NukMenuButton 
                               key={`${nextId()}-nuk-form-app-bar-menu-button`}
                               label={action.label} 
                               icon={action.icon} 
                               items={action.items} 
                               sx={{mr:1}}
                               slim={smBreakPoint}
                               skeleton={loading}
                             /> 
                         :   <NukButton 
                               key={`${nextId()}-nuk-form-app-bar-button`}
                               label={action.label} 
                               icon={action.icon} 
                               onClick={(action.onClick !== undefined) ? action.onClick : null}
                               sx={{mr:1}}
                               slim={smBreakPoint}
                               skeleton={loading}
                             />
                 })}
                    <NukButton 
                    label={t('general:save')} 
                    icon={<Save />} 
                    type="submit"
                    sx={{mr:1}}
                    slim={smBreakPoint}
                    skeleton={loading}
                  />

                 
                 </Fragment>
            
              }

        

               
            

        

         
               </Grid>
             <Grid item xs={12} sm={12} md={12} sx={{pt:1}}>
                <LinearProgress sx={{width:'120%',height:5,position:'relative',left:-35,visibility: (submitted) ? 'visible' : 'hidden'}} />
   
               </Grid>
             </Grid>

   
           </Toolbar>
       
         </AppBar>
             {/*Float Action Bar - Appears on small devices at the bottom */}
             {smBreakPoint && 
                <NukFormFloatAppBar actions={actions}/>
             }
              
             </Fragment>
    )
}