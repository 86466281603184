import { NukMenuSection } from './nuk-menu-section.interface';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { usersResource } from '../../../resources/users/users.resource';
import { rolesResource } from '../../../resources/roles/roles.resource';
import { applicationKeysResource } from '../../../resources/application-keys/application-keys.resource';
import { cremationsResource } from '../../../resources/cremations/cremations.resource';
export const drawerMenuItems : NukMenuSection[] = [
    {
        label:"Main",
        items:[
            {
                label: "Dashboard",
                icon: <DashboardIcon/>,
                to: "/dashboard",
                roles:['MASTER','MODERATOR']

            },
            { resource: cremationsResource},
            /*
            {
                label: "Statistiche",
                icon: <QueryStatsIcon/>,
                to: "/stats",
                roles:['MASTER','MODERATOR']
            }*/
        ],
    },
    {
        label:"Master",
        roles:['MASTER'],
        items:[
            {resource:usersResource},
            {resource:rolesResource},
            {
                label: "To Mam",
                icon: <DashboardIcon></DashboardIcon>,
                to:'/dashboard',
                roles:['GUGU']
            
            },
            {resource:applicationKeysResource}
        ]
    }
]

