import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { MuiColorsEnum } from '../../core/mui/nuk-mui-default-colors.type';
import { INukResource } from '../../core/resources/nuk-resource.interface';
import { RolesEditorPage } from './pages/roles.editor.page';
import { RolesListPage } from './pages/roles.list.page';
/**
 * Roles resource definition
 */
export const rolesResource : INukResource= {
    slug: "roles",
    nameSingular: "role",
    namePlural: "roles",
    icon: <WorkspacePremiumIcon />,
    indexPath: '/roles',
    translationNamespace: 'resourceRole',
    color: MuiColorsEnum.warning,
    roles:['MASTER'],
    routes:[
        {
            path: '/roles',
            element: <RolesListPage/>
        },
        {
            path: '/roles/create',
            element: <RolesEditorPage/>
        },
        {
            path: '/roles/:id/edit',
            element: <RolesEditorPage/>
        },
    ]
}

