import { Button, ButtonProps, IconButton, Link, Skeleton } from "@mui/material"
import { FC } from "react"

interface IProps extends ButtonProps {} // your custom props

interface Props extends ButtonProps {
    label: string
    icon: JSX.Element
    onClick?: any
    slim?: boolean,
    skeleton?: boolean,

}
export const NukButton : FC<Props> = (
    {
        icon,
        label,
        onClick,
        sx,
        slim = false,
        variant = "contained",
        size = "medium",
        color = undefined,
        skeleton = false,
        type = "button",
        disabled = false,
        href= undefined,
    }) =>{
    return (
        slim ?
        skeleton ? 
            <Skeleton animation="wave" variant="circular" width={30} height={30} sx={sx} />
            :
            <Link href={href}>

            <IconButton  aria-label={label} color={color} onClick={onClick} type={type} disabled={disabled} >
                {icon}
            </IconButton> 
            </Link>
        :
        skeleton ? 
            <Skeleton animation="wave" width={100} height={70} sx={sx}/>
            :
            (<Button sx={sx} startIcon={icon} color={color} variant={variant} onClick={onClick} type={type} disabled={disabled} href={(type==='submit') ? undefined : href} size={size}>
                {label}
            </Button>)
    )
}