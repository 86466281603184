import { FC } from "react";
import Chart from 'react-apexcharts'


export const ExampleWeeklyOccupationChart : FC = () =>{


    return(
      <Chart options={
        {
          colors:['#d32f2e','#038ffb'],
          chart: {
            height:10,
            type: 'bar',
            stacked: true,
            stackType: '100%',
            toolbar:{
              show:false
            },
    
            
          },
          legend:{
            show:false
          },
          plotOptions: {
            bar: {
              horizontal: false,
          
            },
            
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['Lu', 'Ma', 'Me', 'Gio', 'Ve', 'Sa', 'Do'],
            
          },
          yaxis: {
            show:false,
            title: {
              text: 'Cremazioni'
            }
          },
          fill: {
            opacity: 1
          },
    
        }
      } 
      
      series={[{
        name: 'Occupato',
        data: [3, 1, 1, 2, 3, 2, 1]
      },
      {
        name: 'Libero',
        data: [0, 2, 2, 1, 0, 1, 2]
      }]} type="bar" height={200} />
    )
}