import { useEffect } from 'react';
import { toast } from "react-toastify";
import { INukFormHookEffectParams } from "../../../../../../core/nuk-core";
import { CreateApplicationKeyMutation, useCreateApplicationKeyMutation } from '../../../../../../graphql/nuk-graphql-main';
import { applicationKeysResource as resourceConfig } from '../../../../application-keys.resource';
import { applicationKeyEditorFormCreateSchemaType } from "../application-key-editor-form.schema";

/**
 * ApplicationKey Create - Hook Wrapper
 * @param params 
 * @returns 
 */
export  const useApplicationKeyFormEditorCreate = () =>{
    return useCreateApplicationKeyMutation();
}

/**
 * ApplicationKey Create - Executes the create function
 * @param service 
 * @param values 
 */
export const executeApplicationKeyFormEditorCreate = (service: Function,values: applicationKeyEditorFormCreateSchemaType) => {
    //Additional validation can be done here.
    //Launching service
    service({
        variables: {
            input: {
                name: values.name,
            }
        }
    })
}

/**
 * ApplicationKey Create - Post Effect
 * @param params 
 */
export  const useApplicationKeyFormEditorCreateEffect = (params: EffectParams) => {
    const { data,error,setGqlError,navigate } = params
    useEffect(() => {
        if(data){
            //Toasting success
            toast.success(`${resourceConfig.nameSingular} ${data.createApplicationKey.name} Created!`)
            //Navigating back to list
            navigate(resourceConfig.indexPath)
        } else if(error){
            //Setting Gql Errors
            setGqlError(error);
        }
    },[data,error])
}

//User Create - Interfaces
interface EffectParams extends INukFormHookEffectParams {data: CreateApplicationKeyMutation | null | undefined}
