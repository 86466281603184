import { Logout } from '@mui/icons-material';
import { Avatar, Box, Chip, Grid, ListItemIcon, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../features/hooks';
import { logout } from '../../../modules/auth/redux/auth-user.slice';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { NukUserAvatarColored } from './nuk-user-avatar-colored';
import { useTranslation } from 'react-i18next';
interface Props {
 
}

export const AvatarMenu: FC<Props> = (props) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const user = useAppSelector((state) => state.user.value)
    const {t} = useTranslation()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const logoutAction = () => {
        AuthService.logout({
          data: null,
          onSuccess: () =>{
            dispatch(logout())
            navigate('/')
          },
          onError: () => {
            dispatch(logout())
            navigate('/')
          }
        })

    }

    const menuOpen = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
    setAnchorEl(null);
    };
  
    return (
        <React.Fragment>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={menuOpen ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
        >
        <NukUserAvatarColored firstName={user.firstName} lastName={user.lastName} />
        </IconButton>
        
      <Menu
  anchorEl={anchorEl}
  id="account-menu"
  open={menuOpen}
  onClose={handleClose}
  onClick={handleClose}
  PaperProps={{
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1.5,
      '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  }}
  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
>
  <MenuItem style={{ backgroundColor: 'transparent' }}>
      <Grid container>

        <Grid item sm={12} sx={{display:'flex',justifyContent:'center'}}>
          <h3 style={{marginBottom:10,marginTop:0}}>{user.firstName} {user.lastName}</h3>
        </Grid>
        <Grid item sm={12} sx={{display:'flex',justifyContent:'center'}}>
        {user.roles && user.roles.length>0 &&
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          {user.roles.map((role) => {
            return <Tooltip title={role.description} key={role.id}>
                    <Chip label={role.title} size="small" color="primary" />
                  </Tooltip>
          })}
        </Stack>
      }
        </Grid>
      </Grid>
    

 

 
  
    
    
  </MenuItem>

  <Divider />
  <MenuItem
          component={Link}
          to={`/profile`}
  >
    <Avatar /> {t('profileAndSettings',{ns:'general'})}
  </MenuItem>
  <Divider />

  <MenuItem onClick={logoutAction}>
    <ListItemIcon>
      <Logout fontSize="small" />
    </ListItemIcon>
    Logout
  </MenuItem>
</Menu>
</React.Fragment>

    )
}
