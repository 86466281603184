import { CardHeader, styled } from "@mui/material";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { textTransform } from '@mui/system';

export const NukResourceListDataGrid = styled(DataGridPro)(({ theme }) => ({
        "& .MuiDataGrid-root":{
            borderColor:'blue'
        },
        "& .MuiDataGrid-pinnedColumns ":{
            backgroundColor:theme.palette.primary.contrastText,
        },
        "& .MuiDataGrid-columnHeaders,.MuiDataGrid-pinnedColumnHeaders":{
            backgroundColor:theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        "& .MuiDataGrid-columnHeaderTitle":{
            textTransform: 'capitalize'
        },
        borderRadius:0,
        border:0,
        borderLeftColor:'primary.main',
        '& .MuiDataGrid-columnHeaders':{borderRadius:0},
        '& .MuiDataGrid-columnHeader':{borderRadius:0}
  }));