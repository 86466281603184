import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { NukResourceRemoveDialog } from '../../../../core/components/dialogs/nuk-resource-remove.dialog';
import { useGetRoleLazyQuery, useRemoveRoleMutation } from "../../../../graphql/nuk-graphql-main";
import { rolesResource as resourceConfig } from '../../roles.resource';

type Props = {
    mode: 'fetch' | 'static',
    resourceId: number,
    label?: string,
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>,
    onCompleted?: Function

}

/**
 * Role Remove Dialog Component
 * Static mode: Expects a label and the resource id
 * Fetch mode: Expects the resourceId and fetches the data  
 * @param param0 
 * @returns 
 */
export const RoleRemoveDialog: FC<Props> = ({
    mode: modeProp = "fetch",
    resourceId,
    label: labelProp = "",
    open = false,
    onCompleted = null,
    setOpen
}) => {

    const [fetch, { data: fetchData, loading: fetchLoading, error: fetchError }] = useGetRoleLazyQuery()
    const [remove, { data: removeData, loading: removeLoading, error: removeError }] = useRemoveRoleMutation()
    const [mode] = useState<string>(modeProp)
    const [label, setLabel] = useState<string>(labelProp)
    //Mode Hook - if fetch, the data is service-loaded
    useEffect(() => {
        if (mode === 'fetch') {
            fetch({ variables: { id: resourceId } })
        }
    }, [mode])

    //Post-fetch hook
    useEffect(() => {
        if (fetchData) {
            setLabel(`${fetchData.role.title}`)
        }
    }, [fetchData])


    //Delete confirmation
    const handleConfirm = () => {
        remove({
            variables: { id: resourceId }
        })
    }

    /**
     * After removal hook
     */
    useEffect(() => {
        if (removeData) {
            setOpen(false)
            toast.success(`${resourceConfig.nameSingular} ${removeData.removeRole.title} Removed!`)
            if (onCompleted) onCompleted()
        }
    }, [removeData, removeError])

    return (

        <NukResourceRemoveDialog
            open={open}
            setOpen={setOpen}
            loading={fetchLoading}
            confirmLoading={removeLoading}
            label={label}
            error={removeError}
            handleConfirm={() => handleConfirm()}
        >
        </NukResourceRemoveDialog>
    )
}