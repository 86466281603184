import { Box, Typography } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { CremationStatus } from "../../../../constants/cremation-status.enum";
import { CremationStatusChip } from "../../../chips/cremation-status.chip";

/**
 * Cremation list detail column
 * (Used for xsBreakPoint to provide a better mobile experience)
 * ? This should become obsolete once MUI implements a default collapse in MuiDataGridProd
 * @param t 
 * @returns 
 */
export const cremationListDetailColumn = (t: Function) => {
  return {
    field: 'name',
    headerName: t('details', { ns: 'general' }),
    sortable: false,
    flex:1,
    renderCell: (params: GridRowParams) => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', pl: 1 }}>
          <Typography fontWeight="bold">
            {params.row.firstName} {params.row.lastName}
          </Typography>
          <CremationStatusChip value={params.row.status as CremationStatus}/>
        </Box>
      )
    }
  }
}

export const cremationListDetailColumnHeight = 70