import FireplaceIcon from '@mui/icons-material/Fireplace';import { INukResource } from '../../core/resources/nuk-resource.interface';
import { CremationsEditorPage } from './pages/cremations.editor.page';
import { CremationsListPage } from './pages/cremations.list.page';
/**
 * Cremation Resource Definition
 */
export const cremationsResource : INukResource = {
    slug: "cremations",
    nameSingular: "cremation",
    namePlural: "cremations",
    icon: <FireplaceIcon />,
    indexPath: '/cremations',
    translationNamespace: 'resourceCremation',
    routes:[
        {
            path: '/cremations',
            element: <CremationsListPage/>
        },
        /*{
            path: '/cremations/create',
            element: <CremationsEditorPage/>
        },*/
        {
            path: '/cremations/:id/edit',
            element: <CremationsEditorPage/>
        },
    ]
}

