//REGISTERED
//DELIVERED
//STARTED
//COMPLETED
//COLLECTED
import { blueGrey } from '@mui/material/colors'

export enum CremationStatus {
    REGISTERED = 'REGISTERED',
    DELIVERED = 'DELIVERED',
    STARTED = 'STARTED',
    COMPLETED = 'COMPLETED',
    COLLECTED = 'COLLECTED'
}
export enum CremationStatusColor {
    REGISTERED = '#37474f', 
    DELIVERED = '#2196f3',
    STARTED = '#d80000',
    COMPLETED = '#ff8b2c',
    COLLECTED = '#388e3c'
}

