import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Container, createTheme, CssBaseline, Grid, ThemeProvider, Toolbar, useMediaQuery } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import nextId from 'react-id-generator';
import { useAppDispatch, useAppSelector } from '../../../features/hooks';
import { toggleDrawer } from '../../../features/layout';
import { MainDrawer } from '../../../themes/nukleus/styles/components/drawer/main-drawer.styled';
import { Theme } from '../../../themes/nukleus/Theme';
import { drawerMenuItems } from '../../config/menus/drawer-menu.config';
import { getAuthFilteredStructure } from '../../utils/menus/nuk-menu-auth-filter';
import { AvatarMenu } from '../avatars/nuk-avatar-menu';
import { NukDrawerMenuItem } from '../menus/nuk-drawer-menu-item';
interface Props {
  
}

const theme = createTheme(Theme);
const drawerWidth: number = 210;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor:'#388e3c',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));






export const NukStructure: FC<Props> = (props) => {
  const dispatch = useAppDispatch()
  const {t} = useTranslation()
  //Redux 
  const authUser = useAppSelector((state) => state.user.value)
  const open = useAppSelector((state) => state.layout.value.open)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const authFilteredMenuStructure = getAuthFilteredStructure(authUser,drawerMenuItems)
  const appName = process.env.REACT_APP_NAME
  const drawerOpen = useAppSelector((state) => state.layout.value.open)
  const xsBreakPoint = useMediaQuery(theme.breakpoints.down('sm'))


const toggleDrawerAction = () => {
dispatch(toggleDrawer())

};

const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};
  return (
    <ThemeProvider theme={theme} >
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawerAction}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Grid container>
              <Grid item xs={8} sm={8}>

                {!(xsBreakPoint && drawerOpen ) &&
              <img style={{width:'200px'}} src="/static/images/brand/brand-navbar.svg"/>
                }
              </Grid>
              <Grid item xs={4} sm={4} sx={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
              <AvatarMenu />

              </Grid>
            </Grid>
  

          </Toolbar>
        </AppBar>
        <MainDrawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],

            }}
          >
            <IconButton onClick={toggleDrawerAction}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            
            {authFilteredMenuStructure.map((section,index) =>{
              if(section.items){
                const items = section.items.map((item) => {
                    if(item.resource){
                      return <NukDrawerMenuItem 
                                key={item.resource.indexPath}
                                label={t(item.resource.namePlural,{ns:item.resource.translationNamespace})} 
                                to={item.resource.indexPath} 
                                icon={item.resource.icon}
                                color={item.resource.color}
                                closeDrawerOnClick={xsBreakPoint}
                              />
                    } else{
                      return <NukDrawerMenuItem 
                                key={item.to}
                                label={(item.label) ? item.label : ""} 
                                to={(item.to) ? item.to : ""} 
                                icon={(item.icon) ? item.icon : null}
                                closeDrawerOnClick={xsBreakPoint}

                              />
                    }
                })
                if(index>0)
                  return [<Divider key={nextId()} sx={{ my: 1 }} />,...items]
                else
                  return items
              }
            })}

          </List>
        </MainDrawer>
          <Box
          component="main"
          sx={{
            display:'flex',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
          >
          {/*<Toolbar />*/}  
          <Container maxWidth={false} sx={{ 
            mt: 8,
            flex:1,           
            display:'flex',
            flexGrow: 1,
            flexDirection:'column',
            backgroundColor: (theme) => theme.palette.grey[100]}} disableGutters >
              {props.children}
          </Container>
          </Box>
      </Box>
    </ThemeProvider>

  );
};

