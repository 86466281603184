

import { Box, Grid, Typography } from "@mui/material"
import { FC, Fragment, useContext } from "react"
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
 type Props = {}

 export const DefaultPrintHeader : FC<Props> = ({}) => {
 return(
     <Grid container sx={{
         borderBottom:'3px solid #77ba52',
         pb:1
     }}>
         <Grid item xs={6}>
         <img style={{ width: '200px' }} src="/static/images/brand/brand-color.svg" />

         </Grid>
         <Grid item xs={6} sx={{display:'flex',flexDirection:'column',alignItems:'flex-end',mt:'10px'}}>
            <Typography variant="subtitle2" sx={{fontSize:'14px'}}>
             Crematorio Ticino Sagl
             </Typography>
             <Typography variant="subtitle2" sx={{fontSize:'14px'}}>
             Viale Righetti 26
             </Typography>
             <Typography variant="subtitle2" sx={{fontSize:'14px'}}>
             6503 Bellinzona-Carasso
             </Typography>
             <Typography variant="subtitle2" sx={{fontSize:'12px'}} color="#77ba52">
                Tel: +41796083047 | Mail: info@crematorioticino.ch
             </Typography>
             <Typography variant="subtitle2" sx={{fontSize:'12px'}}>
            
             </Typography>
      
             </Grid>
     </Grid>

   )
}