import { capitalize, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Skeleton, TextField } from "@mui/material";
import { FC, Fragment, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { GraphqlFormError, hasGraphqlErrorSlim } from './graphl-form-error';
import { NukFormContext } from './nuk-form-context';
import { NukFormError } from "./nuk-form-error";
import nextId from 'react-id-generator';
type Props = {
    name: string
    id?: string
    label?: string
    defaultValue?: string  
    items: NukFormRadioGroupItem[]
    rules?: any
    size?: "small" | "default" 
    sx?: any

}

type NukFormRadioGroupItem = {
  label: string
  value: string
  default?: boolean

}

/**
 * Mui Text Field Component wrapped in a React Hook Form Controller for maximum compatibility.
 * id: if not specified, is the same as name
 * @param props 
 * @returns 
 */
export const NukFormRadioGroupField : FC<Props> = (
  { name,
    id,
    label = "",
    rules,
    items,
    size = "default",
    defaultValue = null,
    sx = undefined,
  }) => {
    if(id==null) id = name
    const { control,formState: { errors }} = useFormContext()
    const { loading,gqlErrors } = useContext(NukFormContext)
    const component =         
    <Fragment> 
      <Controller
              name={name}
              control={control}
              rules={rules}
              defaultValue={defaultValue}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                <FormLabel sx={{fontWeight:'bold'}}>{label}</FormLabel>
                <RadioGroup
                sx={sx}
                row
                aria-labelledby="type"
                name="type"
                onChange={(e) => onChange(e)}
                
              >
                {items.map((item) => <FormControlLabel key={nextId()} value={item.value} 
                
                control={<Radio sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize:(size=="small") ? 22 : null

                  },}}/>} 
                  sx={{'& .MuiTypography-root':{
                    fontSize:(size=="small") ? '0.9rem' : '1rem'
                  }}}
                  label={capitalize(item.label)}  
                  
                  checked={(value) ? (value===item.value) : (item.default) ? true : false}
                  
                  />)}
   
              </RadioGroup>
              </FormControl>
              )}
      />
      <NukFormError field={name} errors={errors} />
      <GraphqlFormError errors={gqlErrors} filters={[name]}/>
    </Fragment>

    const skeleton = <Skeleton animation="wave" height={100}/>

    
    return ((!loading) ? component : skeleton)
}