import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { Box, Chip, Typography } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-pro";

/**
 * Users list detail column
 * (Used for xsBreakPoint to provide a better mobile experience)
 * ? This should become obsolete once MUI implements a default collapse in MuiDataGridProd
 * @param t 
 * @returns 
 */
export const userListDetailColumn = (t: Function) => {
  return{
    field: 'roles',
    headerName: t('details',{ns:'general'}),
    sortable: false,
    renderCell: (params: GridRowParams) => {
      return (
        <Box sx={{display:'flex',flexDirection:'column',pl:1}}>
          <Typography fontWeight="bold">
            {params.row.firstName} {params.row.lastName} 
            {!params.row.enabled && <DoDisturbOnIcon color="warning" style={{position:'relative',top:5}}/>}
          </Typography>
          <Typography>
          {params.row.email} 
          </Typography>
          <Typography>
          {params.row.username} 
          </Typography>
          <Box sx={{width:'100%'}}>
         {params.row.roles 
            && params.row.roles.map((role: any) =>  <Chip key={role.id.toString()} label={role.title} color="primary" sx={{mr:0.5}}/>)
          }
          </Box>

        </Box>
        )
    }
  }
}
    
export const userListDetailColumnHeight = 120