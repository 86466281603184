import { Visibility, VisibilityOff } from "@mui/icons-material";
import { capitalize, IconButton, InputAdornment, Skeleton, TextField } from "@mui/material";
import { FC, Fragment, useContext, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { GraphqlFormError, hasGraphqlErrorSlim } from "./graphl-form-error";
import { NukFormContext } from './nuk-form-context';
import { NukFormError } from "./nuk-form-error";

type Props = {
    name: string
    id?: string
    label: string
    rules?: any
}

/**
 * Mui Password Field Component wrapped in a React Hook Form Controller for maximum compatibility.
 * Adds a password show/hide adornment on the text field.
 * id: if not specified, is the same as name
 * @param props 
 * @returns 
 */
export const NukFormPasswordField : FC<Props> = (
  {
    name,
    id,
    label,
    rules,
  }) => {
  const { control,formState: { errors }} = useFormContext()
  const { loading,gqlErrors } = useContext(NukFormContext)
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
    if(id==null) id = name

    const component = 
    <Fragment>
      <Controller
              name={name}
              control={control}
              defaultValue=""
              rules={rules}
              render={({ field: { onChange, value } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  id={id}
                  label={capitalize(label)}
                  type={showPassword ? "text" : "password"}
                  value={value}
                  onChange={onChange}
                  error={(errors.hasOwnProperty(name) || hasGraphqlErrorSlim(gqlErrors,[name])) ? true : false}
                  InputProps={{ 
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
      />
      <NukFormError field={name} errors={errors} />
      <GraphqlFormError errors={gqlErrors} filters={[name]}/>
    </Fragment>

    const skeleton = <Skeleton animation="wave" height={100}/>

    return ((!loading) ? component : skeleton)
}