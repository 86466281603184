import { Save } from "@mui/icons-material";
import { Box } from "@mui/material";
import { FC, useContext } from "react";
import nextId from "react-id-generator";
import { NukFloatButton } from "../buttons/nuk-float-button";
import { NukFloatLoadingButton } from "../buttons/nuk-float-loading-button";
import { NukFloatMenuButton } from "../buttons/nuk-float-menu-button";
import { NukFormAppBarAction } from "./nuk-form-app-bar";
import { NukFormContext } from "./nuk-form-context";

type Props = {
        actions : NukFormAppBarAction[];
}
export const NukFormFloatAppBar : FC<Props> = ({
    actions
}) =>{
    const { loading,submitted } = useContext(NukFormContext)
    return(
        <Box sx={{position:'absolute',zIndex:'500',bottom:10,right:20,display:'flex'}}>
        {actions.map((action) => {
             return (action.items !== undefined) ? 
                        <NukFloatMenuButton 
                          key={`${nextId()}-nuk-form-app-bar-float-menu-button`}
                          items={action.items} 
                          sx={{mr:1}}
                          icon={action.icon}
                          skeleton={loading}
                        /> 
                    :   <NukFloatButton 
                          key={`${nextId()}-bt-nuk-form-app-bar-button`} 
                          icon={action.icon} 
                          onClick={(action.onClick !== undefined) ? action.onClick : null}
                          sx={{mr:1}}
                          skeleton={loading}
                        />
            })}
             <NukFloatLoadingButton icon={<Save/>} type="submit" skeleton={loading} sx={{ml:1}} loading={submitted} />
         </Box>
    )
}