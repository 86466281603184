import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { NukPrintContainer } from '../../../core/nuk-components';
import { DefaultPrintHeader } from '../../../modules/prints/components/default-print-header';
import QRCode from "react-qr-code";




export const CremationCollectionStickerPrint = React.forwardRef((props, ref) => {
  return (
    <Box ref={ref}>
      <NukPrintContainer paper="A4">
      <Grid container sx={{mt:2,p:1,border:'2px dashed rgba(0,0,0,0.2)'}}>
        <Grid item xs={5}>
          <QRCode value={`tc-crematorium-xxx`}/>                                     
        </Grid>
        <Grid item xs={7}>
        <img style={{ width: '150px' }} src="/static/images/brand/brand-color.svg" />

          <Typography variant="h6" >
          NOME COGNOME
          </Typography>
          <Typography variant="h6" sx={{color:'red'}}>
          ALTRI DATI DA DEFINIRE
          </Typography>
          <Typography variant="h6" sx={{color:'red'}}>
          DIMENSIONE DA DEFINIRE
          </Typography>
        </Grid>
      </Grid>

      </NukPrintContainer>
    </Box>
  );
});

