import { Avatar, Box, capitalize, Skeleton, Typography } from "@mui/material"
import { FC } from "react"
import { MuiColorsEnum } from "../../mui/nuk-mui-default-colors.type"
import { textTransform } from '@mui/system';


type Props = {
    label: string,
    icon: any,
    skeleton?: boolean
    color?: MuiColorsEnum 
    slim?: boolean
    textTransform?: "none" | "uppercase"
}

export const NukIconTitle : FC<Props> = ({
    icon,
    label,
    skeleton = false,
    slim = false,
    color = 'primary',
    textTransform = "none"
    }) => {

    return((!skeleton) ? 
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
          fontWeight: 'bold',
      }}>
      <Avatar sx={{marginRight:1,bgcolor:`${color}.main`,color:`${color}.contrastText`}}>
          {icon}
      </Avatar>
      {(!slim) &&
        <Box sx={{ whiteSpace:'nowrap',textOverflow: 'ellipsis',overflow:'hidden',textTransform: textTransform }}> 
        {capitalize(label)}
        </Box>
      }
      </Box>
    :   <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{ margin: 1 }}>
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
    </Box>
    <Box sx={{ width: '100%' }}>
        <Skeleton width={400}>
          <Typography>.</Typography>
        </Skeleton>
    </Box>
  </Box>)
}