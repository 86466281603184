import {
  capitalize,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NukResourceListContext } from "../../../../../core/components/lists/nuk-list-context";
import { CremationStatusesMultiSelectChip } from "../../selects/cremation-statuses-multi-select-chip";
import { CompanyMultiSelect } from "../../selects/company-multi-select";
import { userHasRole } from "../../../../../modules/auth/utils/auth-user-role-checker";
import { useAppSelector } from "../../../../../features/hooks";

/**
 * Cremations List Filters
 * @returns
 */
export const CremationsListFilters: FC = () => {
  //Resource List Context variables
  const {
    loading,
    page,
    pageSize,
    refetch,
    setFiltersLoading,
    setFiltersCount,
    clearFilters,
    setClearFilters,
  } = useContext(NukResourceListContext);

  //General values
  const currentYear = new Date().getFullYear()

  const authUser = useAppSelector((state) => state.user.value)

  //Theme hooks
  const theme = useTheme();
  const xsBreakPoint = useMediaQuery(theme.breakpoints.down("sm"));
  


  //Filter States
  const [filterQ, setFilterQ] = useState<string>("");
  const [filterStatuses, setFilterStatuses] = useState<string[]>([]);
  const [filterYear,setFilterYear] = useState<string>(currentYear.toString())
  const [filterCompanies,setFilterCompanies] = useState<number[]>([]);
  //Translation
  const { t } = useTranslation();


  //Year filter & options setup
  const startYear = 2022
  const getFilterableYears = () => {
    const years = []
    for(let y=startYear;y<=currentYear;y++){
      years.push(y)
    }
    return years
  }
  /**
   * Users list filter counter function
   * Counts the active filters and displays the number on a badge
   * (xsBreakPoint only)
   */
  const filterCounter = () => {
    let partialCount = 0;
    if (filterQ && filterQ.length) partialCount++;
    if (filterStatuses && filterStatuses.length) partialCount++;
    if (filterCompanies && filterCompanies.length) partialCount++;
    if (setFiltersCount) setFiltersCount(partialCount);
  };

  /**
   * Clear Filters
   * Resets the filter to the given default values
   */
  const filterClear = () => {
    setFilterQ("");
    setFilterYear(currentYear.toString())
    setFilterCompanies([]);
    setFilterStatuses([]);
  };

  /**
   * User Filter Effect Hook
   * Triggered everytime a filter changes with a 500ms delay to avoid to many requests.
   */
  useEffect(
    () => {
      filterCounter();
      if (!loading) {
        if (setFiltersLoading) setFiltersLoading(true);
        const timeOutId = setTimeout(() => {
          if (setFiltersLoading) setFiltersLoading(false);
          refetch({
            input: {
              pagination: { page: page, limit: pageSize },
              filters: {
                q: filterQ,
                statuses: filterStatuses,
                alfCompanyIds: filterCompanies,
                year: (Number(filterYear)!=-1) ? Number(filterYear) : null
              },
            },
          });
        }, 500);
        return () => clearTimeout(timeOutId);
      }
    },
    //Filters to be watched by the hook
    [filterQ, filterStatuses,filterYear,filterCompanies]
  );

  /**
   * Clear Filters Hook
   */
  useEffect(() => {
    if (clearFilters) {
      filterClear();
      if (setClearFilters) setClearFilters(false);
    }
  }, [clearFilters]);

  const companySelectVisible = () => {
    return userHasRole(authUser,["MASTER","MODERATOR"])
  }

  return (
    <Fragment>
      <Grid container spacing={1} rowGap={xsBreakPoint ? 2 : 0}>
        <Grid item xs={(companySelectVisible()) ? 3 : 6}>
          <TextField
            fullWidth
            size="small"
            label={`${capitalize(t("search", { ns: "general" }))}...`}
            value={filterQ}
            onChange={(e) => setFilterQ(e.target.value)}
          />
        </Grid>

        <Grid item xs={4}>
          <CremationStatusesMultiSelectChip
            statuses={filterStatuses}
            setStatuses={setFilterStatuses}
            size="small"
          />
        </Grid>
        {companySelectVisible() ?
        <Grid item xs={3}>
          <CompanyMultiSelect
            statuses={filterCompanies}
            setStatuses={setFilterCompanies}
            size="small"
          />
        </Grid> : null}
        <Grid item xs={2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">Anno</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              value={filterYear}
              label="Age"
              onChange={(e: SelectChangeEvent) => {
                setFilterYear(e.target.value as string)
              }}
            >
              {
                getFilterableYears().map((y: number) => <MenuItem value={y} key={`year-filter-${y}`}>{y}</MenuItem>)
              }
              <MenuItem value={-1} key={`year-filter-none`}>Tutti</MenuItem>
            
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
};
