import { Box, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";



export const NukFormContainer : FC = ({children}) => {
    const theme = useTheme();
    const smBreakPoint = useMediaQuery(theme.breakpoints.down('md'));

    return(
        <Box sx={{
            mt: 12,
            marginBottom:(smBreakPoint) ? 9 : 5,
            padding:2
        }}>
            {children}
        </Box>

    )
} 