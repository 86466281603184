import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { INukFormHookEffectParams } from '../../../../../../core/hooks/nuk-form-hooks.interfaces';
import { UpdateApplicationKeyMutation, useUpdateApplicationKeyMutation } from '../../../../../../graphql/nuk-graphql-main';
import { applicationKeysResource as resourceConfig } from '../../../../application-keys.resource';
import { applicationKeyEditorFormUpdateSchemaType } from "../application-key-editor-form.schema";
/**
 * ApplicationKey Update - Hook Wrapper
 * @param params 
 * @returns 
 */
export  const useApplicationKeyFormEditorUpdate = () =>{
    return useUpdateApplicationKeyMutation();
}

/**
 * ApplicationKey Update - Executes the update function
 * @param service 
 * @param values 
 */
export const executeApplicationKeyFormEditorUpdate = (service: Function,id: number,values: applicationKeyEditorFormUpdateSchemaType) => {
    //Additional validation can be done here.
    //Launching service
    service({
        variables: {
            id: id,
            input: {
                name: values.name,
            }
        }
    })
}


/**
 * ApplicationKey Update - Post Effect
 * @param params 
 */
export  const useApplicationKeyFormEditorUpdateEffect = (params: EffectParams) => {
    const { data,error,setGqlError,setResourceLabel } = params
    useEffect(() => {
        if(data){
            //Clearing previous gql errors
            setGqlError(null)
            //Setting resource label
            setResourceLabel(`${data.updateApplicationKey.name}`)
            //Toasting success
            toast.success(`${resourceConfig.nameSingular} ${data.updateApplicationKey.name} updated.`)
        } else if(error){
            //Setting Gql Errors
            setGqlError(error);
        }
    },[data,error])
}

//User update - Interfaces
interface EffectParams extends INukFormHookEffectParams {data: UpdateApplicationKeyMutation | null | undefined}