import { FC, Fragment, useContext } from "react"
import { NukFormAutoCompleteTextField } from "../../../../core/components/forms/nuk-form-autocomplete-text-field"
import swissCities from '../../../../data/cities/CH_ti_gr.json'
import { NukFormDialogContext } from "../../../../core/nuk-components"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

 type Props = {}

 export const CremationZipCodeField : FC<Props> = ({}) => {
    const { t } = useTranslation()
    const { setValue } = useFormContext()

 return(
    <NukFormAutoCompleteTextField 
    name="zipCode" 
    label={t('zipCode', { ns: 'general' })}
    dataSet={swissCities}
    valueKey="zip"
    filterKeys={['zip']}
    renderOption={(option: any) => (
            <div>
              <b>{option?.zip}</b> &nbsp; {option?.cityMain}
            </div>                
        )
    }
    onChange={(option : any) => {
      //Updates the City field 
      if(option != null && option.hasOwnProperty('cityMain')){
        setValue('city',option.cityMain)
      }
    }}

  />

 )

}