import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { DataGridActionsBuilder } from "../../../../../core/nuk-utils";
import { rolesResource as resourceConfig } from '../../../roles.resource';
import { roleListDetailColumn } from './columns/roles-list-detail.column';

/**
 * Roles list default columns (smBreakPoint and up)
 * @param t 
 * @returns 
 */
export const getColumns = (t: Function) => {
  const ns = {ns: resourceConfig.translationNamespace}
  return [
    { field: 'id', headerName: 'ID', width: 70 },
    { 
      field: 'title', 
      headerName: t('name',{ns: 'general'}), 
      flex: 1,
      renderCell : (params: GridRenderCellParams<string>) => (<strong>{params.value}</strong>)
    },
    { 
      field: 'description', 
      headerName: t('description',{ns: 'general'}), 
      flex: 1,
    },
    { 
      field: 'value', 
      headerName: t('value',{ns: 'general'}), 
      flex: 1,
    },
    //Actions
    DataGridActionsBuilder.buildDefaultResourceActions(resourceConfig.slug)
  ];

}


/**
 * Roles list slim columns (xsBreakPoint down)
 * @param t 
 * @returns 
 */
export const getSlimColumns =(t: Function) => {
  return [roleListDetailColumn(t)]
}

