import { Alert, AlertColor, Skeleton } from "@mui/material";
import { FC, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { NukFormContext } from './nuk-form-context';

type Props = {
    severity?: AlertColor
}

export const NukFormAlert : FC<Props> = ({
    severity = "info",
    children
}) => {
    const { control,formState: { errors }} = useFormContext()
    const { loading } = useContext(NukFormContext)
    
    const component = 
    <Alert severity={severity} sx={{width:'100%'}}>
        {children}
    </Alert>
    
    const skeleton = <Skeleton animation="wave" height={40} />
    
    return((!loading) ? component : skeleton)
}