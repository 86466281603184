

import { Box, Card, CardContent, Collapse, Grid, Typography } from "@mui/material"
import { FC, Fragment, useContext } from "react"
import { NukFormDateTimeField } from "../../../../../../core/nuk-components"
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { NukLabel } from "../../../../../../core/components/labels/nuk-label";

type Props = {
    active: boolean,
    label: string,
    icon: JSX.Element,
    alert: string,
    
}

export const CremationEditorFormBlockableEvent: FC<Props> = ({
    active = false,
    label,
    icon,
    children,
    alert,
}) => {
    return (
        <Card sx={{ background: (theme) => (!active) ? theme.palette.grey[200] : undefined }}>
        <CardContent>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item md={12}>
              <NukLabel label={label} icon={icon}></NukLabel>
            </Grid>
            <Grid item xs={12} md={12}>
            {!active &&
                <Typography variant="subtitle1" fontWeight={'bold'} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems:'center',
                    color: (theme) => theme.palette.grey[700]
                }}>
                    <DoDisturbIcon />
                    {alert}
                </Typography>

            }


            <Box sx={{
                display: (active) ? 'block' : 'none'
            }}>
         
                    {children}
  
            </Box>
            </Grid>

          </Grid>
        </CardContent>
      </Card>
 
    
   
    )
}