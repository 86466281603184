import { FC } from "react";
import Chart from 'react-apexcharts'


export const ExampleHeatChart : FC = () =>{
  const  generateData = (count: any, yrange: any) => {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = (i + 1).toString();
      var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
      series.push({
        x: x,
        y: y
      });
      i++;
    }
    return series;
  }

    return(
      <Chart options={{
        chart:{
         toolbar:{
              show:false
            }
          },
            dataLabels: {
              enabled: false
            },
            colors: ["#008FFB"],
     
          }        
      } 
      
      series={[{
        name: 'Gennaio',
        data: generateData(31, {
          min: 0,
          max: 3
        })
      },
      {
        name: 'Febbraio',
        data: generateData(31, {
          min: 0,
          max: 3
        })
      },
      {
        name: 'Marzo',
        data: generateData(31, {
          min: 0,
          max: 3
        })
      },
      {
        name: 'Aprile',
        data: generateData(31, {
          min: 0,
          max: 3
        })
      },
      {
        name: 'Maggio',
        data: generateData(31, {
          min: 0,
          max: 3
        })
      },
      {
        name: 'Giugno',
        data: generateData(31, {
          min: 0,
          max: 3
        })
      },
      {
        name: 'Luglio',
        data: generateData(31, {
          min: 0,
          max: 3
        })
      },
      {
        name: 'Agosto',
        data: generateData(31, {
          min: 0,
          max: 3
        })
      },
      {
        name: 'Settembre',
        data: generateData(31, {
          min: 0,
          max: 3
        })
      },
      {
        name: 'Ottobre',
        data: generateData(31, {
          min: 0,
          max: 3
        })
      },
      {
        name: 'Novembre',
        data: generateData(31, {
          min: 0,
          max: 3
        })
      },
      {
        name: 'Dicembre',
        data: generateData(31, {
          min: 0,
          max: 3
        })
      }
      ]} type="heatmap" height={450} />
    )
}