

import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab"
import { Avatar, Box, capitalize, Card, CardContent, styled, Typography } from "@mui/material"
import { FC, Fragment, useContext } from "react"
import { CremationStatus, CremationStatusColor } from "../../constants/cremation-status.enum"
import { CremationStatusIcon } from "../icons/cremation-status.icon"
import { useTranslation } from 'react-i18next';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { it } from 'date-fns/locale'
import { uniqueId } from "lodash"
import TodayIcon from '@mui/icons-material/Today';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import nextId from 'react-id-generator';
import { formatInTimeZone } from 'date-fns-tz'

const oppositeSx = {
    py: 2
}
const contentSx = {
    py: 2.2
}

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
        marginRight: 5
    }
}))

type Props = {
    status: CremationStatus,
    time: Date | null,
    detailItems?: [JSX.Element],
    last?: boolean,
    title?: string,
    forceOneHourLess? : boolean

}

export const CremationEventsTimelineItemSlim: FC<Props> = ({
    status,
    time,
    detailItems = [],
    last = false,
    title,
    forceOneHourLess = false
}) => {
    const { t } = useTranslation()
    return (
        time ?
            <Fragment>
                <Card sx={{ mb: 2, mt: 2 }}>
                    <CardContent>
                        <Typography variant="h6" component="span" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2 }}>
                            <Avatar sx={{ bgcolor: CremationStatusColor[status], mr: 1 }}>
                                <CremationStatusIcon status={status} />

                            </Avatar>
                            {!title ?
                                capitalize(t(`cremationStatus${status}`, { ns: 'resourceCremation' }))
                                : title}
                        </Typography>
                        <StyledBox >
                            <TodayIcon />
                            {capitalize(format(new Date(time), 'EEEE dd LLLL yyyy', { locale: it }))}
                        </StyledBox>
                        <StyledBox>
                            <AccessTimeIcon />
                            {forceOneHourLess ? 
                                    capitalize(formatInTimeZone(time, 'Europe/London', 'HH:mm',{locale: it}) )
                                    :
                                    capitalize(format(new Date(time), 'H:mm', { locale: it }))
                                }
                        </StyledBox>
                        {detailItems.map(item => <StyledBox key={nextId()}>
                            {item}
                        </StyledBox>)}

                    </CardContent>
                </Card>
                {!last &&
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <Box sx={{ width: 4, height: 60, background: (theme) => theme.palette.grey[300] }}></Box>
                    </Box>
                }
            </Fragment>
            : null)
}