import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NukListResourceAppBar, NukListResourceDataGrid, NukResourceListContext } from '../../../../../core/nuk-components';
import { ListUsersQuery, useListUsersQuery } from '../../../../../graphql/nuk-graphql-main';
import { usersResource as resourceConfig } from '../../../users.resource';
import { userListDetailColumnHeight } from './columns/users-list-detail.column';
import { getColumns, getSlimColumns } from './users-list.columns';
import { UserListDetailDialog } from './users-list.detail-dialog';
import { UsersListFilters } from './users-list.filters';


/**
 * User List Component
 * @param props 
 * @returns 
 */
export const UsersList: FC = () => {
  const [rowCount, setRowCount] = useState<number>(0);
  const [filtersLoading, setFiltersLoading] = useState<boolean>(false)
  const [filtersCount, setFiltersCount] = useState<number>(0)
  const [clearFilters, setClearFilters] = useState<boolean>(false)
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false)
  const [detailId, setDetailId] = useState<number | null>(null)
  const { translationNamespace: trns } = resourceConfig
  const { t } = useTranslation()
  const columns = getColumns(t)
  const slimColumns = getSlimColumns(t)

  //Main Gql Query
  const { loading, data, refetch } = useListUsersQuery({
    variables: {
      input: {
        pagination: {
          page: page,
          limit: pageSize
        }
      }
    }, fetchPolicy: 'cache-and-network',
  });

  //This functions gets the user items directly - used by the data grid to display data.
  const getItems = (data: ListUsersQuery) => { return data.users.items }

  return (
    <NukResourceListContext.Provider value={{
      data: data,
      meta: data?.users.meta,
      columns: columns,
      slimColumns: slimColumns,
      slimRowHeight: userListDetailColumnHeight,
      loading: loading,
      trns: trns,
      resourceConfig: resourceConfig,
      page: page,
      setPage: setPage,
      pageSize: pageSize,
      setPageSize: setPageSize,
      rowCount: rowCount,
      setRowCount: setRowCount,
      filtersLoading: filtersLoading,
      setFiltersLoading: setFiltersLoading,
      clearFilters: clearFilters,
      setClearFilters: setClearFilters,
      filtersCount: filtersCount,
      setFiltersCount: setFiltersCount,
      detailDialogOpen: detailDialogOpen,
      setDetailDialogOpen: setDetailDialogOpen,
      detailId: detailId,
      setDetailId: setDetailId,
      refetch: refetch,
      getItems: getItems
    }}>
      <NukListResourceAppBar loading={loading}>

        <UsersListFilters />
      </NukListResourceAppBar>
      <NukListResourceDataGrid detailDialog={<UserListDetailDialog />} />



    </NukResourceListContext.Provider>
  );
}