import axios from "axios";
import { AuthUser } from "../types/auth-user.type";
import { Service, ServiceResponse,ServiceParams } from "../../../core/service/service.class";

const prefix = '/auth'

export class AuthService{
    static login(params: ServiceParams) {
        Service.post(`${prefix}/login`,params,(response : ServiceResponse) => {
            if(response.success)
              params.onSuccess(response.data as AuthUser)
            else{
              switch(response.status){
                case 401:
                  params.onError(AuthServiceError.LOGIN_FAILED)
                  break
                default:
                  params.onError(AuthServiceError.LOGIN_SERVICE_UNAIVALABLE)
              }
            }
        })
    }

    static logout(params: ServiceParams){
      Service.post(`${prefix}/logout/session`,params,(response : ServiceResponse) => {
        if(response.success)
          params.onSuccess(true)
        else{
          params.onError(AuthServiceError.LOGIN_SERVICE_UNAIVALABLE)
        }
      })
   }

   /**
    * Refreshes the current user token
    * @param params 
    */
   static async refreshToken(params: ServiceParams){
    Service.post(`${prefix}/token/refresh`,params,(response : ServiceResponse) => {
      if(response.success){
        params.onSuccess(response.data)
      }
      else{
        params.onError(AuthServiceError.TOKEN_REFRESH_FAILED)
      }
    })
 }
   


}

export enum AuthServiceError {
  LOGIN_FAILED = 'LOGIN_FAILED',
  LOGIN_SERVICE_UNAIVALABLE = 'LOGIN_SERVICE_UNAVAILABLE',
  TOKEN_REFRESH_FAILED = 'TOKEN_REFRESH_FAILED'
}
//Data Types
type loginData = {
    email: string,
    password: string,
}