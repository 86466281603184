import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
import { NukFormContainer, NukFormContext } from '../../../../../core/nuk-components';
import { useListCompaniesQuery, useListRolesQuery } from '../../../../../graphql/nuk-graphql-main';
import { usersResource as resourceConfig } from '../../../users.resource';
import { UserRemoveDialog } from '../../dialogs/user-remove.dialog';
import { executeUserFormEditorCreate, useUserFormEditorCreate, useUserFormEditorCreateEffect } from './hooks/user-form-editor-create.hooks';
import { useUserFormEditorGet, useUserFormEditorGetEffect } from './hooks/user-form-editor-get.hooks';
import { executeUserFormEditorUpdate, useUserFormEditorUpdate, useUserFormEditorUpdateEffect } from './hooks/user-form-editor-update.hooks';
import { UserEditorFormAppBar } from './user-editor.form.app-bar';
import { UserEditorFormContent } from './user-editor.form.content';
import { userEditorFormCreateSchema, userEditorFormCreateSchemaType, userEditorFormUpdateSchema, userEditorFormUpdateSchemaType } from './user-editor.form.schema';

/**
 * User Editor Form
 * All the hooks related to CRUD operations are inside this component.
 * @returns 
 */
export const UserEditorForm:FC = () => {
  
    const { id } = useParams();
    const mode = (id) ? 'update' : 'create'
    const methods = useForm({ mode: 'onSubmit' ,reValidateMode:'onSubmit',resolver: 
        yupResolver((mode==="create") ? userEditorFormCreateSchema : userEditorFormUpdateSchema)});
    const { setValue, handleSubmit,formState:{isSubmitting}} = methods
    const navigate = useNavigate()
    const [resourceLabel, setResourceLabel] = useState("")
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [gqlErrors, setGqlErrors] = useState<ApolloError | null | undefined>(null);
    const trns = 'resourceUser'

  
    const hookDefaults = {
      setGqlError: setGqlErrors,
      setResourceLabel: setResourceLabel,
      setValue: setValue,
      navigate: navigate,
      mode: mode,
    }

    useEffect(() =>{
      setGqlErrors(null)
    },[isSubmitting])



    //TODO: AFTER ROLES ARE IMPLEMENTED
    const { data: rolesData } = useListRolesQuery({
      variables: { input: {} },
    });

    const { data: companiesData } = useListCompaniesQuery({variables:{input:{}}})
  
 

    //Form Hooks
    const { loading: resourceLoading, data: resourceData, error: resourceError } = useUserFormEditorGet({ id: Number(id) })
    const [createResource, { data: createdResourceData, loading: createResourceLoading, error: createResourceError }] = useUserFormEditorCreate();
    const [updateResource, { data: updatedResourceData, loading: updateResourceLoading, error: updateResourceError }] = useUserFormEditorUpdate()
  
    //Form Effect Hooks
    useUserFormEditorGetEffect({data: resourceData,loading: resourceLoading,error: resourceError,...hookDefaults})
    useUserFormEditorCreateEffect({ data: createdResourceData, loading: createResourceLoading, error: createResourceError, ...hookDefaults })
    useUserFormEditorUpdateEffect({ data: updatedResourceData, loading: updateResourceLoading, error: updateResourceError, ...hookDefaults })

  
    //Form submission
    const onSubmit = (data : userEditorFormCreateSchemaType | userEditorFormUpdateSchemaType | any) => {
      
      if (mode === 'create') {
        executeUserFormEditorCreate(createResource,data)
      }
      else if (mode === 'update') {
        executeUserFormEditorUpdate(updateResource, Number(id), data)
      }
    };


  
    return(
        <NukFormContext.Provider value={
            {
              loading: resourceLoading,
              submitted: (createResourceLoading || updateResourceLoading),
              deleteDialogOpen: deleteDialogOpen,
              resourceConfig: resourceConfig,
              gqlErrors: gqlErrors,
              mode: (id) ? 'update' : 'create',
              trns: trns,
            }
          }>
            <FormProvider {...methods}  >
              <Box component="form" onSubmit={handleSubmit(data => onSubmit(data))}>
                <NukFormContainer>
                <UserEditorFormAppBar setDeleteDialogOpen={setDeleteDialogOpen} resourceData={resourceData} resourceLabel={resourceLabel}/>
                  <UserEditorFormContent 
                  resourceData={resourceData} 
                  rolesData={rolesData}
                  companiesData={companiesData}/>
                  { (mode==='update' && resourceData) &&
                    //Remove Dialog
                    <UserRemoveDialog 
                    open={deleteDialogOpen} 
                    setOpen={setDeleteDialogOpen} 
                    label={`${resourceData.user.firstName} ${resourceData.user.lastName}`} 
                    mode={'static'} 
                    resourceId={resourceData.user.id} 
                    onCompleted={() => {
                      navigate(resourceConfig.indexPath)
                    }}
                />
                  }
                 </NukFormContainer>
              </Box>
              
        
            </FormProvider>
          </NukFormContext.Provider>
    )
}