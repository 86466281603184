import { ApolloError } from '@apollo/client';
import CategoryIcon from '@mui/icons-material/Category';
import * as React from 'react';
import { FieldValues, UseFormWatch } from 'react-hook-form';
import { MuiColorsEnum } from '../../mui/nuk-mui-default-colors.type';
import { INukResource } from '../../resources/nuk-resource.interface';

//TODO: Rename in editor context
export const NukFormContext = React.createContext<NukFormContextInterface>({
    loading: false,
    submitted: false,
    mode:'create',
    deleteDialogOpen: false,
    gqlErrors: null,
    resourceConfig: {
        slug: "resource",
        nameSingular: "Resource",
        namePlural: "Resources",
        icon: <CategoryIcon/>,
        indexPath: '/resources',
        translationNamespace: 'resourceResource',
        routes: [],
        color: MuiColorsEnum.primary,
    },
    trns: "",
})


export interface NukFormContextInterface{
    loading: boolean
    submitted: boolean
    mode: "create" | "update";
    gqlErrors: ApolloError | null | undefined,
    deleteDialogOpen: boolean,
    resourceConfig: INukResource
    trns: string
    
}