import { AddAlarm, Close as CloseIcon, Edit as EditIcon, ThreeMp } from '@mui/icons-material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC, Fragment, useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import nextId from "react-id-generator";
import { RouteGenerator } from "../../nuk-utils";
import { NukButton } from "../buttons/nuk-button";
import { NukMenuButton, NukMenuButtonItem } from "../buttons/nuk-menu-button";
import { NukResourceListContext } from "../lists/nuk-list-context";
import { NukHistoryRecap } from "../NukHistoryRecap";
import { NukIconTitle } from "../titles/nuk-icon-title";
import { NukTransitionSlideUp } from "../transitions/nuk-transition-slide-up";
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import { NukLoadingButton } from '../buttons/nuk-loading-button';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';



type Props = {
    loading: boolean
    label: string,
    fetch: Function,
    actions?: NukMenuButtonItem[],
    quickActions?: QuickAction[],
    quickActionsDisabled?: boolean,
    quickActionsDisabledMessage?: string, 
    showEditButton?: boolean,
    showActions?: boolean,
    showQuickActions?: boolean,
    resource?: any,
} 


type QuickAction = {
    icon: JSX.Element
    label: string
    onClick: Function
    dividerBefore? : boolean
    dividerAfter? : boolean
    buttonType?: 'default' | 'loading',
    loadingText?: string,
    loading?: boolean
}



  

export const NukResourceListDetailModal : FC<Props> = ({
    label = "",
    loading = false,
    showActions = true,
    showEditButton = true,
    showQuickActions = true,
    quickActionsDisabled = false,
    quickActionsDisabledMessage = "",
    children,
    actions = [],
    quickActions = [],
    fetch,
    resource,
}) =>{
    const {t} = useTranslation()
    const theme = useTheme()
    const xsBreakPoint = useMediaQuery(theme.breakpoints.down('sm'))
    const smBreakPoint = useMediaQuery(theme.breakpoints.down('md'))
    const { detailDialogOpen, setDetailDialogOpen, detailId, resourceConfig } = useContext(NukResourceListContext)

    useEffect(() => {
        if (detailDialogOpen && detailId) {
            fetch({ variables: { id: detailId } })
        }
    }, [detailId, setDetailDialogOpen])




    return(
        <Dialog
        open={detailDialogOpen}
        onClose={() => (setDetailDialogOpen) ? setDetailDialogOpen(false) : null}
        maxWidth="xl"
        fullWidth={true}
        TransitionComponent={NukTransitionSlideUp}
        scroll="body"
        >

        <DialogTitle
            sx={{
                backgroundColor: (theme) =>  theme.palette.grey[100],
            }}
        >
            <Grid container>
          

                <Grid item xs={2} sm={8} >
                    <NukIconTitle icon={resourceConfig.icon} label={(!xsBreakPoint) ? label : ""} skeleton={loading} color={resourceConfig.color}/>
                </Grid>
                <Grid item sm={4}  xs={10} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {showActions &&
                <NukMenuButton 
                               key={`${nextId()}-nuk-resource-list-modal-menu-button`}
                               label={"Asd"} 
                               icon={<MoreHorizIcon/>} 
                               items={actions} 
                               sx={{mr:1}}
                               slim={true}
                               skeleton={loading}
                             /> 
            }
           {showEditButton &&
                <NukButton 
                        icon={<EditIcon></EditIcon>} 
                        color="primary"
                        label={t('edit', { ns: 'general' })} 
                        href={(detailId) ? RouteGenerator.EDIT(resourceConfig.slug, detailId) : undefined}
                        skeleton={loading}
                        slim={smBreakPoint}
                        />
           }
      
                <IconButton aria-label="close" 
                color="inherit"
                onClick={() => (setDetailDialogOpen) ? setDetailDialogOpen(false) : null}
                sx={{ml:2}}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>

                </Grid>

      
            </Grid>
        </DialogTitle>

            { (showQuickActions && quickActions.length > 0) &&
            <DialogTitle sx={{backgroundColor:(theme) => theme.palette.grey[200]}}>
                <Grid container>
                    <Grid item xs={2} sm={2} md={3}>
                        <Typography variant="subtitle1">
                            {!loading ? 
                            <SmartButtonIcon/>
                            : <Skeleton variant="circular" width={30} height={30}/>
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sm={10} md={9} sx={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                    {!quickActionsDisabled ?
                        quickActions.map((quickAction) => {
                            if(quickAction.buttonType==='loading'){
                                return <NukLoadingButton 
                                            key={`${nextId()}-nuk-resource-list-quick-action-button`}
                                            label={quickAction.label} 
                                            icon={quickAction.icon} 
                                            onClick={() => {quickAction.onClick()}} 
                                            slim={xsBreakPoint}
                                            loading={quickAction.loading}
                                            skeleton={loading}
                                            loadingText={quickAction.loadingText}
                                            sx={{mr:2}}
                                            size="large"
                                            color="error"
                                        />

                            } else{
                                return <NukButton 
                                            key={`${nextId()}-nuk-resource-list-quick-action-button`}
                                            label={quickAction.label} 
                                            icon={quickAction.icon} 
                                            skeleton={loading}
                                            onClick={() => {quickAction.onClick()}} 
                                            slim={xsBreakPoint}
                                            sx={{mr:2}}
                                            size="large"

                                            />
                            }
                        })
                    :
                                      <Typography variant="subtitle1" fontWeight={'bold'} sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems:'center',
                    color: (theme) => theme.palette.grey[700]
                }}>
                    <DoDisturbIcon sx={{mr:1}}/>
                    {quickActionsDisabledMessage}    
                </Typography>
                    }
                          
                    </Grid>
                </Grid>
            </DialogTitle>
            }
 

            <DialogContent sx={{ mt: (theme) => theme.spacing(3), mb: (theme) => theme.spacing(3), color: 'black' }}>
                {!loading ? 
                    children
                : <Fragment>
                    <Skeleton height={30}></Skeleton>
                    <Skeleton height={30}></Skeleton>
                    <Skeleton height={30}></Skeleton>
                    <Skeleton height={30}></Skeleton>
                </Fragment>}

            </DialogContent>
            
            {resource && 
            <DialogContent sx={{backgroundColor: (theme) =>  theme.palette.grey[100],pt:0}}>
                <NukHistoryRecap resource={resource} loading={loading}></NukHistoryRecap>
            </DialogContent>
            }
    </Dialog>
    )
}