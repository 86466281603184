

import { Box, Button, capitalize, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { FC, Fragment } from 'react';
import { NukStructure } from "../../../core/nuk-components";
import { useAppSelector } from '../../../features/hooks';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ExampleWeeklyRequestsChart } from '../charts/example-weekly-requests.chart';
import { ExampleWeeklyCremationsChart } from '../charts/exampe-weekly-cremations.chart';
import { ExampleWeeklyOccupationChart } from '../charts/example-weekly-occupation.chart';
import { ExampleHeatChart } from '../charts/exampe-heat.chart';
import { ExampleComparisonChart } from '../charts/exampe-comparison.chart';


/**
 * Example Page
 * @param props 
 * @returns 
 */
export const StatsPage: FC = (props) => {
  const authUser = useAppSelector((state) => state.user.value)
  const { t,i18n } = useTranslation()


  return (
    <NukStructure>
      <Box sx={{ p: 2 }}>
   
      <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item xs={12} md={12}>
            <Grid container rowSpacing={2} columnSpacing={2}>
         
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle1">Nuove richieste ricevute</Typography>
                    <ExampleWeeklyRequestsChart/>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
            <CardContent>
                <Typography variant="subtitle1">Cremazioni schedulate</Typography>
                  <ExampleWeeklyCremationsChart/>
              </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1">Occupazione forno</Typography>
                  <ExampleWeeklyOccupationChart/>
              </CardContent>
            </Card>

            </Grid>
            <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1">Utilizzo Giornaliero    </Typography>
                  <ExampleHeatChart/>
              </CardContent>
            </Card>

            </Grid>
            <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1">Totale Cremazioni    </Typography>
                  <ExampleComparisonChart/>
              </CardContent>
            </Card>

            </Grid>
            </Grid>
          </Grid>
        </Grid>
     
      </Box>
    </NukStructure>
  )
}