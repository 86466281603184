import { LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { FC } from "react"
import { useGetDailyArrivalsQuery } from "../../graphql/nuk-graphql-main"
import { format } from "date-fns"
function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
  ) {
    return { name, calories, fat, carbs, protein };
  }

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];


export const DailyArrivals : FC = () => {
    const {loading,data,error} = useGetDailyArrivalsQuery()
    return(<div>
        <h5>Consegne giornaliere</h5>
        {loading &&
            <LinearProgress/>
        }

        { (data && data.getDailyArrivals.length>0) &&  
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Orario</TableCell>
            <TableCell >Onoranza</TableCell>
            <TableCell >Defunto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.getDailyArrivals.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
           
              <TableCell component={"th"} scope="row"> {format(new Date(row.estimatedDeliveryAt),'HH:mm')}</TableCell>
              <TableCell >{(row.company) ? row.company.name : "" }</TableCell>
              <TableCell><b>{row.lastName} {row.firstName}</b></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
     } 
     {
        (data && data.getDailyArrivals.length<=0) &&
        
        <h4 style={{width:'100%',textAlign:'center'}}>Nessuna consegna registrata per la giornata.</h4>
        
     }
    </div>)
}