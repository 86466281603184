import { useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { ToastContainer } from "react-toastify";


  
export const NukToastContainer :FC = () => {
    const theme = useTheme();
    const xsBreakPoint = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <ToastContainer
        style={{minWidth:'40%',marginTop:(xsBreakPoint) ? 0 : 60}}
        position={(xsBreakPoint) ? 'bottom-center' : 'top-center'}
        theme="colored"
        
        />
    )
}

