import { Avatar, Tooltip } from "@mui/material";
import React, { FC } from "react";
import { NukStringColorizer } from '../../utils/nuk-string-colorizer.class';
type Props = {
    name: string,
    size?: string,
    style?: any, 
}

export const NukUserApplicationAvatarColored: FC<Props> = ({
    name,
}) => {
    const initials = `${name.charAt(0)}${name.charAt(1)}`.toUpperCase()
   return(
       <Tooltip title={name}>
        <Avatar sx={{ bgcolor: NukStringColorizer.getColor(name) }} >
            {initials}
        </Avatar>
       </Tooltip>
   )

} 