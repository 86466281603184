import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
import { NukFormContainer, NukFormContext } from '../../../../../core/nuk-components';
import { cremationsResource as resourceConfig } from '../../../cremations.resource';
import { CremationRemoveDialog } from '../../dialogs/cremation-remove.dialog';
import { executeCremationFormEditorCreate, useCremationFormEditorCreate, useCremationFormEditorCreateEffect } from './hooks/cremation-editor-form-create.hook';
import { useCremationFormEditorGet, useCremationFormEditorGetEffect } from './hooks/cremation-editor-form-get.hook';
import { executeCremationFormEditorUpdate, useCremationFormEditorUpdate, useCremationFormEditorUpdateEffect, } from './hooks/cremation-editor-form-update.hook';
import { CremationEditorFormAppBar } from './cremation-editor-form.app-bar';
import { CremationEditorFormContent } from './cremation-editor-form.content';
import { cremationEditorFormCreateSchema, cremationEditorFormCreateSchemaType, cremationEditorFormUpdateSchema, cremationEditorFormUpdateSchemaType } from './cremation-editor-form.schema';
import { Package } from '../cremation-request-medical/cremation-request-form-medical.dialog';
import { executeMedicalCremationFormEditorUpdate, useCremationFormEditorUpdateMedical, useCremationMedicalFormEditorUpdateEffect } from './hooks/cremation-editor-form-medical-update.hook';

/**
 * Cremation Editor Form
 * All the hooks related to CRUD operations are inside this component.
 * @returns 
 */
export const CremationEditorForm:FC = () => {
  
    const { id } = useParams();
    const mode = (id) ? 'update' : 'create'
    const methods = useForm({ mode: 'onSubmit' ,reValidateMode:'onSubmit',resolver: 
        yupResolver((mode==="create") ? cremationEditorFormCreateSchema : cremationEditorFormUpdateSchema)});
  const { setValue, handleSubmit,formState:{isSubmitting},watch } = methods
    const navigate = useNavigate()
    const [resourceLabel, setResourceLabel] = useState("")
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [gqlErrors, setGqlErrors] = useState<ApolloError | null | undefined>(null);

  
    const hookDefaults = {
      setGqlError: setGqlErrors,
      setResourceLabel: setResourceLabel,
      setValue: setValue,
      navigate: navigate,
      mode: mode,
    }

    useEffect(() =>{
      setGqlErrors(null)
    },[isSubmitting])

 
    //Form Hooks
    const { loading: resourceLoading, data: resourceData, error: resourceError } = useCremationFormEditorGet({ id: Number(id) })
    const [createResource, { data: createdResourceData, loading: createResourceLoading, error: createResourceError }] = useCremationFormEditorCreate();
    const [updateResource, { data: updatedResourceData, loading: updateResourceLoading, error: updateResourceError }] = useCremationFormEditorUpdate()
    const [updateMedicalResource, { data: updatedMedicalResourceData, loading: updateMedicalResourceLoading, error: updatedMedicalResourceError }] = useCremationFormEditorUpdateMedical()
  
    //Form Effect Hooks
    useCremationFormEditorGetEffect({data: resourceData,loading: resourceLoading,error: resourceError,...hookDefaults})
    useCremationFormEditorCreateEffect({ data: createdResourceData, loading: createResourceLoading, error: createResourceError, ...hookDefaults })
    useCremationFormEditorUpdateEffect({ data: updatedResourceData, loading: updateResourceLoading, error: updateResourceError, ...hookDefaults })
    useCremationMedicalFormEditorUpdateEffect({ data: updatedMedicalResourceData, loading: updateMedicalResourceLoading, error: updatedMedicalResourceError, ...hookDefaults })

  
    //Form submission
    const onSubmit = (data : cremationEditorFormCreateSchemaType | cremationEditorFormUpdateSchemaType | any) => {
      if (mode === 'create') {
        executeCremationFormEditorCreate(createResource,data)
      }
      else if (mode === 'update') {
        if(resourceData?.cremation.type==='MEDICAL_REMAINS'){
          executeMedicalCremationFormEditorUpdate(updateMedicalResource,Number(id),data,packages)
        } else{
          executeCremationFormEditorUpdate(updateResource, Number(id), data)
        }
      }
    };

    //Packets
    const [packages, setPackages] = useState<Package[]>([]);
  
    const addPackage = () => {
      setPackages((current) => [...current, {
        total:1,
        contentId:1,
        originId:1
      }]);
    };
  
    const updatePackage = (index: number, updateData: any) => {
      setPackages((current) =>
        current.map((p: Package, i: number) => {
          if (i == index) {
            p = { ...p, ...updateData };
          }
          return p;
        })
      );
    };
  
    const removePackage = (index: number) => {
      setPackages((current) => [
        ...current.slice(0, index),
        ...current.slice(index + 1),
      ]);
    };

    useEffect(() => {
      if(resourceData){
        //@ts-ignore
        setPackages(resourceData.cremation.packets?.map((p) => {
          return {
            contentId: p.content.id,
            originId: p.origin.id,
            total: p.total
          }
        }))
      }
    },[resourceData])
  
    return(
        <NukFormContext.Provider value={
            {
              loading: resourceLoading,
              submitted: (createResourceLoading || updateResourceLoading),
              deleteDialogOpen: deleteDialogOpen,
              resourceConfig: resourceConfig,
              gqlErrors: gqlErrors,
              mode: (id) ? 'update' : 'create',
              trns: resourceConfig.translationNamespace,
            }
          }>
            <FormProvider {...methods}  >
              <Box component="form" onSubmit={handleSubmit(data => onSubmit(data))}>
                <NukFormContainer>
                <CremationEditorFormAppBar setDeleteDialogOpen={setDeleteDialogOpen} resourceData={resourceData} resourceLabel={resourceLabel}/>
                  <CremationEditorFormContent resourceData={resourceData} watch={watch}
                                packages={packages}
                                addPackage={addPackage}
                                updatePackage={updatePackage}
                                removePackage={removePackage}
                  />
                  { (mode==='update' && resourceData) &&
                    //Remove Dialog
                    <CremationRemoveDialog 
                    open={deleteDialogOpen} 
                    setOpen={setDeleteDialogOpen} 
                    label={`${resourceData.cremation.firstName} ${resourceData.cremation.lastName}`} 
                    mode={'static'} 
                    resourceId={resourceData.cremation.id} 
                    onCompleted={() => {
                      navigate(resourceConfig.indexPath)
                    }}
                />
                  }
                 </NukFormContainer>
              </Box>
              
        
            </FormProvider>
          </NukFormContext.Provider>
    )
}