import * as Yup from 'yup'
import { nukYupI18nextLocale } from '../../../../../core/nuk-core'

//Enabling localization
Yup.setLocale(nukYupI18nextLocale)

/**
 * ApplicationKey editor form create validation schema
 */
export const applicationKeyEditorFormCreateSchema = Yup.object().shape({
  name: Yup.string().required(),
})

/**
 * ApplicationKey editor form update validation schema
 */
export const applicationKeyEditorFormUpdateSchema = Yup.object().shape({
  name: Yup.string().required(),
})
export type applicationKeyEditorFormCreateSchemaType = Yup.InferType<typeof applicationKeyEditorFormCreateSchema>
export type applicationKeyEditorFormUpdateSchemaType = Yup.InferType<typeof applicationKeyEditorFormUpdateSchema>