import { capitalize, Card, CardContent, Grid, LinearProgress } from '@mui/material';
import { FC, Fragment, useContext } from "react";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NukFormAlert, NukFormCheckboxCards, NukFormContext, NukFormLabel, NukFormPasswordField, NukFormSwitchField, NukFormTextField } from "../../../../../core/nuk-components";
import { GetUserQuery, ListCompaniesQuery, ListRolesQuery, Role } from '../../../../../graphql/nuk-graphql-main';

type Props = {
  companiesData: ListCompaniesQuery | null | undefined
  rolesData: ListRolesQuery | null | undefined
  resourceData: GetUserQuery | null | undefined
}
/**
 * User editor form content 
 * All the fields are inside this component
 * @param param0 
 * @returns 
 */
export const UserEditorFormContent: FC<Props> = ({ rolesData, resourceData,companiesData }) => {
  const { mode, trns } = useContext(NukFormContext)
  const { watch } = useFormContext()
  const rolesFormValues = watch('roles')
  const companiesFormValues = watch('companies')
  const { t } = useTranslation()
  return (

    <Fragment>
      <Card >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <NukFormLabel title={t('personalData', { ns: trns })} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <NukFormTextField
                name="firstName"
                label={t('firstName', { ns: trns })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NukFormTextField
                name="lastName"
                label={t('lastName', { ns: trns })}
              />
            </Grid>
          </Grid>


          <Grid container spacing={2}>
            <Grid item>
              <NukFormLabel title={t('emailAndUsernameLabel', { ns: trns })} />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <NukFormTextField
                name="email"
                label="Email"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NukFormTextField
                name="username"
                label="Username"
              />
            </Grid>
          </Grid>


          {(mode === "create") &&
            <Fragment>
              <Grid container spacing={2}>
                <Grid item>
                  <NukFormLabel title="Password" />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <NukFormAlert>
                    {capitalize(t('passwordNoticeMessage', { ns: trns }))}
                  </NukFormAlert>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <NukFormPasswordField
                    name="password"
                    label="Password"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <NukFormPasswordField
                    name="rePassword"
                    label={t('rePassword', { ns: trns })}
                  />
                </Grid>
              </Grid>
            </Fragment>
          }

          <Grid container spacing={2}>
            <Grid item md={12}>
              <NukFormLabel title={t('general:status')} />
            </Grid>
          </Grid>


          <Grid container spacing={2}>
            <Grid item md={12}>
              <NukFormSwitchField name="enabled" label={t('isEnabledLabel', { ns: trns })} />

            </Grid>

          </Grid>


          <Grid container spacing={2}>
            <Grid item>
              <NukFormLabel title={t('general:roles')} />
            </Grid>
          </Grid>


          <Grid container spacing={2}>
            <Grid item md={12}>
              {(rolesData && (rolesFormValues || mode === 'create')) &&
                <NukFormCheckboxCards
                  name="roles"
                  items={rolesData.roles.items.map((role: any) => {
                    return {
                      label: role.title,
                      description: role.description,
                      value: role.id.toString(),
                      checked: (mode === 'update') ? (rolesFormValues.includes(role.id)) : false
                    }
                  })}
                />
              }
            </Grid>




          </Grid>

          <Grid container spacing={2} sx={{mt:2}}>
            <Grid item>
              <NukFormLabel title={'Onoranze'} />
            </Grid>
          </Grid>

      


          <Grid container spacing={2}>
            <Grid item md={12}>
              {companiesData ?
                <NukFormCheckboxCards
                  name="companies"
                  items={companiesData.companies.items.map((company: any) => {
                    return {
                      label: company.name,
                      description: company.city,
                      value: company.id.toString(),
                      checked: (mode === 'update') ? (companiesFormValues.includes(company.id)) : false
                    }
                  })}
                />
                : <LinearProgress/>
              }
            </Grid>




          </Grid>



        </CardContent>
      </Card>
    </Fragment>
  )
}