import { FC } from "react";
import Chart from 'react-apexcharts'


export const ExampleComparisonChart : FC = () =>{
  

    return(
      <Chart options={{
        chart:{
         toolbar:{
              show:false
            }
          },
            dataLabels: {
              enabled: false
            },
            colors: ["#009688",'#E91E63','#008FFB'],
            xaxis: {
              categories: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug','Ago','Set','Ott','Nov','Dic'],
            },
          }        
      } 
      
      series={[{
        name: '2021',
        data: [11,2,13,5,12,30,11,2,22,6,29]
      }, {
        name: '2022',
        data: [26,31,12,22,29,18,25,6,28,15,12]
      },
      {
        name: '2023',
        data: [31,2,30,29,25,29,12,5,8,12,35]
      }
    ]} type="area" height={450} />
    )
}