import { FC } from 'react';
import { NukStructure } from "../../../core/nuk-components";
import { ApplicationKeyEditorForm } from '../components/forms/application-key-editor/application-key-editor-form';
/**
 * User Editor Page
 * @param props 
 * @returns 
 */
export const ApplicationKeysEditorPage: FC = (props) => {
  return (
    <NukStructure>
        <ApplicationKeyEditorForm/>
    </NukStructure>
  )
}