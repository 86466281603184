import { ApolloError } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Card,
  CardContent,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { NukFormDialog } from "../../../../../core/components/dialogs/nuk-form.dialog";
import {
  NukFormDialogContext,
  NukIconTitle,
} from "../../../../../core/nuk-components";
import { CremationRequestFormMedicalCompleted } from "./components/cremation-request-form-medical.completed";
//import { CremationRequestFormContent } from "./cremation-request-form.content"
import { CremationRequestFormMedicalContent } from "./cremation-request-form-medical.content";
import {
  cremationRequestFormMedicalSchema,
  cremationRequestFormMedicalSchemaType,
} from "./cremation-request-form-medical.schema";
import {
  executeCremationRequestFormMedicalCreate,
  useCremationRequestFormMedicalCreate,
  useCremationRequestFormMedicalCreateEffect,
} from "./hooks/cremation-request-form-medical-create.hook";

type Props = {
  open: boolean;
  setOpen: Function;
  refetch: Function;
};
export type Package = {
  total?: number;
  contentId?: number;
  originId?: number;
};
export const CremationRequestFormMedicalDialog: FC<Props> = ({
  open,
  setOpen,
  refetch,
}) => {
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(cremationRequestFormMedicalSchema),
  });

  const [gqlErrors, setGqlErrors] = useState<ApolloError | null | undefined>(
    null
  );
  const [completed, setCompleted] = useState<boolean>(false);

  const hookDefaults = {
    setGqlError: setGqlErrors,
    setValue: methods.setValue,
  };

  //Form Hooks
  const [
    createResource,
    {
      data: createdResourceData,
      loading: createResourceLoading,
      error: createResourceError,
    },
  ] = useCremationRequestFormMedicalCreate();

  //Form Effect Hooks
  useCremationRequestFormMedicalCreateEffect({
    data: createdResourceData,
    loading: createResourceLoading,
    error: createResourceError,
    refetch: refetch,
    ...hookDefaults,
  });

  //Form submission
  const onSubmit = (data: cremationRequestFormMedicalSchemaType) => {
    executeCremationRequestFormMedicalCreate(createResource, data,packages);
  };

  useEffect(() => {
    if (open == false && createdResourceData) {
      setCompleted(false);
    }
  }, [open]);

  useEffect(() => {
    if (createdResourceData) {
      setCompleted(true);
      methods.reset();
    }
  }, [createdResourceData]);

  const [packages, setPackages] = useState<Package[]>([{
    total:1,
    contentId:1,
    originId:1
  }]);

  const addPackage = () => {
    setPackages((current) => [...current, {
      total:1,
      contentId:1,
      originId:1
    }]);
  };

  const updatePackage = (index: number, updateData: any) => {
    setPackages((current) =>
      current.map((p: Package, i: number) => {
        if (i == index) {
          p = { ...p, ...updateData };
        }
        return p;
      })
    );
  };

  const removePackage = (index: number) => {
    setPackages((current) => [
      ...current.slice(0, index),
      ...current.slice(index + 1),
    ]);
  };

  return (
    <NukFormDialogContext.Provider
      value={{
        loading: false,
        open: open,
        submitted: createResourceLoading,
        gqlErrors: gqlErrors,
        formMethods: methods,
        completed: completed,
        onSubmit: onSubmit,
        setOpen: setOpen,
      }}
    >
      <FormProvider {...methods}>
        <NukFormDialog
          title="Nuova richiesta di cremazione"
          completedViewComponent={
            <CremationRequestFormMedicalCompleted data={createdResourceData} />
          }
          size="lg"
        >
          <CremationRequestFormMedicalContent
            packages={packages}
            addPackage={addPackage}
            updatePackage={updatePackage}
            removePackage={removePackage}
          />
        </NukFormDialog>
      </FormProvider>
    </NukFormDialogContext.Provider>
  );
};
