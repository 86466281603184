
   
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n.use(Backend).use(initReactI18next).init({
  fallbackLng: 'it',
  debug: false,
  ns:[
    'errors',
    'countries',
    'general',
    'labels',
    'muiDataGrid',
    'validation',
    'resources',
    //Resource Related Translations
    'resourceUser',
    'resourceRole',
    'resourceApplicationKey',
    'resourceCremation'

  ],
  defaultNS:'general',
  interpolation: {
    escapeValue: false
  }
  
})

export default i18n