import { useEffect } from 'react';
import { toast } from "react-toastify";
import { INukFormHookEffectParams } from "../../../../../../core/nuk-core";
import { CreateRoleMutation, useCreateRoleMutation } from '../../../../../../graphql/nuk-graphql-main';
import { rolesResource as resourceConfig } from '../../../../roles.resource';
import { roleEditorFormCreateSchemaType } from "../role-editor-form.schema";

/**
 * Role Create - Hook Wrapper
 * @param params 
 * @returns 
 */
export  const useRoleFormEditorCreate = () =>{
    return useCreateRoleMutation();
}

/**
 * Role Create - Executes the create function
 * @param service 
 * @param values 
 */
export const executeRoleFormEditorCreate = (service: Function,values: roleEditorFormCreateSchemaType) => {
    //Additional validation can be done here.
    //Launching service
    service({
        variables: {
            input: {
                title: values.title,
                value: values.value,
                description: values.description,
            }
        }
    })
}

/**
 * Role Create - Post Effect
 * @param params 
 */
export  const useRoleFormEditorCreateEffect = (params: EffectParams) => {
    const { data,error,setGqlError,navigate } = params
    useEffect(() => {
        if(data){
            //Toasting success
            toast.success(`${resourceConfig.nameSingular} ${data.createRole.title} Created!`)
            //Navigating back to list
            navigate(resourceConfig.indexPath)
        } else if(error){
            //Setting Gql Errors
            setGqlError(error);
        }
    },[data,error])
}

//User Create - Interfaces
interface EffectParams extends INukFormHookEffectParams {data: CreateRoleMutation | null | undefined}
