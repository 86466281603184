import { onError } from "@apollo/client/link/error";
import { capitalize } from '@mui/material';
import { toast } from "react-toastify";
import i18n from "../../../i18n";
import { logout, refreshTokens } from "../../../modules/auth/redux/auth-user.slice";
import { AuthService } from "../../../modules/auth/services/auth.service";
import { store } from "../../../store";

export const errorLink = onError(({ graphQLErrors, networkError,operation,forward }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations,extensions, path }) =>{
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
        if(message==="Unauthorized"){
          AuthService.refreshToken({
            data: {},
            onSuccess: (data : any) => {
                //Token refreshed successfully, dispatching token update
                store.dispatch(refreshTokens(data.tokensExpirationDates))
            },
            onError: () => {
                //Refresh operation failed - this may be due to a disabled refresh token, or disabled user
                store.dispatch(logout())
            }
        })
        }
        //TODO: Filter only some kind of errors.
        else if(
          message!=='Bad Request Exception' 
          && !message.includes('VALIDATION_FAILED')
          && extensions.code!=='BAD_USER_INPUT'
          ){
          toast.error(capitalize(i18n.t('gqlError',{ns:'general'})))
        }
 

  
      }
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });
  