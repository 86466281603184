import { from, HttpLink } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { authLink } from "./apollo-auth.link";
import { errorLink } from "./apollo-error.link";


export const additiveLink = from([
  authLink,
  errorLink,
  new RetryLink(),
  new HttpLink({  
      uri: process.env.REACT_APP_GRAPQHL_ENDPOINT,
      credentials: 'include', })
  ]);
  