import { useEffect } from "react";
import { INukFormHookEffectParams, INukFormHookGetParams } from "../../../../../../core/nuk-core";
import { GetRoleQuery, GetRoleQueryResult, useGetRoleQuery } from "../../../../../../graphql/nuk-graphql-main";

/**
 * Role Get - Fetch Hook
 * @param INukFormHookGetParams 
 * @returns GetUserQueryResult
 */
export  const useRoleFormEditorGet = (params: INukFormHookGetParams) : GetRoleQueryResult => {
    const { id } = params
    return useGetRoleQuery({
        variables:{id:id},
        skip:(isNaN(id) || !id)
    });

}

/**
 * Role Get - Post Effect
 * @param EffectParams 
 */
export const useRoleFormEditorGetEffect = (params: EffectParams) => {
    const { data,setValue } = params
    useEffect(() => {
        if(data){
        //Compiling form values
        setValue('title', data.role.title)
        setValue('value', data.role.value)
        setValue('description', data.role.description)
        }
    },[data])
}

//User Get - Interfaces
interface EffectParams extends INukFormHookEffectParams {data: GetRoleQuery | null | undefined}
