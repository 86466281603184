import { Close } from "@mui/icons-material";
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FC } from "react";
import { NukFormLabel } from "../../../../../../core/nuk-components";
import { ListContentsQuery, ListOriginsQuery } from "../../../../../../graphql/nuk-graphql-main";
import { Package } from "../cremation-request-form-medical.dialog";

type Props = {
    data: Package
    index: number,
    update: Function,
    remove: Function,
    totalValues: number[],
    originValues: ListOriginsQuery,
    contentValues: ListContentsQuery
};

export const PackageContentEntry: FC<Props> = ({data,index,update,remove,totalValues,originValues,contentValues}) => {
  return (
    <Card sx={{mb:2}}>
      <CardContent>
        <Grid container>
          <Grid item sm={12} md={2} sx={{ p: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="medical-nr-packages-label">Colli</InputLabel>
              <Select
                labelId="medical-nr-packages-label"
                id="medical-nr-packages"
                value={(data.total) ? data.total : totalValues[0]}
                label="Colli"
                size="small"
                fullWidth
                onChange={(e) => {update(index,{total:e.target.value})}}
              >
                
                {totalValues.map((v,i) =>     <MenuItem key={`package-count-key-${i}`} value={v}>{v}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={4} sx={{ p: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="medical-type-label">Tipo</InputLabel>
              <Select
                labelId="medical-type-label"
                id="medical-type"
                value={(data.contentId) ? data.contentId : contentValues.contents.items[0].id}
                label="Tipo"
                size="small"
                fullWidth
                onChange={(e) => {update(index,{contentId:e.target.value})}}
              >
                {contentValues.contents.items.map((c,i) =>     <MenuItem key={`package-content-item-${c.id}`} value={c.id}>{c.name}</MenuItem>)}

              </Select>
            </FormControl>{" "}
          </Grid>
          <Grid item sm={12} md={5} sx={{ p: 1 }}>
            <FormControl fullWidth>
   
              <InputLabel id="medical-origin-label">Origine</InputLabel>
              <Select
                labelId="medical-origin-label"
                id="medical-origin"
                value={(data.originId) ? data.originId : originValues.origins.items[0].id}
                label="Origine"
                size="small"
                fullWidth
                onChange={(e) => {update(index,{originId:e.target.value})}}
              >
                {originValues.origins.items.map((o : any) => 
                <MenuItem key={`package-origin-item-${o.id}`}value={o.id}>{o.name} </MenuItem>
                )}

              </Select>
            </FormControl>{" "}
          </Grid>
          <Grid item sm={12} md={1} sx={{ p: 1, display:'flex',justifyContent:'flex-end'}}>
            {index!=0 &&
          <IconButton aria-label="delete" size="small" onClick={() => {remove(index)}}>
  <Close fontSize="small" />
</IconButton>
}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
