import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { NukFormDialog } from "../../../../../core/components/dialogs/nuk-form.dialog";
import {
  NukListResourceAppBar,
  NukListResourceDataGrid,
  NukResourceListContext,
} from "../../../../../core/nuk-components";
import { useAppSelector } from "../../../../../features/hooks";
import {
  ListCremationsQuery,
  useListCremationsQuery,
} from "../../../../../graphql/nuk-graphql-main";
import { userHasRole } from "../../../../../modules/auth/utils/auth-user-role-checker";
import { cremationsResource as resourceConfig } from "../../../cremations.resource";
import { CremationTypeChoiceDialog } from "../../dialogs/cremation-type-choice.dialog";
import { CremationRequestFormMedicalDialog } from "../../forms/cremation-request-medical/cremation-request-form-medical.dialog";
import { CremationRequestFormDialog } from "../../forms/cremation-request/cremation-request-form.dialog";
import { cremationListDetailColumnHeight } from "./columns/cremations-list-detail.column";
import { getColumns, getSlimColumns } from "./cremations-list.columns";
import { CremationListDetailDialog } from "./cremations-list.detail-dialog";
import { CremationsListFilters } from "./cremations-list.filters";

/**
 * Cremations List Component
 * @param props
 * @returns
 */
export const CremationsList: FC = () => {
  const [rowCount, setRowCount] = useState<number>(0);
  const [filtersLoading, setFiltersLoading] = useState<boolean>(false);
  const [filtersCount, setFiltersCount] = useState<number>(0);
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const authUser = useAppSelector((state) => state.user.value)
  const currentYear = new Date().getFullYear()

  const [pageSize, setPageSize] = useState(20);
  const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<number | null>(null);
  const { translationNamespace: trns } = resourceConfig;
  const { t } = useTranslation();
  const columns = getColumns(t);
  const slimColumns = getSlimColumns(t);
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [createMedicalDialogOpen, setCreateMedicalDialogOpen] =
    useState<boolean>(false);
  const [cremationTypeChoiceDialogOpen, setCremationTypeChoiceDialogOpen] =
    useState<boolean>(false);

  //Main Gql Query
  const { loading, data, refetch } = useListCremationsQuery({
    variables: {
      input: {
        pagination: {
          page: page,
          limit: pageSize,
        },
        filters:{
          year: currentYear
        }
      },
    },
    fetchPolicy: "cache-and-network",
  });

  //This functions gets the user items directly - used by the data grid to display data.
  const getItems = (data: ListCremationsQuery) => {
    return data.cremations.items;
  };

  return (
    <NukResourceListContext.Provider
      value={{
        data: data,
        meta: data?.cremations.meta,
        columns: columns,
        slimColumns: slimColumns,
        slimRowHeight: cremationListDetailColumnHeight,
        loading: loading,
        trns: trns,
        resourceConfig: resourceConfig,
        page: page,
        setPage: setPage,
        pageSize: pageSize,
        setPageSize: setPageSize,
        rowCount: rowCount,
        setRowCount: setRowCount,
        filtersLoading: filtersLoading,
        setFiltersLoading: setFiltersLoading,
        clearFilters: clearFilters,
        setClearFilters: setClearFilters,
        filtersCount: filtersCount,
        setFiltersCount: setFiltersCount,
        detailDialogOpen: detailDialogOpen,
        setDetailDialogOpen: setDetailDialogOpen,
        detailId: detailId,
        setDetailId: setDetailId,
        refetch: refetch,
        getItems: getItems,
      }}
    >
      <NukListResourceAppBar
        loading={loading}
        createButtonLabel="Richiesta nuova cremazione"
        createButtonOnClick={() => {
          //setCreateDialogOpen(true)
          //setCreateMedicalDialogOpen(true)
          //Master and moderator see the default 
          if(userHasRole(authUser,["MASTER","MODERATOR"])){
            setCremationTypeChoiceDialogOpen(true);
          }
          else if(userHasRole(authUser,["COMPANY_AGENT"])){
            if(authUser.companies.length>0 && authUser.companies.some(c => c.id === 20)){
              setCreateMedicalDialogOpen(true)
            }
            else{
              setCreateDialogOpen(true)
            }
          }

        }}
      >
        <CremationsListFilters />
      </NukListResourceAppBar>
      <NukListResourceDataGrid detailDialog={<CremationListDetailDialog />} />
      {userHasRole(authUser,["MASTER","MODERATOR"]) &&
      <CremationTypeChoiceDialog
        open={cremationTypeChoiceDialogOpen}
        setOpen={setCremationTypeChoiceDialogOpen}
        setCreateDialogOpen={setCreateDialogOpen}
        setCreateMedicalDialogOpen={setCreateMedicalDialogOpen}
      />
      }
      <CremationRequestFormDialog
        open={createDialogOpen}
        setOpen={setCreateDialogOpen}
        refetch={refetch}
      />
      <CremationRequestFormMedicalDialog
        open={createMedicalDialogOpen}
        setOpen={setCreateMedicalDialogOpen}
        refetch={refetch}
      />
    </NukResourceListContext.Provider>
  );
};
