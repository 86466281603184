
import * as Yup from 'yup'
import { nukYupI18nextLocale } from '../../../../../core/nuk-core'

//Enabling localization
Yup.setLocale(nukYupI18nextLocale)

/**
 * User editor form create validation schema
 */
export const userEditorFormCreateSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().required().email(),
  username: Yup.string().required(),
  password: Yup.string()
    .required()
    .min(8),
  rePassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password')],"custom.repeatPassword"),
  roles: Yup.array().default([]),
  companies: Yup.array().default([]),
})

/**
 * User editor form update validation schema
 */
export const userEditorFormUpdateSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().required().email(),
  username: Yup.string().required(),
  roles: Yup.array().default([]),
  companies: Yup.array().default([]),
})
export type userEditorFormCreateSchemaType = Yup.InferType<typeof userEditorFormCreateSchema>
export type userEditorFormUpdateSchemaType = Yup.InferType<typeof userEditorFormUpdateSchema>