import { Avatar, capitalize, Card, CardContent, CardHeader, Typography } from "@mui/material"
import { FC } from "react"
import { MuiColors, MuiColorsEnum } from "../../mui/nuk-mui-default-colors.type"

type Props = {
    title: string,
    icon: JSX.Element,
    iconBackground?: string,
    sx?: any,
}

export const NukIconCard: FC<Props> = ({
    title,
    icon,
    iconBackground = "primary.main",
    children,
    sx = {}
}) => {
    return (
        <Card sx={sx}>
            <CardHeader
                
                avatar={
                    <Avatar sx={{ bgcolor: iconBackground }} aria-label="recipe">
                        {icon}
                    </Avatar>
                }
                title={<Typography variant="h6">{capitalize(title)}</Typography>}
            />
            <CardContent>
                {children}

            </CardContent>
        </Card>
    )
}