import { Chip, Tooltip, Typography } from "@mui/material"
import { FC, Fragment } from "react"
import { useTranslation } from "react-i18next"
import { MuiColors } from "../../mui/nuk-mui-default-colors.type"
import { NukLabel } from "./nuk-label"

type Props = {
    label: string,
    items: NukChipListLabelItem[],
    color?:  MuiColors
}

type NukChipListLabelItem = {
    label: string,
    description: string,
    key: string,
}

export const NukChipListLabel : FC<Props> = ({
    label,
    items = [],
    color = "primary",
}) =>{
    const {t} = useTranslation()
    return(
        <Fragment>
            <NukLabel label={label}/>
            <Typography variant="h5" >
                {items.length > 0 ?
                items.map(item => 
                    <Tooltip title={item.description} key={item.key}>
                    <Chip color={color} label={item.label} sx={{mr:1,fontSize:20}}/>
                    </Tooltip>
                    )
                    : 
                    <Typography fontSize={26} textTransform="capitalize">
                     {t("none",{ns:'general'})}
                    </Typography>
                   
                }
            </Typography>
        </Fragment>
    )
}