import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { INukFormHookEffectParams } from '../../../../../../core/hooks/nuk-form-hooks.interfaces';
import { UpdateUserMutation, useUpdateUserMutation } from '../../../../../../graphql/nuk-graphql-main';
import { usersResource as resourceConfig } from '../../../../users.resource';
import { userEditorFormUpdateSchemaType } from '../user-editor.form.schema';
/**
 * User Update - Hook Wrapper
 * @param params 
 * @returns 
 */
export  const useUserFormEditorUpdate = () =>{
    return useUpdateUserMutation();
}

/**
 * User Update - Executes the update function
 * @param service 
 * @param values 
 */
export const executeUserFormEditorUpdate = (service: Function,id: number,values: userEditorFormUpdateSchemaType) => {
    //Additional validation can be done here.
    //Launching service
    service({
        variables: {
            id: id,
            input: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                username: values.username,
                enabled: values.enabled,
                roles: values.roles.map((role) => ({id:Number(role)})),
                companies: values.companies.map((companyId) => Number(companyId))
            }
        }
    })
}


/**
 * User Update - Post Effect
 * @param params 
 */
export  const useUserFormEditorUpdateEffect = (params: EffectParams) => {
    const { data,error,setGqlError,setResourceLabel } = params
    useEffect(() => {
        if(data){
            //Clearing previous gql errors
            setGqlError(null)
            //Setting resource label
            setResourceLabel(`${data.updateUser.firstName} ${data.updateUser.lastName}`)
            //Toasting success
            toast.success(`${resourceConfig.nameSingular} ${data.updateUser.firstName} ${data.updateUser.lastName} updated.`)
        } else if(error){
            //Setting Gql Errors
            setGqlError(error);
        }
    },[data,error])
}

//User update - Interfaces
interface EffectParams extends INukFormHookEffectParams {data: UpdateUserMutation | null | undefined}