import { Paper, useMediaQuery, useTheme } from "@mui/material";
import { GridDensityTypes, GridRowParams } from "@mui/x-data-grid-pro";
import { TypeMetaFieldDef } from "graphql";
import { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NukResourceListDataGrid } from '../../../themes/nukleus/styles/components/data-grid/nuk-resource-list-data-grid.styled';
import { NukMuiDataGridTranslator } from "../../mui/data-grid/nuk-mui-data-grid-translator";
import { NukResourceListContext } from "./nuk-list-context";

type Props = {
    detailDialog?: JSX.Element 
}

export const NukListResourceDataGrid : FC<Props> = ({detailDialog = null}) =>{
    const {
        data,
        meta,
        page,
        setPage,
        pageSize,
        setPageSize,
        rowCount,
        setRowCount,
        refetch,
        loading,
        columns,
        slimColumns,
        slimRowHeight,
        getItems,
        setDetailDialogOpen,
        setDetailId} = useContext(NukResourceListContext)
    const theme = useTheme()
    const xsBreakPoint = useMediaQuery(theme.breakpoints.down('sm'))
    const {t} = useTranslation()


     //Refetch on page change
    useEffect(() => {
        if (!loading) {
        refetch({
            input: {
            pagination: { page: page, limit: pageSize }
            }
        })
        }
    }, [page])

    //Switching to page 1 everytime the pagesize changes
    useEffect(() => {if(setPage){setPage(1)}}, [pageSize])

    //Row count update on data change
    useEffect(() => {
        if (data && setRowCount && meta && meta.totalItems) {
            setRowCount(meta.totalItems)
        }
    }, [data])

    const dialogOpener = (params: GridRowParams) => {
        if(setDetailId) setDetailId(Number(params.id))
        if(setDetailDialogOpen) setDetailDialogOpen(true)
    }

    return(
        <Paper sx={{marginLeft:0,flex:1}}> 
        <NukResourceListDataGrid 
          localeText={NukMuiDataGridTranslator.get(t)}
          rows={(data) ? getItems(data) : []}
          rowCount={rowCount}
          columns={(!xsBreakPoint) ? columns.map((column) => ({
            ...column,
            sortable:false,
            
          })) : slimColumns.map((column) => ({...column,sortable:false}))
        
        }
          onRowClick={(params: GridRowParams) => (setDetailDialogOpen) ? dialogOpener(params) : null }
          disableColumnMenu
          disableColumnResize={xsBreakPoint}
          rowHeight={(xsBreakPoint) ? slimRowHeight : 50}
          loading={loading}
          pageSize={pageSize}
          rowsPerPageOptions={[5,10,20,50,100]}
          initialState={{ pinnedColumns: { left: [], right: ['actions'] }}}
          paginationMode="server"
          pagination
          onPageChange={(page)=> (setPage) ? setPage((page+1)) : null} 
          onPageSizeChange={(pageSize) => (setPageSize) ? setPageSize(pageSize) : null }
          density={GridDensityTypes.Comfortable}

        />
        {detailDialog}
        </Paper>
    )
    
}