import { Card, CardContent, Grid, Skeleton } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NukHistoryRecapCard } from "./NukHistoryRecapCard";


type Props = {
    resource: any,
    loading?: boolean,
}

export const NukHistoryRecap: FC<Props> = ({
    resource,
    loading = false
}) => {
    const { t } = useTranslation()
    return (
        !loading ?
            <Grid container spacing={2} mt={1}>
                {resource.createdByUser &&
                    <Grid item xs={12} sm={6}>
                        <NukHistoryRecapCard
                            label={t('creation', { ns: 'general' })}
                            firstName={resource.createdByUser.firstName}
                            lastName={resource.createdByUser.lastName}
                            date={resource.createdAt} />
                    </Grid>
                }
                {resource.createdByApplication &&
                    <Grid item xs={12} sm={6}>
                        <NukHistoryRecapCard
                            label={t('creation', { ns: 'general' })}
                            name={resource.createdByApplication.name}
                            date={resource.createdAt}
                        />
                    </Grid>
                }
                {resource.updatedByUser &&
                    <Grid item xs={12} sm={6}>
                        <NukHistoryRecapCard
                            label={t('lastUpdated', { ns: 'general' })}
                            firstName={resource.updatedByUser.firstName}
                            lastName={resource.updatedByUser.lastName}
                            date={resource.updatedAt}
                        />
                    </Grid>
                }
                {resource.updatedByApplication &&
                    <Grid item xs={12} sm={6}>
                        <NukHistoryRecapCard
                            label={t('lastUpdated', { ns: 'general' })}
                            name={resource.updatedByApplication.name}
                            date={resource.updatedAt}
                        />
                    </Grid>
                }
            </Grid>
            :
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={6}>
                    <Skeleton sx={{ height: 100 }}></Skeleton>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Skeleton sx={{ height: 100 }}></Skeleton>
                </Grid>
            </Grid>
    )
}

