import { Alert, Card, CardContent, Grid } from '@mui/material';
import { FC, Fragment, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { NukFormContext, NukFormLabel, NukFormTextField } from "../../../../../core/nuk-components";
import { GetRoleQuery } from '../../../../../graphql/nuk-graphql-main';
import { rolesResource as resourceConfig } from '../../../roles.resource';

type Props = {
  resourceData: GetRoleQuery | null | undefined
}
/**
 * Role editor form content 
 * All the fields are inside this component
 * @param param0 
 * @returns 
 */
export const RoleEditorFormContent: FC<Props> = () => {
  const { mode, trns } = useContext(NukFormContext)
  const { t } = useTranslation()
  return (

    <Fragment>
      <Card >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <NukFormLabel title={t('data', { ns: 'general' })} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NukFormTextField
                name="title"
                label={t('title', { ns: 'general' })}
              />
            </Grid>
            <Grid item xs={12}>
              <NukFormTextField
                name="value"
                label={t('value', { ns: 'general' })}
              />
              <Alert severity="info">{t('valueInfoAlert',{ns: resourceConfig.translationNamespace})}</Alert>

            </Grid>
            <Grid item xs={12}>
              <NukFormTextField
                name="description"
                multiline
                minRows={4}
                label={t('description', { ns: 'general' })}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  )
}