import { useEffect } from "react";
import { INukFormHookEffectParams, INukFormHookGetParams } from "../../../../../../core/nuk-core";
import { GetApplicationKeyQuery, GetApplicationKeyQueryResult, useGetApplicationKeyQuery } from "../../../../../../graphql/nuk-graphql-main";

/**
 * ApplicationKey Get - Fetch Hook
 * @param INukFormHookGetParams 
 * @returns GetUserQueryResult
 */
export  const useApplicationKeyFormEditorGet = (params: INukFormHookGetParams) : GetApplicationKeyQueryResult => {
    const { id } = params
    return useGetApplicationKeyQuery({
        variables:{id:id},
        skip:(isNaN(id) || !id)
    });

}

/**
 * ApplicationKey Get - Post Effect
 * @param EffectParams 
 */
export const useApplicationKeyFormEditorGetEffect = (params: EffectParams) => {
    const { data,setValue } = params
    useEffect(() => {
        if(data){
        //Compiling form values
        setValue('name', data.applicationKey.name)
        }
    },[data])
}

//User Get - Interfaces
interface EffectParams extends INukFormHookEffectParams {data: GetApplicationKeyQuery | null | undefined}
