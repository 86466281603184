import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
import { NukFormContainer, NukFormContext } from '../../../../../core/nuk-components';
import { applicationKeysResource as resourceConfig } from '../../../application-keys.resource';
import { ApplicationKeyRemoveDialog } from '../../dialogs/application-key-remove.dialog';
import { executeApplicationKeyFormEditorCreate, useApplicationKeyFormEditorCreate, useApplicationKeyFormEditorCreateEffect } from './hooks/application-key-editor-form-create.hook';
import { useApplicationKeyFormEditorGet, useApplicationKeyFormEditorGetEffect } from './hooks/application-key-editor-form-get.hook';
import { executeApplicationKeyFormEditorUpdate, useApplicationKeyFormEditorUpdate, useApplicationKeyFormEditorUpdateEffect } from './hooks/application-key-editor-form-update.hook';
import { ApplicationKeyEditorFormAppBar } from './application-key-editor-form.app-bar';
import { ApplicationKeyEditorFormContent } from './application-key-editor-form.content';
import { applicationKeyEditorFormCreateSchema, applicationKeyEditorFormCreateSchemaType, applicationKeyEditorFormUpdateSchema, applicationKeyEditorFormUpdateSchemaType } from './application-key-editor-form.schema';

/**
 * ApplicationKey Editor Form
 * All the hooks related to CRUD operations are inside this component.
 * @returns 
 */
export const ApplicationKeyEditorForm:FC = () => {
  
    const { id } = useParams();
    const mode = (id) ? 'update' : 'create'
    const methods = useForm({ mode: 'onSubmit' ,reValidateMode:'onSubmit',resolver: 
        yupResolver((mode==="create") ? applicationKeyEditorFormCreateSchema : applicationKeyEditorFormUpdateSchema)});
    const { setValue, handleSubmit,formState:{isSubmitting} } = methods
    const navigate = useNavigate()
    const [resourceLabel, setResourceLabel] = useState("")
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [gqlErrors, setGqlErrors] = useState<ApolloError | null | undefined>(null);
    const trns = 'resourceUser'

  
    const hookDefaults = {
      setGqlError: setGqlErrors,
      setResourceLabel: setResourceLabel,
      setValue: setValue,
      navigate: navigate,
      mode: mode,
    }

    useEffect(() =>{
      setGqlErrors(null)
    },[isSubmitting])

 
    //Form Hooks
    const { loading: resourceLoading, data: resourceData, error: resourceError } = useApplicationKeyFormEditorGet({ id: Number(id) })
    const [createResource, { data: createdResourceData, loading: createResourceLoading, error: createResourceError }] = useApplicationKeyFormEditorCreate();
    const [updateResource, { data: updatedResourceData, loading: updateResourceLoading, error: updateResourceError }] = useApplicationKeyFormEditorUpdate()
  
    //Form Effect Hooks
    useApplicationKeyFormEditorGetEffect({data: resourceData,loading: resourceLoading,error: resourceError,...hookDefaults})
    useApplicationKeyFormEditorCreateEffect({ data: createdResourceData, loading: createResourceLoading, error: createResourceError, ...hookDefaults })
    useApplicationKeyFormEditorUpdateEffect({ data: updatedResourceData, loading: updateResourceLoading, error: updateResourceError, ...hookDefaults })

  
    //Form submission
    const onSubmit = (data : applicationKeyEditorFormCreateSchemaType | applicationKeyEditorFormUpdateSchemaType | any) => {
      
      if (mode === 'create') {
        executeApplicationKeyFormEditorCreate(createResource,data)
      }
      else if (mode === 'update') {
        executeApplicationKeyFormEditorUpdate(updateResource, Number(id), data)
      }
    };


  
    return(
        <NukFormContext.Provider value={
            {
              loading: resourceLoading,
              submitted: (createResourceLoading || updateResourceLoading),
              deleteDialogOpen: deleteDialogOpen,
              resourceConfig: resourceConfig,
              gqlErrors: gqlErrors,
              mode: (id) ? 'update' : 'create',
              trns: trns,
            }
          }>
            <FormProvider {...methods}  >
              <Box component="form" onSubmit={handleSubmit(data => onSubmit(data))}>
                <NukFormContainer>
                <ApplicationKeyEditorFormAppBar setDeleteDialogOpen={setDeleteDialogOpen} resourceData={resourceData} resourceLabel={resourceLabel}/>
                  <ApplicationKeyEditorFormContent resourceData={resourceData}/>
                  { (mode==='update' && resourceData) &&
                    //Remove Dialog
                    <ApplicationKeyRemoveDialog 
                    open={deleteDialogOpen} 
                    setOpen={setDeleteDialogOpen} 
                    label={`${resourceData.applicationKey.name}`} 
                    mode={'static'} 
                    resourceId={resourceData.applicationKey.id} 
                    onCompleted={() => {
                      navigate(resourceConfig.indexPath)
                    }}
                />
                  }
                 </NukFormContainer>
              </Box>
              
        
            </FormProvider>
          </NukFormContext.Provider>
    )
}