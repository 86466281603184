

import { Card, CardContent } from '@mui/material';
import { FC } from 'react';
import { NukStructure } from "../../../core/nuk-components";
import { Typography } from '@mui/material';
import { useAppSelector } from '../../../features/hooks';
import { useTranslation } from 'react-i18next';
/**
 * 404 Error Page
 * @param props 
 * @returns 
 */
export const NotFound404Page: FC = (props) => {
  const authUser = useAppSelector((state) => state.user.value)
  const {t} = useTranslation()
  return (
    <NukStructure>
        <Card sx={{flex:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
            <CardContent >
                <Typography variant="h3" textTransform="capitalize">
                    UOOP NOT FOUND!
                </Typography>    
            </CardContent>
        </Card>
    </NukStructure>
  )
}