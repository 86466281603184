import { usersResource } from './resources/users/users.resource';
import { rolesResource } from './resources/roles/roles.resource';
import { applicationKeysResource } from './resources/application-keys/application-keys.resource';
import { cremationsResource } from './resources/cremations/cremations.resource';

/**
 * Resources enabled for the current application
 * This enables the routes related to the listed resources
 */
export const resources = [
    usersResource,
    rolesResource,
    applicationKeysResource,
    cremationsResource,
]