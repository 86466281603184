import { FC } from "react";
import { NukStructure } from "../../../core/nuk-components";
import { ApplicationKeysList } from "../components/lists/application-keys-list/application-keys-list";
/**
 * Users List Page
 * @returns FC
 */
export const ApplicationKeysListPage: FC = () => {
  return (
      <NukStructure>
            <ApplicationKeysList/>
      </NukStructure>
  )
}