
export const Theme = {
        palette:{
          primary: {
            //main: '#388e3c',
            main: '#455A64',
            contrastText: '#FFF'
          },
          secondary: {
            main: '#9c27b0',
            contrastText: '#FFF'
          },
          error:{
            main: '#d32f2f',
            contrastText: '#FFF'
          },
          warning:{
            main: '#ffd978',
            contrastText: '#000'
          },
          info:{
            main: '#0288d1',
            contrastText: '#FFF'
          },
          success:{
            main: '#2e7d32',
            contrastText: '#FFF'
          },

         },
          // Used by `getContrastText()` to maximize the contrast between
          // the background and the text.
          contrastThreshold: 3,
          // Used by the functions below to shift a color's luminance by approximately
          // two indexes within its tonal palette.
          // E.g., shift from Red 500 to Red 300 or Red 700.
          tonalOffset: 0.2,
          background:{
            default:'#303030'
          },


          
}



